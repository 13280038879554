import Grid from "@mui/material/Grid";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthenticationWebService } from "../../services/AuthenticationWebService";
import { email_validation, name_validation } from "../../utils/InputValidations";
import { useLoading } from "../LoadingContext";
import { useNotification } from "../NotificationContext";
import Button from "../atoms/Button";
import InputV2 from "../atoms/InputV2";
const SignupForm = () => {
	const methods = useForm({ mode: "onBlur" });
	const [success, setSuccess] = useState(false);
	const navigate = useNavigate();
	const [error, setError] = useState<string | null>(null);
	const { showLoading, hideLoading, isLoading } = useLoading();
	const { showSuccessMessage, showErrorMessage } = useNotification();

	const onSubmit = methods.handleSubmit((data: any) => {
		showLoading();
		const body = {
			firstName: data["firstName"],
			lastName: data["lastName"],
			email: data["emailId"],
		};
		AuthenticationWebService.signUp(body)
			.then((value: any) => {
				methods.reset();
				setSuccess(true);
				setError(null);
				showSuccessMessage("New user is created! Kindly check your mailbox for next steps");
				// navigate("/");
			})
			.catch((err) => {
				showErrorMessage(err.message);
				setError(err);
			})
			.finally(() => {
				hideLoading();
			});
	});

	return (
		<div>
			<FormProvider {...methods}>
				<form onSubmit={(e) => e.preventDefault()} noValidate autoComplete='off'>
					<Grid container columnSpacing={1}>
						<Grid item xs={12}>
							<InputV2 name='firstName' type='text' placeholder='First Name' label='First Name' validation={name_validation} />
						</Grid>
						<Grid item xs={12}>
							<InputV2 name='lastName' type='text' placeholder='Last Name' label='Last Name' validation={name_validation} />
						</Grid>
						<Grid item xs={12}>
							<InputV2 name='emailId' type='email' placeholder='Email' label='Email' validation={email_validation} />
						</Grid>
						{/* <Grid item xs={12}>
							<InputV2 name='password' type='password' placeholder='Password' label='Password' validation={password_validation} />
						</Grid> */}
					</Grid>
					<Button size='sm' className='g-margin-t-20--xs g-width-100-percent--xs' onClick={onSubmit} color='dark' variant={"contained"} text='Sign-Up'></Button>
				</form>
			</FormProvider>
		</div>
	);
};
export default SignupForm;
