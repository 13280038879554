import { Grid, Skeleton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BlobFile, TaskFile } from "../../../models/Interface";
import { BlobStorageService } from "../../../services/BlobStorageService";
import { WorkspaceService } from "../../../services/WorkspaceService";
import { WorkspaceWebService } from "../../../services/WorkspaceWebService";
import { name_validation } from "../../../utils/InputValidations";
import AutoCompleteSm from "../../atoms/AutoCompleteSm";
import Button from "../../atoms/Button";
import Editor from "../../atoms/Editor";
import InputError from "../../atoms/InputError";
import InputV3 from "../../atoms/InputV3";
import { useAuth } from "../../AuthContext";
import { useBasePath } from "../../BasePathContext";
import { useLoading } from "../../LoadingContext";
import MoreOptionMenu, { MoreOptionMenuItem } from "../../molecules/MoreOptionMenu";
import { useNotification } from "../../NotificationContext";
import { DashboardContext } from "../../pages/Dashboard";
import { useUploadContext } from "../../UploadContext";
import { useUploadStatusContext } from "../../UploadStatusContext";

const Doc = () => {
	const { currentWorkspace, user } = useAuth();
	const NEW_IDEA_ID = "new";
	const states = [{ label: "Draft" }, { label: "Approved" }];
	let { taskId, fileId } = useParams();
	const navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();
	const { closeSidebar, openSidebar }: any = useContext(DashboardContext);
	const { showLoading, hideLoading, isLoading } = useLoading();
	const { showErrorMessage, showSuccessMessage } = useNotification();
	const { addToUploadQueue, isFileUploaded } = useUploadContext();
	const { clearStatusQueue, addToStatusQueue } = useUploadStatusContext();
	const [lastChangeDatetime, setLastChangeDatetime] = useState<string>();
	const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);
	const [hasEditAccess, setHasEditAccess] = useState<boolean>(false);
	const [blobVersions, setBlobVersions] = useState<Array<BlobFile>>([]);
	const [currentBlobIndex, setCurrentBlobIndex] = useState<number>(0);
	const [fileName, setFileName] = useState<string | undefined>("");
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [fileState, setFileState] = useState<any>(states[0]);
	const [localLoading, setLocalLoading] = useState<any>(false);
	const [currFileId, setCurrFileId] = useState(fileId);
	const [document, setDocument] = useState<any>();
	const [initialDocument, setInitialDocument] = useState<any>();

	const { basePath } = useBasePath();

	const updateDocument = (document: any) => {
		setDocument(document);
	};

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const moreMenuOptions: MoreOptionMenuItem[] = [
		{ title: "Upload New Version", icon: "upload", isDisable: !hasEditAccess || currFileId == NEW_IDEA_ID, action: () => handleUploadBtnClick("") },
		{ title: "Download File", icon: "download", isDisable: currFileId == NEW_IDEA_ID, action: () => console.log("") },
		{ title: "Delete Version", icon: "delete", isDisable: !hasEditAccess || currFileId == NEW_IDEA_ID, action: () => handleDeleteVersion() },
	];

	const handleFileStateChange = async (state: any) => {
		setFileState(state);

		if (state.label !== fileState.label) {
			showLoading();
			const file = blobVersions[currentBlobIndex];
			const body = { ...file, approved: state.label === "Approved" };
			if (file && taskId) {
				await WorkspaceWebService.uploadFileInTask(taskId, [body])
					.then((res) => {
						blobVersions[currentBlobIndex].approved = state.label === "Approved";
						setBlobVersions(blobVersions);
						showSuccessMessage("File status updated successfully !");
					})
					.catch((err) => showErrorMessage(err.message))
					.finally(() => hideLoading());
			}
		}
	};

	const handleFileNameChange = (e: any) => {
		console.log(`In handleIdeaNameChange`);
		const value = e.target?.value;
		if (value != null) {
			setFileName(value);
		}
	};

	const handleFileSave = async () => {
		if (fileName && currentWorkspace) {
			let newFileId;
			if (currFileId === NEW_IDEA_ID) {
				newFileId = crypto.randomUUID();
				setCurrFileId(newFileId);
			} else {
				newFileId = currFileId;
			}

			if (basePath != null && basePath != "/") {
				setFileName(basePath + fileName);
			}

			if (newFileId) {
				const blob = new Blob([document], { type: "text/html" });
				const file = new File([blob], fileName, { type: "text/html" });
				const newFile = await BlobStorageService.fileToBlobFile(file, user.email, currentWorkspace?.id, taskId ?? "", newFileId, fileName, "txt");
				addToStatusQueue(newFile);
				addToUploadQueue(newFile)
					.then(() => {
						if (fileId === NEW_IDEA_ID) {
							navigate(`/${currentWorkspace.id}/tasks/${taskId}/doc/${newFileId}.txt`);
						}
					})
					.catch((error) => {
						showErrorMessage(error.message);
					});
			}
		} else {
			showErrorMessage("File name can't be empty!");
		}
	};

	const handleLoadingFile = async () => {
		if (taskId != null && currFileId && currFileId != NEW_IDEA_ID) {
			showLoading();
			setLocalLoading(true);
			await BlobStorageService.getBlobVersions(`${currentWorkspace?.id}/${taskId}/${currFileId}`)
				.then(async (res) => {
					const refTaskId = searchParams.get("refTaskId");
					if (refTaskId && refTaskId != "") {
						const result = await WorkspaceService.fetchAllRefTaskFiles(refTaskId);
						const references = result?.files;
						const taskReference = references?.find((ref: any) => ref.taskIdRef == taskId);
						taskReference?.parsedValues?.map((val: BlobFile) => {
							if (currFileId) {
								if (val.name?.includes(currFileId)) {
									const ind = res.findIndex((azf: BlobFile) => azf.versionId === val.versionId);
									if (ind !== -1) {
										//Updating version from azure data
										val.isCurrentVersion = res[ind].isCurrentVersion;
										//Updating path with latest signature
										val.path = res[ind].path;
										val.approved ? setFileState(states[1]) : setFileState(states[0]);
										setBlobVersions([val]);
										setFileName(val.originalName);
										setCurrentBlobIndex(0);
										setHasEditAccess(() => result.currMemberPermissions.some((p: any) => ["c", "u", "a"].includes(p)));
									}
								}
							}
						});
					} else {
						const result = await WorkspaceService.fetchAllTaskFiles(taskId);
						const files = result?.files as Array<TaskFile>;
						const index = files.findIndex((f) => (currFileId ? f.fileName.includes(currFileId) : false));

						if (index !== -1) {
							const file = files[index];
							let versions = file.fileVersions;

							versions = versions.map((version, index) => {
								const ind = res.findIndex((azf: BlobFile) => azf.versionId === version.versionId);
								if (ind !== -1) {
									if (index !== 0) setCurrentBlobIndex(index);
									setFileName(versions[index].originalName);
									versions[index].approved ? setFileState(states[1]) : setFileState(states[0]);
									//Updating version from azure data
									version.isCurrentVersion = res[ind].isCurrentVersion;
									//Updating path with latest signature
									version.path = res[ind].path;
								}
								return version;
							});
							versions.sort((a, b) => Number(a.approved) - Number(b.approved));
							setBlobVersions(versions);
							// await loadFileContent(versions);
							setHasEditAccess(() => result.currMemberPermissions.some((p: any) => ["c", "u", "a"].includes(p)));
						}
					}
				})
				.catch((err) => showErrorMessage(err.message))
				.finally(() => {
					hideLoading();
					setLocalLoading(false);
				});
		} else {
			setHasEditAccess(true);
		}
	};
	const changeCurrentBlob = (index: number) => {
		setCurrentBlobIndex(index);
	};

	useEffect(() => {
		clearStatusQueue();
		handleLoadingFile();
	}, [currFileId, isFileUploaded]);

	const loadFileContent = (versions: any) => {
		const fileurl = versions[currentBlobIndex]?.path;
		if (fileurl) {
			setLocalLoading(true);
			fetch(fileurl).then(async (res: any) => {
				setInitialDocument(await res.text());
				setLocalLoading(false);
			});
		}
	};

	const isAnyVersionApproved = () => {
		return blobVersions.some((v) => v.approved);
	};

	const isCurrentVersionApproved = () => {
		return blobVersions[currentBlobIndex].approved;
	};

	return (
		<>
			{localLoading ? (
				<div className='g-height-100-percent--xs '>
					<div className='g-padding-y-10--xs g-hor-divider__solid--gray'>
						<Skeleton variant='text' className='g-margin-l-10--xs' width={"500px"} sx={{ fontSize: "1.4rem" }} />
					</div>
					<div className='g-margin-t-10--xs g-width-100-percent--xs g-padding-x-40--xs'>
						<Skeleton variant='rectangular' width={"100%"} sx={{ fontSize: "1rem" }} />
					</div>
					<div className='g-margin-t-10--xs g-width-100-percent--xs g-padding-x-40--xs'>
						<Skeleton variant='rectangular' width={"100%"} sx={{ fontSize: "1rem" }} />
					</div>
					<div className='g-margin-t-10--xs g-width-100-percent--xs g-padding-x-40--xs'>
						<Skeleton variant='rectangular' width={"100%"} sx={{ fontSize: "1rem" }} />
					</div>
					<div className='g-margin-t-10--xs g-width-100-percent--xs g-padding-x-40--xs'>
						<Skeleton variant='rectangular' width={"100%"} sx={{ fontSize: "1rem" }} />
					</div>
				</div>
			) : (
				<div className='g-bg-color--gray'>
					<Grid
						sx={{ zIndex: 1000, position: "sticky", display: "block", top: 0 }}
						className='g-padding-x-20--xs g-padding-y-10--xs g-hor-divider__solid--gray g-bg-color--white'>
						<Grid container className='g-margin-t-0--xs'>
							<Grid item xs={9}>
								<div className='g-display-flex--xs g-content-center-y--xs g-margin-r-5--xs' style={{ justifyContent: "flex-start" }}>
									<div className='g-content-center-y--xs g-width-450--xs g-margin-r-5--xs'>
										<i className='material-symbols-sharp g-margin-r-5--xs g-font-size-20--xs'>description</i>
										<InputV3
											value={fileName}
											onChange={(e: any) => handleFileNameChange(e)}
											validation={name_validation}
											name='fileName'
											placeholder='Enter File Name'
											disabled={!hasEditAccess}
										/>
									</div>
									{fileName?.length == 0 && <InputError message="File name can't be empty"></InputError>}
								</div>
							</Grid>
							<Grid item xs={3}>
								<div className='g-display-flex--xs' style={{ justifyContent: "flex-end" }}>
									<div className='g-display-flex--xs g-width-100-percent--xs g-margin-r-10--xs' style={{ alignItems: "center", justifyContent: "flex-end" }}>
										<div className='g-width-200--xs g-margin-l-5--xs'>
											<AutoCompleteSm
												onChange={(e: any, v: any) => handleFileStateChange(v)}
												options={states}
												value={fileState}
												defaultValue={states[0]}
												placeholder='Select State'
												disabled={!hasEditAccess || (isAnyVersionApproved() ? !isCurrentVersionApproved() : false)}
											/>
										</div>
									</div>
									<div className='g-margin-r-10--xs'>
										<Button
											onClick={handleFileSave}
											text='Save'
											icon='save'
											color={"primary"}
											radius={4}
											variant='contained'
											disabled={!hasEditAccess || fileName?.length === 0}></Button>
									</div>
									<div className='g-margin-r-5--xs'>
										<Button style={{ height: "33px" }} onClick={handleMenuClick} icon='more_horiz' color={"light"} radius={4} variant='contained'></Button>
									</div>
								</div>
								<MoreOptionMenu anchorElement={anchorEl} closeHandler={handleMenuClose} items={moreMenuOptions} />
							</Grid>
						</Grid>
					</Grid>
					<div className='g-bg-color--gray' style={{ minHeight: "100vh" }}>
						<div
							className='g-bg-color--white g-padding-y-50--xs g-margin-t-20--xs'
							style={{ width: "80%", marginLeft: "auto", marginRight: "auto", border: "1px solid var(--gray)" }}>
							<Editor
								isLoading={localLoading}
								name='taskEditorContent'
								currentBlobIndex={currentBlobIndex}
								filePath={blobVersions[currentBlobIndex]?.path}
								callback={updateDocument}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Doc;

function handleUploadBtnClick(arg0: string): void {
	throw new Error("Function not implemented.");
}

function handleDeleteVersion(): void {
	throw new Error("Function not implemented.");
}
