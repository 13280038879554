export const AvatarIntials = (title: string) => {
	const titles = title.split(" ");
	if (titles.length > 1) {
		return `${titles[0].charAt(0).toUpperCase()}${titles[1].charAt(0).toUpperCase()}`;
	} else return `${titles[0].charAt(0).toUpperCase()}${titles[0].charAt(1).toUpperCase()}`;
};

export function formatBytes(bytesString: string | undefined) {
	const bytes = Number(bytesString);
	var marker = 1024; // Change to 1000 if required
	var decimal = 2; // Change as required
	var kiloBytes = marker; // One Kilobyte is 1024 bytes
	var megaBytes = marker * marker; // One MB is 1024 KB
	var gigaBytes = marker * marker * marker; // One GB is 1024 MB

	// return bytes if less than a KB
	if (bytes < kiloBytes) return bytes + " B";
	// return KB if less than a MB
	else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
	// return MB if less than a GB
	else if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + " MB";
	// return GB if less than a TB
	else return (bytes / gigaBytes).toFixed(decimal) + " GB";
}

export function fileIcon(fileType: string | undefined) {
	if (!fileType) return "description";
	return fileType?.includes("video") ? "videocam" : fileType?.includes("image") ? "image" : fileType?.includes("audio") ? "audio_file" : "description";
}

export function fileThumbnail(fileType: string | undefined, filePath: string | undefined) {
	const dummyThumbnail = "https://www.contentviewspro.com/wp-content/uploads/2017/07/default_image.png";
	if (!fileType) return dummyThumbnail;
	return fileType?.includes("image")
		? filePath
		: fileType?.includes("audio")
		? "/img/thumbnails/audio.png"
		: fileType?.includes("text")
		? "/img/thumbnails/doc.png"
		: fileType?.includes("video")
		? "/img/thumbnails/video.png"
		: undefined;
}

export function fancyTimeFormat(duration: number) {
	// Hours, minutes and seconds
	const hrs = ~~(duration / 3600);
	const mins = ~~((duration % 3600) / 60);
	const secs = ~~duration % 60;

	// Output like "1:01" or "4:03:59" or "123:03:59"
	let ret = "";

	if (hrs > 0) {
		ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
	}

	ret += "" + mins + ":" + (secs < 10 ? "0" : "");
	ret += "" + secs;

	return ret;
}

//initialize throttlePause variable outside throttle function
let throttlePause: boolean = false;
let isFirstCall: boolean = true;
export const throttle = (callback: Function, time: number) => {
	//don't run the function if throttlePause is true
	if (throttlePause) return;
	if (isFirstCall) {
		callback();
		throttlePause = false;
		isFirstCall = false;
		return;
	}
	//set throttlePause to true after the if condition. This allows the function to be run once
	throttlePause = true;

	//setTimeout runs the callback within the specified time
	setTimeout(() => {
		callback();
		//throttlePause is set to false once the function has been called, allowing the throttle function to loop
		throttlePause = false;
		return;
	}, time);
};
