import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { ChangeWSMenuProps } from "./ChangeWorkspaceMenu";

const SettingsMenu = (props: ChangeWSMenuProps) => {
	const navigate = useNavigate();
	const { logout, user } = useAuth();
	const openMenu = Boolean(props.anchorElement);

	const logoutAndRedirect = () => {
		props.closeHandler();
		logout();
		navigate("/login");
	};

	const goToSettings = () => {
		props.closeHandler();
		navigate("settings");
	};

	return (
		<Menu
			id='basic-menu'
			anchorEl={props.anchorElement}
			open={openMenu}
			onClose={props.closeHandler}
			sx={{ "& .MuiPaper-root": { minWidth: "16rem", border: "1px solid #ddd", borderRadius: "0.25rem" } }}
			MenuListProps={{
				"aria-labelledby": "basic-button-settings",
				style: { padding: "0.5rem 0" },
			}}
			anchorOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}>
			<div>
				<div
					className='g-font-size-12--xs g-padding-x-5--xs g-margin-b-5--xs g-font-weight--700 g-color--text g-line-height--sm'
					style={{ paddingLeft: "1rem" }}>
					<span className='material-symbols-sharp g-margin-r-5--xs'>account_circle</span>
					{user.email}
				</div>
			</div>
			<MenuItem onClick={() => goToSettings()}>
				<span className='material-symbols-sharp g-margin-r-5--xs'>settings</span>Settings & members
			</MenuItem>
			{/* <MenuItem onClick={() => logoutAndRedirect()}>
				<span className='material-symbols-sharp g-margin-r-5--xs'>logout</span>Logout
			</MenuItem> */}
		</Menu>
	);
};

export { SettingsMenu };
