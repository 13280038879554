import { memo } from "react";
import { NodeProps } from "reactflow";
import { TaskTemplate } from "../../../models/Interface";
import BaseNode from "./BaseNode";

const ReleaseYoutubeNode = memo(({ id, type, data }: NodeProps<TaskTemplate>) => {
	return <BaseNode id={id} type={type} data={data}></BaseNode>;
});

export default ReleaseYoutubeNode;
