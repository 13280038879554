import { LinearProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthenticationWebService } from "../../services/AuthenticationWebService";
import { email_validation } from "../../utils/InputValidations";
import { useAuth } from "../AuthContext";
import { useLoading } from "../LoadingContext";
import { useNotification } from "../NotificationContext";
import Button from "../atoms/Button";
import InputV2 from "../atoms/InputV2";
const LoginForm = () => {
	const { login } = useAuth();
	const methods = useForm({ mode: "onBlur" });
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();
	const { showLoading, hideLoading, isLoading } = useLoading();
	const { showErrorMessage, showSuccessMessage } = useNotification();

	const onSubmit = methods.handleSubmit((data: any) => {
		showLoading();
		const body = {
			username: data["emailId"],
			password: data["password"],
		};
		AuthenticationWebService.login(body)
			.then((value: any) => {
				const jwt = value.jwt;
				const user = value.user;
				methods.reset();
				setSuccess(true);
				setError(null);
				login(jwt, user);
				navigate("/");
			})
			.catch((err) => {
				console.log(err);
				setError(err.message);
				showErrorMessage(err.message);
			})
			.finally(() => {
				hideLoading();
			});
	});

	return (
		<div>
			<h1 className='g-margin-b-40--xs '>
				<b>Welcome back</b>
			</h1>
			<FormProvider {...methods}>
				<form onSubmit={(e) => e.preventDefault()} noValidate autoComplete='off'>
					<Grid container>
						<Grid item xs={12}>
							<InputV2 name='emailId' type='email' placeholder='Email' label='Email' validation={email_validation} />
						</Grid>
						<Grid item xs={12}>
							<InputV2 name='password' type='password' placeholder='Password' label='Password' validation={{}} />
						</Grid>
					</Grid>
					<Button size='sm' className='g-margin-t-20--xs g-width-100-percent--xs' onClick={onSubmit} color='dark' variant={"contained"} text='Sign-In'></Button>
				</form>
				{error && <p>{error}</p>}
			</FormProvider>
			<div className='g-margin-t-10--xs'>{/* <a href={RP.ForgotPassword}>Forgot Password?</a> */}</div>
			{isLoading && (
				<div className='g-content-center-x--xs'>
					<LinearProgress className='g-margin-t-40--xs g-width-150--xs' color='secondary' />
				</div>
			)}
		</div>
	);
};
export default LoginForm;
