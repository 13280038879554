import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import React from "react";

interface PopoverProps {
	anchorEl: HTMLElement | null;
	children: React.ReactNode;
}

const Popover = (props: PopoverProps) => {
	const open = Boolean(props.anchorEl);
	const id = open ? "simple-popper" : undefined;
	return (
		<Popper id={id} open={open} anchorEl={props.anchorEl} placement={"right-end"}>
			<Box sx={{ width: 200, p: 1, bgcolor: "background.paper", lineHeight: 1.4 }} className='g-box-shadow__dark-lightest-v4'>
				{props.children}
			</Box>
		</Popper>
	);
};

export default Popover;
