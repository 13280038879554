import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import { useEffect } from "react";
import Button from "./Button";

interface row {
	id: string;
	data: any;
}

interface column {
	id: string;
	label: string;
	render: (T: any) => React.ReactNode | string | null;
}

/////////////////////////////
type Order = "asc" | "desc";

function descendingComparator(a: row, b: row, orderBy: string) {
	if (b.data[orderBy] < a.data[orderBy]) {
		return -1;
	}
	if (b.data[orderBy] > a.data[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order: Order, orderBy: string): (a: row, b: row) => number {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(rows: readonly row[], comparator: (a: row, b: row) => number) {
	const stabilizedThis = rows.map((el, index) => [el, index] as [row, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableHeaderProps {
	numSelected: number;
	onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
	isEditable?: boolean;
	columns: column[];
}

const EnhancedTableHead = (props: EnhancedTableHeaderProps) => {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};
	return (
		<TableHead>
			<TableRow key={-1}>
				{props.isEditable && (
					<TableCell padding='checkbox'>
						<Checkbox
							color='primary'
							sx={{ padding: 0 }}
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{
								"aria-label": "select all desserts",
							}}
						/>
					</TableCell>
				)}

				{props.columns.map((headCell) => (
					<TableCell key={headCell.id} align={"left"} padding={"normal"} sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

interface EnhancedTableToolbarProps {
	selectedNum: number;
	totalNum: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
	const { selectedNum: selectedNum, totalNum: totalNum } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
			}}>
			{selectedNum > 0 ? (
				<Typography sx={{ flex: "1 1 100%" }} color='inherit' variant='subtitle1' component='div' className='g-font-size-14--xs'>
					Selected {selectedNum}
				</Typography>
			) : (
				""
			)}
			{selectedNum > 0 && (
				<Tooltip title='Delete'>
					<Button size='xs' text='Delete' radius='10' color='primary' variant='contained'></Button>
				</Tooltip>
			)}
		</Toolbar>
	);
}

interface EnhancedTableProps {
	rows: row[];
	columns: column[];
	paginationOptions?: number[];
	isEditable?: boolean;
}

const EnhancedTable = (props: EnhancedTableProps) => {
	const [order, setOrder] = React.useState<Order>("asc");
	const [orderBy, setOrderBy] = React.useState<string>(() => (props.columns.length > 0 ? props.columns[0].id : ""));
	const [selected, setSelected] = React.useState<readonly string[]>([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(() => {
		if (props.paginationOptions != null && props.paginationOptions.length > 0) return props.paginationOptions[0];
		else return props.rows.length;
	});

	const [rows, setRows] = React.useState(props.rows);
	useEffect(() => {
		setRows(props.rows);
	}, [props.rows]);
	// const rows = props.rows;

	const isEditable = props.isEditable == null ? false : props.isEditable;

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: any, id: string) => {
		event.stopPropagation();
		if (event.target.tagName !== "INPUT") return false;
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (name: string) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const visibleRows: row[] = React.useMemo(
		() => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
		[order, orderBy, page, rowsPerPage]
	);

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%" }}>
				{/* <EnhancedTableToolbar selectedNum={selected.length} totalNum={rows.length} /> */}
				<TableContainer>
					<Table sx={{ minWidth: 750, mt: 3 }} aria-labelledby='tableTitle'>
						<EnhancedTableHead
							columns={props.columns}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
							isEditable={isEditable}
						/>
						<TableBody>
							{visibleRows.map((row: row, index) => {
								const isItemSelected = isSelected(row.id);
								const labelId = `enhanced-table-checkbox-${row.id}`;

								return (
									<TableRow
										hover
										onClick={(event) => handleClick(event, row.id)}
										role='checkbox'
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={index}
										selected={isItemSelected}
										sx={{ cursor: "pointer" }}>
										{isEditable && (
											<TableCell padding='checkbox'>
												<Checkbox
													color='primary'
													sx={{ padding: 0 }}
													checked={isItemSelected}
													inputProps={{
														"aria-labelledby": labelId,
													}}
												/>
											</TableCell>
										)}

										{props.columns.map((col: column, index) => (
											<TableCell key={index} component='td' scope='row'>
												<>{col.render(row.data)}</>
											</TableCell>
										))}
										{isEditable && (
											<td>
												<i style={{ position: "relative", right: "1rem", display: "none" }} className='material-symbols-sharp'>
													more_vert
												</i>
											</td>
										)}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				{props.paginationOptions != null && (
					<TablePagination
						rowsPerPageOptions={props.paginationOptions}
						sx={{ "& .MuiTablePagination-toolbar": { alignItems: "baseline" } }}
						component='div'
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Paper>
		</Box>
	);
};

export { EnhancedTable };
