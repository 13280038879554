import { BlobFile } from "../../models/Interface";
import { BlobStorageService } from "../../services/BlobStorageService";
import { useAuth } from "../AuthContext";
import { useBasePath } from "../BasePathContext";
import { useNotification } from "../NotificationContext";
import { useUploadContext } from "../UploadContext";
import { useUploadStatusContext } from "../UploadStatusContext";

interface FileVersionUploadProps {
	taskId: string;
	blobVersions: Array<BlobFile>;
	currentBlobIndex: number;
	inputFiles: any;
}

const FileVersionUpload = (props: FileVersionUploadProps) => {
	const { basePath } = useBasePath();
	const { currentWorkspace, user } = useAuth();
	const { showErrorMessage, showSuccessMessage } = useNotification();
	const { addToUploadQueue, isFileUploaded } = useUploadContext();
	const { clearStatusQueue, addToStatusQueue, stopRefreshingProgress, refreshingProgress } = useUploadStatusContext();

	const { taskId, blobVersions, currentBlobIndex, inputFiles } = props;

	const handleFileSelectChange = async (event: any) => {
		console.log("Clicked");
		const selectedFilesArray: any = Array.from(event.target.files || []);
		await uploadFiles(selectedFilesArray);
	};

	const uploadFiles = async (selectedFilesArray: any) => {
		clearStatusQueue();
		selectedFilesArray.map(async (file: any) => {
			if (file.type === blobVersions[currentBlobIndex].fileType && currentWorkspace) {
				const newFileId = blobVersions[currentBlobIndex].name.split(".")[0];
				let currentFileName = file.name;
				if (basePath != null && basePath != "/") {
					currentFileName = basePath + currentFileName;
				}
				const newFile = await BlobStorageService.fileToBlobFile(file, user.email, currentWorkspace?.id, taskId ?? "", newFileId, currentFileName);
				addToStatusQueue(newFile);
				addToUploadQueue(newFile).catch((err) => {
					showErrorMessage(err.message);
				});
			} else {
				showErrorMessage("Could not able to upload this as new version. File type should be same!");
			}
		});
	};

	return (
		<>
			<input ref={inputFiles} type='file' style={{ display: "none" }} onChange={handleFileSelectChange} />
		</>
	);
};
export default FileVersionUpload;
