import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BlobFile } from "../../models/Interface";
import { BlobStorageService } from "../../services/BlobStorageService";
import Button from "../atoms/Button";
import { useAuth } from "../AuthContext";
import { useBasePath } from "../BasePathContext";
import { useNotification } from "../NotificationContext";
import { useUploadContext } from "../UploadContext";
import { useUploadStatusContext } from "../UploadStatusContext";

export interface FileManagerProps {
	taskId: string;
	files: BlobFile[];
}
const UploadManager = (props: FileManagerProps) => {
	let inputFiles = useRef<any>(null);
	let inputFolderFiles = useRef<any>(null);
	const { currentWorkspace, user } = useAuth();
	const { basePath } = useBasePath();
	const { showErrorMessage, showSuccessMessage } = useNotification();
	const { addToUploadQueue, isFileUploaded } = useUploadContext();
	const { addToStatusQueue, refreshingProgress, stopRefreshingProgress, clearStatusQueue } = useUploadStatusContext();

	const isReloading = useRef(false);

	const [inputKey, setInputKey] = useState(Date.now());

	const filesInTask: BlobFile[] = props.files;
	const taskId: string = props.taskId;

	function handleUploadBtnClick(event: any) {
		if (inputFiles.current) {
			isReloading.current = true;
			stopRefreshingProgress();
			clearStatusQueue();
			inputFiles.current.click();
			inputFiles.current.value = "";
		}
	}

	function handleUploadFolderBtnClick(event: any) {
		if (inputFolderFiles.current) {
			isReloading.current = true;
			stopRefreshingProgress();
			clearStatusQueue();
			inputFolderFiles.current.click();
			inputFolderFiles.current.value = "";
		}
	}

	const handleFileSelectChange = async (event: any) => {
		event.preventDefault();
		const selectedFilesArray: any = Array.from(event.target.files || []);
		uploadFiles(selectedFilesArray);
		event.target.value = "";
		setInputKey(Date.now()); // Force re-render of input
	};

	const uploadFiles = async (selectedFilesArray: any) => {
		if (currentWorkspace?.id && taskId && taskId !== undefined) {
			refreshingProgress();
			selectedFilesArray.map(async (file: any) => {
				if (["audio", "video", "image"].includes(file.type.split("/")[0])) {
					let currentFileName = file?.webkitRelativePath != null && file?.webkitRelativePath != "" ? file?.webkitRelativePath : file.name;
					if (basePath != null && basePath != "/") {
						currentFileName = basePath + currentFileName;
					}

					const newFileId = crypto.randomUUID();
					const newFileName = geNewFileUniqueName(currentFileName);
					const newFile = await BlobStorageService.fileToBlobFile(file, user.email, currentWorkspace?.id, taskId ?? "", newFileId, newFileName);
					addToStatusQueue(newFile);
					addToUploadQueue(newFile).catch((err) => {
						showErrorMessage(err.message);
					});
				} else {
					showErrorMessage(`Format not supported for ${file.name}!`);
				}
			});
		}
	};

	const isFileNameAlreadyExist = (fileName: string): boolean => {
		return filesInTask.some((el) => el.originalName === fileName) ?? false;
	};

	const geNewFileUniqueName = (fileName: any): string => {
		if (isFileNameAlreadyExist(fileName)) {
			const oldName = fileName;
			const oldNameString = oldName.split(".")[0];
			const oldNameExt = oldName.split(".")[1];
			const newName = `${oldNameString} (new).${oldNameExt}`;
			return geNewFileUniqueName(newName);
		}
		return fileName;
	};

	// Checking for hard Refresh / closing the tab while uploading files and showing warnings
	useEffect(() => {
		function handleHardRefresh(event: BeforeUnloadEvent) {
			if (isReloading.current) {
				event.preventDefault();
				event.returnValue = "";
			}
		}
		window.addEventListener("beforeunload", handleHardRefresh);

		return () => {
			window.removeEventListener("beforeunload", handleHardRefresh);
		};
	}, [isReloading]);

	useEffect(() => {
		isReloading.current = false;
	}, [isFileUploaded]);

	return (
		<>
			<input
				ref={inputFiles}
				type='file'
				key={inputKey}
				style={{ display: "none" }}
				multiple
				onClick={(e: any) => (e.target.value = "")}
				onChange={handleFileSelectChange}
			/>
			<input
				ref={inputFolderFiles}
				type='file'
				key={"folder-upload"}
				style={{ display: "none" }}
				multiple
				onClick={(e: any) => (e.target.value = "")}
				onChange={handleFileSelectChange}
				{...({
					webkitdirectory: "",
					directory: "",
				} as any)}
			/>
			<div className='g-display-flex--xs'>
				<Link to={"doc/new"}>
					<Button className={"g-margin-r-10--xs"} text='New Document' icon={"description"} />
				</Link>
				<Button className={"g-margin-r-10--xs"} text='Files Upload' icon={"upload"} onClick={handleUploadBtnClick} />
				<Button className={"g-margin-r-0--xs"} text='Folder Upload' icon={"upload"} onClick={handleUploadFolderBtnClick} />
			</div>
		</>
	);
};
export default UploadManager;
