class RoutePaths {
	public static ROOT: string = "/";
	public static LOGIN: string = "/login";
	public static SIGNUP: string = "/signupavni";
	public static ONBOARDING: string = "/onboarding";
	public static FORGET_PASS: string = "/forgotpassword";
	public static DASHBOARD: any = {
		ROOT: "",
		TASKS: "tasks",
		IDEAS: "ideas",
		WORKFLOW: "workflow",
		SETTINGS: "settings",
	};
}
export default RoutePaths;
