import { Workspace } from "../models/Interface";

export namespace StorageService {
	export const TOKEN = "token";
	export const USER = "user";
	export const WORKSPACES = "workspaces";
	export const CURR_WORKSPACE = "currentWorkspace";
	export const WORKFLOW_STORAGE_KEY = "gbnns-flow";

	export const logOut = () => {
		localStorage.removeItem(TOKEN);
		localStorage.removeItem(USER);
		localStorage.removeItem(WORKSPACES);
	};

	export const logIn = (token: string, user: any) => {
		localStorage.setItem(TOKEN, token);
		localStorage.setItem(USER, JSON.stringify(user));
	};

	export const setupWorkspace = (workspace: Workspace, workspaces: Array<Workspace>) => {
		localStorage.setItem(WORKSPACES, JSON.stringify(workspaces));
		localStorage.setItem(CURR_WORKSPACE, JSON.stringify(workspace));
	};

	export const setupWorkspaces = (workspaces: Array<Workspace>) => {
		localStorage.setItem(WORKSPACES, JSON.stringify(workspaces));
	};

	export const saveWorkflow = (workflow: any) => {
		localStorage.setItem(WORKFLOW_STORAGE_KEY, JSON.stringify(workflow));
	};
}
