import { AnimatePresence, motion } from "framer-motion";
import { useFormContext } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../utils/FormValidations";
const InputV1 = (props: {
	name: string;
	value?: string;
	type?: string;
	label?: string;
	id?: string;
	placeholder?: string;
	validation?: any;
	multiline?: boolean;
	className?: string;
	onChange?: any;
}) => {
	let { name, value, type, label, id, placeholder, validation, className, ...remaining } = props;
	name = name ?? "";
	type = type ?? "text";
	id = id ?? name;
	placeholder = placeholder ?? `Enter ${name}`;

	const {
		register,
		formState: { errors },
	} = useFormContext();
	const inputErrors = findInputError(errors, name);
	const isInvalid = isFormInvalid(inputErrors);
	return (
		<div className='g-position--relative'>
			<label htmlFor={id}>{label}</label>
			{isInvalid && (
				<AnimatePresence mode='wait' initial={false}>
					<InputError message={inputErrors?.error?.message} key={inputErrors?.error?.message}></InputError>
				</AnimatePresence>
			)}
			<input
				id={id}
				placeholder={placeholder}
				{...register(name, validation ?? {})}
				type={type}
				value={value}
				{...remaining}
				className={`s-form-v1__input g-width-100-percent--xs g-margin-b-20--xs ${className}`}></input>
		</div>
	);
};

const InputError = (props: { message: string }) => {
	return (
		<motion.p
			className='g-position--absolute g-position-0--right g-position-10--top g-font-weight--700 g-padding-x-5--xs g-color--red g-bg-color--red-opacity-light g-width-auto--xs g-radius--4 input-error-label'
			initial={{ opacity: 0, y: -10 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: -10 }}
			transition={{ duration: 0.2 }}
			style={{ top: "-1px" }}>
			<span className='material-symbols-sharp g-font-size-18--xs'>error</span>
			{props.message}
		</motion.p>
	);
};

export default InputV1;
