import { createContext, ReactNode, useContext, useState } from "react";
import { BlobFile } from "../models/Interface";
import { BlobStorageService } from "../services/BlobStorageService";
import { useUploadContext } from "./UploadContext";

interface UploadStatus {
	fileId: string;
	fileName: string;
	status: string;
}

interface UploadStatusContextType {
	uploadStatus: UploadStatus[];
	getUploadStatus: (fileId: string) => UploadStatus | undefined;
	addToStatusQueue: (file: BlobFile) => void;
	removeFromStatusQueue: (fileId: string) => void;
	clearStatusQueue: () => void;
	refreshingProgress: () => void;
	stopRefreshingProgress: () => void;
	abortUpload: (fileId: string) => void;
}

const UploadStatusContext = createContext<UploadStatusContextType | undefined>(undefined);
let updater: any;
const UploadStatusProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [uploadStatus, setUploadStatus] = useState<UploadStatus[]>([]);

	const { uploadQueue, removeFromUploadQueue, setIsFileUploaded } = useUploadContext();

	const updateUploadStatus = () => {
		if (updater) {
			Object.keys(BlobStorageService.getProgressAll()).map((fileId) => {
				setUploadStatus((prevStatus) => {
					const updatedStatus = [...prevStatus];
					const index = updatedStatus.findIndex((item) => item.fileId === fileId);
					const fileIndex = uploadQueue.findIndex((item) => item.name == fileId);
					const status = BlobStorageService.getProgress(fileId) ?? "0";
					if (index !== -1) {
						updatedStatus[index].status = status;
					} else if (fileIndex != -1) {
						updatedStatus.push({ fileId: fileId, fileName: uploadQueue[fileIndex].originalName, status: status });
					}

					const isUploading = updatedStatus.some((v) => Number(v.status) < 100);
					const allUploadsCompleted = updatedStatus.filter((v) => Number(v.status) == 100).length == updatedStatus.length;
					if (updatedStatus.length > 0 && !isUploading && allUploadsCompleted) {
						stopRefreshingProgress();
					}
					return updatedStatus;
				});
			});
		}
	};

	const getUploadStatus = (fileId: string): UploadStatus | undefined => {
		const index = uploadStatus.findIndex((item) => item.fileId === fileId);
		if (index !== -1) {
			return uploadStatus[index];
		}
	};

	const refreshingProgress = () => {
		updateUploadStatus();
		if (!updater || updater == undefined) {
			updater = setInterval(() => {
				updateUploadStatus();
			}, 2000);
		}
	};

	const stopRefreshingProgress = () => {
		clearInterval(updater);
		updater = null;
		if (updater) {
			setTimeout(() => stopRefreshingProgress(), 1000);
		}
	};

	const addToStatusQueue = (file: BlobFile) => {
		setUploadStatus((prev) => [...prev, { fileId: file.name, fileName: file.originalName, status: "0" }]);
		refreshingProgress();
	};

	const removeFromStatusQueue = (fileId: string) => {
		setUploadStatus((prevQueue) => prevQueue.filter((item) => item.fileId !== fileId));
	};

	const abortUpload = (fileId: string) => {
		BlobStorageService.abortUpload(fileId);
		removeFromUploadQueue(fileId);
		removeFromStatusQueue(fileId);
	};

	const clearStatusQueue = () => {
		if (!uploadStatus.some((v) => v.status !== "100")) {
			BlobStorageService.clearProgressAll();
			setUploadStatus([]);
			stopRefreshingProgress();
			setIsFileUploaded("");
		}
	};

	return (
		<UploadStatusContext.Provider
			value={{
				uploadStatus,
				getUploadStatus,
				addToStatusQueue,
				removeFromStatusQueue,
				clearStatusQueue,
				refreshingProgress,
				stopRefreshingProgress,
				abortUpload,
			}}>
			{children}
		</UploadStatusContext.Provider>
	);
};

const useUploadStatusContext = () => {
	const context = useContext(UploadStatusContext);
	if (context === undefined) {
		throw new Error("useUploadContext must be used within an UploadProvider");
	}
	return context;
};

export { UploadStatusProvider, useUploadStatusContext };
