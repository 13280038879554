import { motion } from "framer-motion";

const InputError = (props: { message: string }) => {
	return (
		<span>
			<motion.span
				className='g-content-center-y--xs g-position-10--top g-font-weight--700 g-padding-x-5--xs g-color--red g-radius--4 input-error-label'
				initial={{ opacity: 0, y: -10 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -10 }}
				transition={{ duration: 0.2 }}
				style={{ top: "-1px" }}>
				<span className='material-symbols-sharp g-font-size-18--xs g-margin-r-5--xs'>error</span>
				{props.message}
			</motion.span>
		</span>
	);
};

export default InputError;
