import { Block, BlockNoteEditor, PartialBlock } from "@blocknote/core";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/react";
import "@blocknote/react/style.css";
import { Skeleton } from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";

interface EditorProps {
	name: string;
	filePath: string | undefined;
	isLoading: boolean;
	currentBlobIndex: number;
	callback: (document: any) => void;
}

const Editor = memo((props: EditorProps) => {
	const [initialContent, setInitialContent] = useState<PartialBlock[] | undefined>([{}] as PartialBlock[]);
	const [localLoading, setLocalLoading] = useState<any>(true);
	async function saveToStorage(jsonBlocks: Block[]) {
		// Save contents to local storage. You might want to debounce this or replace
		// with a call to your API / database.

		localStorage.setItem(props.name, JSON.stringify(jsonBlocks));
		props.callback(await editor?.blocksToHTMLLossy(jsonBlocks));
	}

	async function loadFromStorage() {
		// Gets the previously stored editor contents.
		const storageString = localStorage.getItem(props.name);
		return storageString ? (JSON.parse(storageString) as PartialBlock[]) : undefined;
	}

	const loadDocument = async () => {
		if (props.filePath && !props.isLoading) {
			setLocalLoading(true);
			fetch(props.filePath).then(async (res: any) => {
				const text = await res.text();
				const blocks = await editor?.tryParseHTMLToBlocks(text);
				if (blocks) {
					saveToStorage(blocks);
					setInitialContent(blocks);
					// editor.replaceBlocks(editor.document, blocks);
				}
				setLocalLoading(false);
			});
		} else {
			setInitialContent([{}] as PartialBlock[]);
			localStorage.removeItem(props.name);
			setLocalLoading(false);
		}
	};

	// const editor = useCreateBlockNote({});
	const editor = useMemo(() => {
		return BlockNoteEditor.create({ initialContent });
	}, [initialContent]);

	// Loads the previously stored editor contents.
	useEffect(() => {
		loadDocument();
	}, [props.currentBlobIndex]);

	// Renders the editor instance.
	return (
		<>
			{editor === undefined || props.isLoading || localLoading ? (
				<>
					{[1, 1, 1, 1, 1, 1].map((idx) => (
						<div className='g-margin-t-10--xs g-width-100-percent--xs g-padding-x-40--xs'>
							<Skeleton variant='rectangular' width={"100%"} sx={{ fontSize: "1rem" }} />
						</div>
					))}
				</>
			) : (
				<BlockNoteView
					editor={editor}
					theme={"light"}
					onChange={() => {
						saveToStorage(editor.document);
					}}
					style={{ minHeight: "90vh" }}
				/>
			)}
		</>
	);
});

export default Editor;
