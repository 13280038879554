import { Avatar } from "@mui/material";

interface UserAvatarProps {
	size?: number;
	color?: string;
}

const UserAvatar = (props: any) => {
	let { size, color, radius, ...children } = props;
	size = size ?? 20;
	return (
		<>
			<Avatar
				sx={{
					width: size,
					height: size,
					marginRight: 1,
					background: color ?? "green",
					borderRadius: radius ?? "50%",
					fontSize: size / 2.2,
					alignItems: "center",
				}}
				{...children}
			/>
		</>
	);
};

export default UserAvatar;
