import APIPaths from "../constants/ApiPaths";
import { Task, Workspace } from "../models/Interface";
import Api from "./Api";

export namespace WorkspaceWebService {
	export const fetchAllWorkspaces = () => {
		return new Promise((resolve, reject) => {
			Api.get(APIPaths.WorkspaceAPI)
				.then((response) => {
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data as Array<Workspace> | [];
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const fetchWorkspaceById = (workspaceId: string) => {
		return new Promise((resolve, reject) => {
			Api.get(`workspace/${workspaceId}`)
				.then((response) => {
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data as Workspace | {};
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const createWorkspace = (body: any) => {
		return new Promise((resolve, reject) => {
			Api.post("/workspace", body)
				.then((response) => {
					let responseOK = response && response.status === 201;
					if (responseOK) {
						const data = response.data;
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const inviteUsersToWorkspace = (workspaceId: string, body: any) => {
		return new Promise((resolve, reject) => {
			Api.post(`/workspace/${workspaceId}/memberids`, body)
				.then((response) => {
					let responseOK = response && response.status === 201;
					if (responseOK) {
						const data = response.data;
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const fetchAllUserTasks = (workspaceId: string) => {
		return new Promise((resolve, reject) => {
			Api.get(`/workspace/${workspaceId}/task`)
				.then((response) => {
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data as Array<Task>;
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const fetchTaskById = (taskId: string) => {
		return new Promise((resolve, reject) => {
			Api.get(`task/${taskId}`)
				.then((response) => {
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data;
						if (data.length > 0) resolve(data[0] as Task);
						else resolve(null);
					} else {
						reject(response);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const updateTaskStatus = (taskId: string, status: string) => {
		return new Promise((resolve, reject) => {
			Api.post(`/task/${taskId}/status/${status}`, {})
				.then((response) => {
					let responseOK = (response && response.status === 200) || response.status === 201;
					if (responseOK) {
						const data = response.data;
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const uploadFileInTask = (taskId: string, body: any) => {
		return new Promise((resolve, reject) => {
			Api.post(`/task/${taskId}/fileVersion`, body)
				.then((response) => {
					let responseOK = (response && response.status === 200) || response.status === 201;
					if (responseOK) {
						const data = response.data;
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const isStreamingEndpointActive = () => {
		return new Promise((resolve, reject) => {
			Api.post(`/streaming`)
				.then((response) => {
					let responseOK = response && response.status === 200;
					let responseInternalServerErr = response && response.status === 500;
					if (responseOK) {
						resolve(true);
					} else if (responseInternalServerErr) {
						resolve(false);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
	export const updateTaskReviewers = (taskId: string, body: string[]) => {
		return new Promise((resolve, reject) => {
			Api.post(`/task/${taskId}/reviewers`, body)
				.then((response) => {
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data;
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
}
