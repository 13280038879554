import { BlobFile, FileNode } from "../models/Interface";

export default function buildFileTree(blobFiles: BlobFile[]): FileNode[] {
	const root: FileNode = { name: "", file: {} as BlobFile, children: [] };

	blobFiles.forEach((blobFile) => {
		const parts = blobFile.originalName.split("/"); // Split originalName to determine the hierarchy
		let currentNode = root;

		parts.forEach((part, index) => {
			let childNode = currentNode.children?.find((child) => child.name === part);

			if (!childNode) {
				// Determine if it's the last part to decide if it's a file or a folder placeholder
				const isLastPart = index === parts.length - 1;
				childNode = {
					name: part,
					file: isLastPart ? blobFile : ({ originalName: part } as BlobFile),
					children: [],
				};
				currentNode.children!.push(childNode);
			}

			currentNode = childNode;
		});
	});
	return root.children!;
}
