import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WorkspaceWebService } from "../../services/WorkspaceWebService";
import { AvatarIntials } from "../../utils/Helpers";
import { useAuth } from "../AuthContext";
import { useLoading } from "../LoadingContext";

export interface ChangeWSMenuProps {
	closeHandler: any;
	anchorElement: any;
}

const ChangeWorkspaceMenu = (props: ChangeWSMenuProps) => {
	const navigate = useNavigate();
	const { workspaces, user, setupWorkspace } = useAuth();
	const { showLoading, hideLoading } = useLoading();
	const openMenu = Boolean(props.anchorElement);
	const changeWorkspace = (workspaceId: string, active: boolean = false) => {
		props.closeHandler();
		if (!active || active == undefined || active == null) navigate(`/${workspaceId}/tasks`);
	};

	useEffect(() => {
		fetchWorkspaces();
	}, []);

	const fetchWorkspaces = () => {
		showLoading();
		WorkspaceWebService.fetchAllWorkspaces()
			.then((workspaces: any) => {
				setupWorkspace(workspaces);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				hideLoading();
			});
	};

	return (
		<Menu
			id='basic-menu'
			anchorEl={props.anchorElement}
			open={openMenu}
			onClose={props.closeHandler}
			sx={{ "& .MuiPaper-root": { minWidth: "16rem", border: "1px solid #ddd", borderRadius: "0.25rem" } }}
			MenuListProps={{
				"aria-labelledby": "basic-button",
				style: { padding: "0.5rem 0" },
			}}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}>
			<div>
				<div
					className='g-font-size-12--xs g-padding-x-5--xs g-margin-b-5--xs g-font-weight--700 g-color--text g-line-height--sm'
					style={{ paddingLeft: "1rem" }}>
					<span className='material-symbols-sharp g-margin-r-5--xs'>account_circle</span>
					{user.email}
				</div>
			</div>

			{workspaces?.map((workspace) => {
				return (
					<MenuItem key={workspace.id} onClick={() => changeWorkspace(workspace.id, workspace.isCurrent)} sx={{ padding: "0.5rem 2.5rem 0.5rem 1rem" }}>
						<div className='g-display-flex--xs' style={{ alignItems: "center", flexDirection: "row" }}>
							<Avatar sx={{ bgcolor: "green", width: 32, height: 32, fontSize: 14, mr: 1, borderRadius: "0" }}>{AvatarIntials(workspace.name)}</Avatar>
							<div>
								<div className='g-font-size-14--xs g-font-weight--700 g-color--dark'>{workspace.name}</div>
								<div className='g-font-size-12--xs g-font-weight--400 g-color--text g-line-height--sm'>Free Plan | {workspace.members.length} members</div>
							</div>
						</div>
					</MenuItem>
				);
			})}
			<Divider />
			<MenuItem onClick={() => navigate("/onboarding")}>
				<span className='material-symbols-sharp g-margin-r-5--xs'>add</span>Create New Workspace
			</MenuItem>
		</Menu>
	);
};

export { ChangeWorkspaceMenu };
