import { Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { MultipleSelectChipProps } from "./MultipleSelectChip";

const MultipleSelectFreeSolo = (props: MultipleSelectChipProps) => {
	let { name, label, placeholder, value, options, allowMultiple, onSelect, disableClearable, sx, disabled } = props;
	const handleChange = (event: any, value: any) => {
		onSelect(typeof value === "string" ? [value] : value);
	};
	return (
		<>
			<label htmlFor={name}>{label}</label>
			<Autocomplete
				className='g-margin-b-20--xs'
				sx={{
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "gray",
						borderWidth: ".075rem !important",
					},
					"& .MuiOutlinedInput-root": {
						padding: "0.3rem 0.5rem",
						height: "max(2.375rem,fit-content) !important",
						width: "100%",
					},
					"&.MuiTextField-root": {
						display: "none",
						"&.MuiInputLabel-root": {
							display: "none",
						},
					},
					input: {
						color: "var(--dark) !important",
						fontSize: "0.8125rem",
						letterSpacing: "0.05rem",
						"&::placeholder": {
							opacity: 1,
							color: "rgba(34, 35, 36, 0.8)",
						},
					},
					...sx,
				}}
				id='combo-box-demo'
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				freeSolo
				multiple={allowMultiple}
				value={value}
				options={options.map((option) => option.value ?? "")}
				onChange={handleChange}
				disableClearable={disableClearable}
				renderTags={(value: readonly string[], getTagProps) =>
					value.map((option: string, index: number) => <Chip size='small' label={option} {...getTagProps({ index })} />)
				}
				renderInput={(params) => <TextField className='lol' {...params} placeholder={placeholder} />}
				disabled={disabled ?? false}
			/>
		</>
	);
};
export default MultipleSelectFreeSolo;
