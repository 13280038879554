import { Accordion, AccordionDetails, AccordionSummary, List, ListItem } from "@mui/material";
import Button from "../atoms/Button";
import CircularProgressWithLabel from "../atoms/CircularProgressWithLabel";
import { useUploadStatusContext } from "../UploadStatusContext";

const UploadStatus = () => {
	const { uploadStatus, clearStatusQueue: clearStatusList, abortUpload } = useUploadStatusContext();
	function handleClick(event: any): void {
		clearStatusList();
	}

	const handleCancelUpload = (item: any) => {
		abortUpload(item.fileId);
	};

	return (
		<div style={{ position: "fixed", bottom: 0, right: 25, zIndex: 1002, width: 420, display: uploadStatus.length > 0 ? "block" : "none" }}>
			<Accordion defaultExpanded>
				<AccordionSummary
					sx={{ background: "#efefef" }}
					expandIcon={<i className='material-symbols-sharp'>expand_more</i>}
					aria-controls='panel1-content'
					id='panel1-header'>
					<div className='g-display-flex--xs' style={{ alignItems: "center", justifyContent: "space-between", width: "97%" }}>
						<div className='g-font-size-18--xs g-font-weight--700'>Upload Progress</div>
						{!uploadStatus.some((v) => v.status !== "100") && (
							<i onClick={handleClick} className='material-symbols-sharp'>
								close
							</i>
						)}
					</div>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: 0, maxHeight: "60vh", overflow: "auto" }}>
					<List>
						{uploadStatus &&
							uploadStatus.map((item, index) => (
								<ListItem key={index}>
									<div className='g-display-flex--xs' style={{ justifyContent: "space-between", alignItems: "center", width: "100%" }}>
										<div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", marginRight: 10, fontSize: 13, fontWeight: 600 }}>
											{item.fileName}
										</div>
										<div className='g-display-flex--xs'>
											<CircularProgressWithLabel value={Number(item.status)} />
											{Number(item.status) !== 100 && (
												<Button
													className='g-font-size-10--xs'
													onClick={() => handleCancelUpload(item)}
													variant='text'
													icon={Number(item.status) === 100 ? "task_alt" : "close"}
													disabled={Number(item.status) === 100}
												/>
											)}
										</div>
									</div>
								</ListItem>
							))}
					</List>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default UploadStatus;
