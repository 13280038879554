import React, { createContext, ReactNode, useContext, useState } from "react";
import { CommentsService } from "../services/CommentsService";
interface Comment {
	id: string;
	commentsId: string;
	commenterId: string;
	parentId?: string;
	thumb: string;
	completed: boolean;
	completerId: string;
	annotation: string;
	frame: number;
	timestampMs: number;
	text: string;
	likeCount: number;
	updatedOn: Date;
	deletedOn?: Date;
	createdOn: Date;
	isDeleted: boolean;
	isLiked: boolean;
	isDisliked: boolean;
	replies: Comment[];
}
interface FileDetails {
	taskId: string;
	fileName: string;
	fileVersionId: string;
}
interface CommentsContextType {
	comments: Comment[];
	setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
	addComment: (comment: any) => void;
	updateComment: (comment: any) => void;
	deleteComment: (comment: any) => void;
	fetchComments: (taskId: any, fileName: any, fileVersionId: any) => void;
	fileDetails: FileDetails;
	setFileDetails: React.Dispatch<React.SetStateAction<FileDetails>>;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CommentsContext = createContext<CommentsContextType | undefined>(undefined);

export const CommentsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [comments, setComments] = useState<any>([]);
	const [fileDetails, setFileDetails] = useState<FileDetails>({
		taskId: "",
		fileName: "",
		fileVersionId: "",
	});
	const [loading, setLoading] = useState<boolean>(false);

	const fetchComments = async (taskId: string, fileName: string, fileVersionId: string) => {
		try {
			const fetchedComments = await CommentsService.getAllComments(taskId, fileName, fileVersionId);
			setLoading(false);
			setTimeout(() => {
				setComments(fetchedComments);
			}, 100);
		} catch (error) {
			console.error("Error fetching comments:", error);
		}
	};

	const addComment = async (comment: any) => {
		try {
			const newComment = await CommentsService.postComment(fileDetails?.taskId, fileDetails?.fileName, fileDetails?.fileVersionId, comment);
			setComments((prevComments: any) => [...prevComments, newComment]);
		} catch (error) {
			console.error("Error adding comment:", error);
		}
	};

	const updateComment = async (comment: any) => {
		try {
			const newComment = await CommentsService.postComment(fileDetails?.taskId, fileDetails?.fileName, fileDetails?.fileVersionId, comment);
			// setComments((prevComments: any) => prevComments.filter((currComment: any) => currComment.id !== comment.id).concat(newComment));
			setComments((prevComments: any) => prevComments.map((currComment: any) => (currComment.id === comment.id ? newComment : currComment)));
		} catch (error) {
			console.error("Error adding comment:", error);
		}
	};

	const deleteComment = async (comment: any) => {
		try {
			const newComment = await CommentsService.postComment(fileDetails?.taskId, fileDetails?.fileName, fileDetails?.fileVersionId, comment);
			setComments((prevComments: any) => prevComments.filter((currComment: any) => currComment.id !== comment?.id));
		} catch (error) {
			console.error("Error adding comment:", error);
		}
	};

	return (
		<CommentsContext.Provider
			value={{ comments, setComments, addComment, updateComment, deleteComment, fileDetails, setFileDetails, loading, setLoading, fetchComments }}>
			{children}
		</CommentsContext.Provider>
	);
};

export const useComments = (): CommentsContextType => {
	const context = useContext(CommentsContext);
	if (context === undefined) {
		throw new Error("useComments must be used within a CommentsProvider");
	}
	return context;
};
