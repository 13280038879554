const InputV3 = (props: {
	name: string;
	value?: string;
	type?: string;
	label?: string;
	id?: string;
	placeholder?: string;
	validation?: any;
	multiline?: boolean;
	className?: string;
	style?: any;
	onChange?: any;
	disabled?: boolean;
}) => {
	let { name, value, type, label, id, placeholder, validation, className, ...remaining } = props;
	name = name ?? "";
	type = type ?? "text";
	id = id ?? name;
	placeholder = placeholder ?? `Enter ${name}`;

	return (
		<div className='g-position--relative g-width-100-percent--xs'>
			<label htmlFor={id} className='g-font-weight-800-xs g-letter-spacing-1--xs'>
				<strong>{label}</strong>
			</label>
			<input
				id={id}
				placeholder={placeholder}
				type={type}
				value={value}
				{...remaining}
				className={`s-form-v3__input g-width-100-percent--xs g-margin-b-0--xs ${className}`}></input>
		</div>
	);
};

export default InputV3;
