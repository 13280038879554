export const findInputError = (errors: any, name: string): any => {
	const filtered = Object.keys(errors)
		.filter((key) => key.includes(name))
		.reduce((cur, key) => {
			return Object.assign(cur, { error: errors[key] });
		}, {});
	return filtered;
};

export const isFormInvalid = (errors: any): boolean => {
	if (Object.keys(errors).length > 0) return true;
	return false;
};
