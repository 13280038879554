import Grid from "@mui/material/Grid";
import RP from "../../constants/RoutePaths";
import Title from "../atoms/Title";
import LoginForm from "../molecules/LoginForm";
const LoginPage = () => {
	Title(`GoBananas | Login | Welcome Back`);
	return (
		<>
			<Grid container justifyContent='center'>
				<Grid item xs={12} md={7} display={{ xs: "none", md: "block" }}>
					<div
						style={{
							height: "100vh",
							background: "url(/svg/gobananas-login.svg)",
							backgroundColor: "#f7f7f7",
							backgroundSize: "contain",
							backgroundRepeat: "no-repeat",
						}}></div>
				</Grid>
				<Grid item xs={12} md={5} className='g-padding-y-40--xs g-padding-x-20--xs g-padding-x-80--md'>
					<Grid width={"100%"} display={"inline-flex"} justifyContent={"space-between"}>
						<div>
							<a href={RP.ROOT}>
								<img src='/logo.png' height='30'></img>
							</a>
						</div>
						<div>{/* <a href={RP.SignUp}>Create an account</a> */}</div>
					</Grid>

					<Grid className='g-margin-t-100--xs'>
						<LoginForm></LoginForm>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default LoginPage;
