// UploadContext.tsx

import React, { ReactNode, createContext, useContext, useState } from "react";
import { BlobFile } from "../models/Interface";
import { BlobStorageService } from "../services/BlobStorageService";
import { WorkspaceWebService } from "../services/WorkspaceWebService";

interface UploadContextType {
	uploadQueue: BlobFile[];
	isFileUploaded: string;
	setIsFileUploaded: any;
	addToUploadQueue: (file: BlobFile) => Promise<void>;
	removeFromUploadQueue: (fileId: string) => void;
}

const UploadContext = createContext<UploadContextType | undefined>(undefined);
const UploadProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [uploadQueue, setUploadQueue] = useState<BlobFile[]>([]);
	const [isFileUploaded, setIsFileUploaded] = useState("");

	const addToUploadQueue = (file: BlobFile) => {
		const fileId = file.name;
		setUploadQueue((prevQueue) => [...prevQueue, file]);

		return new Promise<void>((resolve, reject) => {
			BlobStorageService.uploadFile(file)
				.then((res) => {
					BlobStorageService.getBlobsListAtPath(file.name).then((files) => {
						const current = files.filter((f) => f.isCurrentVersion);
						if (current.length > 0 && file.taskId) {
							file.isCurrentVersion = current[0].isCurrentVersion;
							file.path = current[0].path;
							file.versionId = current[0].versionId;
							file.name = current[0].name;
							WorkspaceWebService.uploadFileInTask(file.taskId, [file]).then(async (res) => {
								removeFromUploadQueue(fileId); // Remove file from upload queue on successful upload
								setIsFileUploaded(fileId);
							});

							if (file.path && file.isCurrentVersion) {
								const blobName = new URL(decodeURIComponent(file.path)).pathname.split("/").slice(2).join("/");
								BlobStorageService.setBlobContentDispositionHeader(blobName, file.originalName);
							}
							resolve();
						}
					});
				})
				.catch((error) => {
					console.error("Error uploading file:", error);
					removeFromUploadQueue(file.name); // Remove file from upload queue on error
					reject(error);
				});
		});
	};

	const removeFromUploadQueue = (fileName: string) => {
		setUploadQueue((prevQueue) => prevQueue.filter((item) => !fileName.includes(item.name)));
		if (fileName == isFileUploaded) {
			setIsFileUploaded("");
		}
	};

	return (
		<UploadContext.Provider
			value={{
				uploadQueue,
				isFileUploaded,
				setIsFileUploaded,
				addToUploadQueue,
				removeFromUploadQueue,
			}}>
			{children}
		</UploadContext.Provider>
	);
};

const useUploadContext = () => {
	const context = useContext(UploadContext);
	if (context === undefined) {
		throw new Error("useUploadContext must be used within an UploadProvider");
	}
	return context;
};

export { UploadProvider, useUploadContext };
