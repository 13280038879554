import axios from "axios";
import APIPaths from "../constants/ApiPaths";
import { StorageService } from "./StorageService";

const Api = axios.create({
	baseURL: APIPaths.BaseURL,
	timeout: 600000,
});

// Add an interceptor to include the JWT token in the authorization header
Api.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

Api.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response?.status === 401) {
			StorageService.logOut();
			window.location.href = "/";
		} else {
			if (error.response?.status === 403) {
				error.message = "Invalid privileges to add or update the entity.";
			}
			throw error;
		}
	}
);

export default Api;
