import Grid from "@mui/material/Grid";
import Title from "../atoms/Title";
import LoginForm from "../molecules/LoginForm";

const ForgotPassword = () => {
	Title(`GoBananas | Change your password`);
	return (
		<>
			<Grid container justifyContent='center'>
				<Grid item xs={12} md={7} display={{ xs: "none", md: "block" }}>
					<div style={{ height: "100vh", background: "url(/svg/gobananas-login.svg)", backgroundColor: "#f7f7f7", backgroundSize: "cover" }}></div>
				</Grid>
				<Grid item xs={12} md={5} className='g-padding-y-40--xs g-padding-x-20--xs g-padding-x-80--md'>
					<Grid width={"100%"} display={"inline-flex"} justifyContent={"space-between"}>
						<div>
							<a href='https://gobanans.live'>
								<img src='/logo.png' height='30'></img>
							</a>
						</div>
						<div>
							<a href='/login'>Back to login</a>
						</div>
					</Grid>

					<Grid className='g-margin-t-100--xs'>
						<h1 className='g-margin-b-40--xs '>
							<b>Reset your password</b>
						</h1>
						<LoginForm></LoginForm>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default ForgotPassword;
