export const name_validation = {
	required: {
		value: true,
		message: "required",
	},
	maxLength: {
		value: 30,
		message: "30 characters max",
	},
};

export const desc_validation = {
	required: {
		value: true,
		message: "required",
	},
	maxLength: {
		value: 200,
		message: "200 characters max",
	},
};

export const password_validation = {
	required: {
		value: true,
		message: "required",
	},
	minLength: {
		value: 8,
		message: "min 8 characters",
	},
};

export const num_validation = {
	required: {
		value: true,
		message: "required",
	},
};

export const email_validation = {
	pattern: {
		value:
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		message: "not valid",
	},
};
