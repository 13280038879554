import { Grid } from "@mui/material";
import { NodeProps } from "reactflow";
import { TaskTemplate } from "../../../models/Interface";
import ActivityIcon from "../../atoms/ActivityIcon";
import ActivityIconSoon from "../../atoms/ActivtyIconSoon";
import BaseNode from "./BaseNode";
import wfDef from "./workflowDefinitionSchema";

const ActivityDrawerNode = ({ id, type, data }: NodeProps<TaskTemplate>) => {
	const activities = wfDef["properties"]["activities"]["properties"];
	const builtIn = activities["builtIn"]["enum"];
	const external = activities["external"]["enum"];
	const definitions = wfDef["properties"]["activities"]["definitions"] as any;
	return (
		<BaseNode id={id} type={type} data={data}>
			<div>
				<Grid container>
					{builtIn.map((activityType: string, index) => {
						return (
							<Grid key={index} item xs={6}>
								<ActivityIcon activity={{ ...definitions[activityType] }} />
							</Grid>
						);
					})}
				</Grid>
			</div>
			<br />
			<div>
				<Grid container>
					{external.map((activityType: string, index) => {
						return (
							<Grid key={index} item xs={3}>
								<ActivityIconSoon activity={{ ...definitions[activityType] }} />
							</Grid>
						);
					})}
				</Grid>
			</div>
		</BaseNode>
	);
};

export default ActivityDrawerNode;
