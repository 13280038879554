class NodeTypes {
	public static ActivityDrawerNode: string = "ActivityDrawer";
	public static ScriptNode: string = "Script";
	public static RecordingsNode: string = "Recordings";
	public static ThumbnailNode: string = "Thumbnail";
	public static ImageNode: string = "Image";
	public static VideoNode: string = "Video";
	public static DocumentNode: string = "Document";
	public static AudioNode: string = "Audio";

	//External
	public static ReleaseYoutubeNode: string = "ReleaseYoutube";
}
export default NodeTypes;
