import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export type MultipleSelectChipOption = {
	label: string;
	key?: string;
	value?: string;
	icon?: string;
};

export interface MultipleSelectChipProps {
	name: string;
	placeholder: string;
	label: string;
	value?: string[];
	options: MultipleSelectChipOption[];
	allowMultiple?: boolean;
	disableClearable?: boolean;
	sx?: any;
	onSelect: (value: string[] | string) => void;
	disabled?: boolean;
}

const MultipleSelectChip = (props: MultipleSelectChipProps) => {
	let { name, label, placeholder, value, options, allowMultiple, sx, onSelect } = props;
	allowMultiple = allowMultiple ?? false;
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	value = typeof value === "string" ? [value] : value;
	const handleChange = (event: any) => {
		let {
			target: { value },
		} = event;
		onSelect(typeof value === "string" ? [value] : value);
	};

	const getLabel = (value: string) => {
		const filtered = options.filter((option) => option.value === value);
		return filtered.length > 0 ? filtered[0].label : value;
	};

	const getIcon = (value: string): string => {
		const filtered = options.filter((option) => option.value === value);
		return filtered.length > 0 ? (filtered[0].icon ? filtered[0].icon.toUpperCase() : "") : "";
	};

	return (
		<div>
			<label htmlFor={name}>{label}</label>
			<div style={{ width: "100%" }} className='g-margin-b-20--xs'>
				<Select
					sx={{
						height: "2.375rem",
						width: "100%",
						"& .MuiOutlinedInput-notchedOutline": {
							borderColor: "var(--dark)",
							borderWidth: ".075rem !important",
						},
						"& .MuiSelect-select": {
							padding: "0 0.5rem",
						},
						...sx,
					}}
					labelId='demo-multiple-chip-label'
					id='demo-multiple-chip'
					multiple={allowMultiple}
					displayEmpty
					name={name}
					value={value}
					onChange={handleChange}
					onClick={() => setOpen(() => !open)}
					renderValue={(selected) => {
						if (selected && selected.length === 0) {
							return <div style={{ color: "rgba(34, 35, 36, 0.8)", fontSize: "0.8125rem", letterSpacing: "0.05rem" }}>{placeholder}</div>;
						} else if (selected) {
							return (
								<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
									{selected.map((option: any, index) =>
										getIcon(option)?.length > 0 ? (
											<Chip
												avatar={<Avatar src={getIcon(option)}></Avatar>}
												sx={{ backgroundColor: "transparent" }}
												size='small'
												key={index}
												label={getLabel(option)}
											/>
										) : (
											<Chip size='small' key={index} label={getLabel(option)} />
										)
									)}
								</Box>
							);
						}
					}}
					MenuProps={MenuProps}>
					{options.map((option, index) => (
						<MenuItem key={index} value={option.value}>
							{`${option.label}`}
						</MenuItem>
					))}
					{options.length == 0 && <MenuItem disabled>Nothing to add here</MenuItem>}
				</Select>
			</div>
		</div>
	);
};
export default MultipleSelectChip;
