import { Autocomplete, TextField } from "@mui/material";
import { ReactNode } from "react";

interface AutoCompleteProps {
	options: any[];
	placeholder: string;
	defaultValue?: any;
	children: ReactNode;
}

const AutoCompleteSm = (props: any) => {
	const { options, placeholder, value, defaultValue, ...remaining } = props;
	return (
		<>
			<Autocomplete
				sx={{
					width: "100%",
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "var(--dark) !important",
						borderWidth: ".065rem !important",
					},
					"& .MuiOutlinedInput-root": {
						padding: "0.1rem 0.5rem",
						borderRadius: "0.25rem",
						height: "33px",
					},
					"&.MuiTextField-root": {
						display: "none",
						"&.MuiInputLabel-root": {
							display: "none",
						},
					},
					"&.MuiInputLabel-root": {
						display: "none",
					},
					input: {
						color: "var(--dark) !important",
						fontSize: "0.8125rem",
						letterSpacing: "0.05rem",
						padding: "4px !important",
						"&::placeholder": {
							opacity: 1,
							color: "rgba(34, 35, 36, 0.8)",
						},
					},
				}}
				{...remaining}
				id='combo-box-demo'
				disablePortal
				disableClearable
				autoSelect
				options={options}
				defaultValue={options[0]}
				value={value}
				isOptionEqualToValue={(option: any, value) => option.label == value.label}
				renderInput={(params: any) => <TextField {...params} className='s-form-v3__input' placeholder={props.placeholder} />}
			/>
		</>
	);
};
export default AutoCompleteSm;
