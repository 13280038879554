import { useContext } from "react";
import { TaskTemplate } from "../../models/Interface";
import { WorkflowContext } from "../templates/dashboard/WorkflowEditor";
export interface ActivityIconProps {
	activity: TaskTemplate;
}

const ActivityIcon = (props: ActivityIconProps) => {
	let { activity } = props;

	const { handleAddActivityFromDrawer }: any = useContext(WorkflowContext);
	return (
		<div onClick={() => handleAddActivityFromDrawer(activity)}>
			<div style={{ background: activity.theme ?? "#aaa", cursor: "pointer" }} className={"g-content-center-xy--xs g-width-50--xs g-height-50--xs g-radius--3"}>
				<span className='material-symbols-sharp g-color--white-opacity g-font-size-36--xs'>{activity.avatar ?? "browse_activity"}</span>
			</div>
			<div className='g-text-left--xs g-margin-t-5--xs'>
				<p className='g-line-height--sm g-font-size-13--xs'>
					{"Content Manager"}
					<br />
					<span className='g-line-height--sm g-font-size-11--xs'>{activity.description}</span>
				</p>
			</div>
		</div>
	);
};

export default ActivityIcon;
