import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { GetTaskStatusList, StatusLabels } from "../../constants/TaskStatus";
import StateCircle from "./StateCircle";
import StatusChip from "./StatusChip";

const StatusChipMenu = (props: any) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { status, handleTaskStateChange, ...rest } = props;
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const [currentstatus, SetCurrentStatus] = useState(status);
	const handleChangeStatus = (newStatus: string) => {
		handleTaskStateChange(newStatus);
		const formattedState = newStatus.toUpperCase().replace(/\s+/g, "");
		const finalState = formattedState === "NEEDSREVIEW" ? "NEEDS_REVIEW" : formattedState;
		SetCurrentStatus(finalState);
		handleClose();
	};

	return (
		<>
			<StatusChip
				status={currentstatus}
				{...rest}
				onClick={handleClick}
				onDelete={handleClick}
				deleteIcon={<i className='material-symbols-sharp g-font-size-20--xs g-color--dark-light'>arrow_drop_down</i>}
			/>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}>
				{GetTaskStatusList().map((item) => (
					<MenuItem key={item.label} onClick={() => handleChangeStatus(item.label)}>
						<StateCircle color={StatusLabels.getColor(item.value)} />
						<span className='g-margin-l-5--xs'>{item.label}</span>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default StatusChipMenu;
