import { useState } from "react";
import Button from "../atoms/Button";
import MoreOptionMenu from "./MoreOptionMenu";

const ButtonMenu = (props: any) => {
	let { menuOptions, ...buttonProps } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			<Button onClick={handleMenuClick} {...buttonProps} />
			<MoreOptionMenu anchorElement={anchorEl} closeHandler={handleMenuClose} items={menuOptions} />
		</>
	);
};

export default ButtonMenu;
