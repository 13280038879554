import { Menu, MenuItem } from "@mui/material";

export interface MoreOptionMenuItem {
	title: string;
	icon?: string;
	subtitle?: string;
	isDisable?: boolean;
	action: () => void;
}

export interface MoreOptionMenuProps {
	closeHandler: any;
	anchorElement: any;
	items: MoreOptionMenuItem[];
}
const MoreOptionMenu = (props: MoreOptionMenuProps) => {
	const openMenu = Boolean(props.anchorElement);
	const taskAction = (action: any) => {
		action();
		props.closeHandler();
	};
	return (
		<>
			<Menu
				id='basic-menu'
				anchorEl={props.anchorElement}
				open={openMenu}
				onClose={props.closeHandler}
				sx={{ "& .MuiPaper-root": { minWidth: "12rem", border: "1px solid #ddd", borderRadius: "0.25rem" } }}
				MenuListProps={{
					"aria-labelledby": "basic-button",
					style: { padding: "0.2rem 0" },
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}>
				{props.items.map((item, index) => (
					<MenuItem key={index} onClick={() => taskAction(item.action)} disabled={item.isDisable ?? false}>
						<div key={item.subtitle}>
							{item.icon && <span className='material-symbols-sharp g-margin-r-5--xs'>{item.icon}</span>}
							{item.title}
							{item.subtitle && <p className='g-color--dark-light g-font-size-13--xs'>{item.subtitle}</p>}
						</div>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default MoreOptionMenu;
