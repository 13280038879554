import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { WorkspaceWebService } from "../../services/WorkspaceWebService";
import { useAuth } from "../AuthContext";
import { useLoading } from "../LoadingContext";
import { useNotification } from "../NotificationContext";
import Title from "../atoms/Title";
import MainSidebar from "../molecules/DashboardSidebar";
import UploadStatus from "../molecules/UploadStatus";

interface DashboardContextType {
	open: boolean;
	toggleOpen: () => void;
	closeSidebar: () => void;
	openSidebar: () => void;
}

export const DashboardContext = React.createContext<DashboardContextType | null>(null);

const Dashboard = (props: any) => {
	const [open, setOpen] = React.useState(true);
	const { changeWorkspace, currentWorkspace } = useAuth();
	const { showLoading, hideLoading } = useLoading();
	const { showErrorMessage } = useNotification();
	const params = useParams();
	const toggleOpen = () => {
		setOpen(() => !open);
	};
	const closeSidebar = () => {
		setOpen(false);
	};
	const openSidebar = () => {
		setOpen(true);
	};
	const workspaceId = params.workspaceId || "";

	const fetchWorkspace = () => {
		if (workspaceId != undefined) {
			showLoading();
			WorkspaceWebService.fetchWorkspaceById(workspaceId)
				.then((workspace: any) => {
					if (workspace) {
						changeWorkspace({ ...workspace });
					}
				})
				.catch((err) => {
					showErrorMessage("Unable to fetch data");
				})
				.finally(() => {
					hideLoading();
				});
		}
	};

	useEffect(() => {
		fetchWorkspace();
	}, [workspaceId]);
	Title(`GoBananas | ${currentWorkspace?.name}`);

	useEffect(() => {
		const handleContextmenu = (e: any) => {
			e.preventDefault();
		};
		// document.addEventListener("contextmenu", handleContextmenu);
		return function cleanup() {
			document.removeEventListener("contextmenu", handleContextmenu);
		};
	}, []);
	return (
		<Box sx={{ display: "flex" }}>
			{/* <AppHeader /> */}
			<DashboardContext.Provider value={{ open: open, toggleOpen, closeSidebar, openSidebar }}>
				<MainSidebar></MainSidebar>
				<UploadStatus />
				<Box component='main' sx={{ flexGrow: 1, mt: 0 }}>
					<Outlet />
				</Box>
			</DashboardContext.Provider>
		</Box>
	);
};

export default Dashboard;
