import Grid from "@mui/material/Grid";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { WorkspaceWebService } from "../../services/WorkspaceWebService";
import { email_validation } from "../../utils/InputValidations";
import { useAuth } from "../AuthContext";
import { useLoading } from "../LoadingContext";
import { useNotification } from "../NotificationContext";
import Button from "../atoms/Button";
import InputV2 from "../atoms/InputV2";
const InviteUsersForm = () => {
	const methods = useForm({ mode: "onBlur" });
	const [success, setSuccess] = useState(false);
	const { showLoading, hideLoading } = useLoading();
	const { showSuccessMessage, showErrorMessage } = useNotification();

	const { currentWorkspace, changeWorkspace } = useAuth();

	const fetchWorkspace = () => {
		if (currentWorkspace?.id) {
			showLoading();
			WorkspaceWebService.fetchWorkspaceById(currentWorkspace?.id)
				.then((workspace: any) => {
					if (workspace) {
						changeWorkspace(workspace);
					}
				})
				.catch((err) => {
					console.log(err);
					showErrorMessage("Unable to fetch data");
				})
				.finally(() => {
					hideLoading();
				});
		}
	};
	const onSubmit = methods.handleSubmit((data: any) => {
		const members: Array<string> = [];
		if (data["user1"] !== "") {
			members.push(data["user1"]);
		}
		if (data["user2"] !== "") {
			members.push(data["user2"]);
		}
		if (data["user3"] !== "") {
			members.push(data["user3"]);
		}
		if (data["user4"] !== "") {
			members.push(data["user4"]);
		}

		if (currentWorkspace && members.length > 0) {
			showLoading();
			WorkspaceWebService.inviteUsersToWorkspace(currentWorkspace?.id, members)
				.then((value: any) => {
					showSuccessMessage("Invitation have been sent successfully.");
					fetchWorkspace();
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => hideLoading());
		}
	});

	return (
		<div>
			<FormProvider {...methods}>
				<form onSubmit={(e) => e.preventDefault()} noValidate autoComplete='off'>
					<Grid container columnSpacing={1} padding={2}>
						<Grid item xs={12}>
							<InputV2 name='user1' type='text' placeholder='Member Email 1' label='Invite members to your workspace' validation={email_validation} />
						</Grid>
						<Grid item xs={12}>
							<InputV2 name='user2' type='text' placeholder='Member Email 2' validation={{}} />
						</Grid>
						<Grid item xs={12}>
							<InputV2 name='user3' type='text' placeholder='Member Email 3' validation={{}} />
						</Grid>
						<Grid item xs={12}>
							<InputV2 name='user4' type='text' placeholder='Member Email 4' validation={{}} />
						</Grid>
						<Button
							size='sm'
							className='g-margin-t-20--xs g-width-100-percent--xs'
							onClick={onSubmit}
							color='dark'
							variant={"contained"}
							text='Send Invite'></Button>
					</Grid>
				</form>
			</FormProvider>
		</div>
	);
};
export default InviteUsersForm;
