import { TaskTemplate } from "../models/Interface";
import { WorkspaceWebService } from "./WorkspaceWebService";

export namespace WorkspaceService {
	export const fetchAllTaskFiles = async (taskId: string) => {
		return await WorkspaceWebService.fetchTaskById(taskId)
			.then(async (res) => {
				const taskResponse = res as any;
				if (Object.keys(taskResponse).length > 0) {
					const data = taskResponse["data"] as TaskTemplate;
					const files = data.inputs;
					return { files: files, currMemberPermissions: taskResponse?.currMember?.role?.permissions };
				}
				return { files: [], currMemberPermissions: taskResponse?.currMember?.role?.permissions };
			})
			.catch((err) => {
				return { files: [], currMemberPermissions: [] };
			});
	};

	export const fetchAllRefTaskFiles = async (taskId: string) => {
		return await WorkspaceWebService.fetchTaskById(taskId)
			.then(async (res) => {
				const taskResponse = res as any;
				if (Object.keys(taskResponse).length > 0) {
					const data = taskResponse["data"] as TaskTemplate;
					const files = data.references;
					return { files: files, currMemberPermissions: taskResponse?.currMember?.role?.permissions };
				}
				return { files: [], currMemberPermissions: taskResponse?.currMember?.role?.permissions };
			})
			.catch((err) => {
				return { files: [], currMemberPermissions: [] };
			});
	};
}
