import { SocialIcon } from "react-social-icons";
import { ActivityIconProps } from "./ActivityIcon";

const ActivityIconSoon = (props: ActivityIconProps) => {
	let { activity } = props;
	return (
		<div>
			<div style={{ background: "#fff", cursor: "pointer" }} className={"g-content-center-xy--xs g-width-50--xs g-height-50--xs g-radius--3"}>
				<span className='material-symbols-sharp g-color--white-opacity g-font-size-36--xs'>
					<SocialIcon network={activity.avatar} />
				</span>
			</div>
			<div className='g-text-left--xs g-margin-t-5--xs'>
				<p className='g-line-height--sm g-font-size-13--xs'>
					{activity.title ?? "Activity Name"}
					<br />
					<span className='g-line-height--sm g-font-size-11--xs'>{activity.description}</span>
				</p>
			</div>
		</div>
	);
};

export default ActivityIconSoon;
