import Grid from "@mui/material/Grid";
import RP from "../../constants/RoutePaths";
import Title from "../atoms/Title";
import SignupForm from "../molecules/SignupForm";

const SignupPage = () => {
	Title(`GoBananas | Sign Up`);
	return (
		<>
			<Grid container justifyContent='center'>
				<Grid item xs={12} md={7} display={{ xs: "none", md: "block" }}>
					<div
						style={{
							height: "100vh",
							background: "url(/svg/gobananas-signup.svg)",
							backgroundColor: "#f7f7f7",
							backgroundSize: "contain",
							backgroundRepeat: "no-repeat",
						}}></div>
				</Grid>
				<Grid item xs={12} md={5} className='g-padding-y-40--xs g-padding-x-20--xs g-padding-x-80--md'>
					<Grid width={"100%"} display={"inline-flex"} justifyContent={"space-between"}>
						<div>
							<a href={RP.ROOT}>
								<img src='/logo.png' height='30'></img>
							</a>
						</div>
						<div>
							<a href={RP.LOGIN}>Sign In</a>
						</div>
					</Grid>
					<Grid className='g-margin-t-60--xs'>
						<h1 className='g-margin-b-30--xs '>
							<b>Create an account</b>
						</h1>
						<SignupForm></SignupForm>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default SignupPage;
