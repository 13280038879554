import React, { createContext, ReactNode, useContext, useState } from "react";

interface HLSPlayerContextType {
	currentTime: number;
	isPlaying: boolean;
	setCurrentTime: (time: number) => void;
	setIsPlaying: (playing: boolean) => void;
}

const HLSPlayerContext = createContext<HLSPlayerContextType | undefined>(undefined);

export const HLSPlayerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [currentTime, setCurrentTime] = useState(0);
	const [isPlaying, setIsPlaying] = useState(false);

	return <HLSPlayerContext.Provider value={{ currentTime, isPlaying, setCurrentTime, setIsPlaying }}>{children}</HLSPlayerContext.Provider>;
};

export const useHLSPlayer = (): HLSPlayerContextType => {
	const context = useContext(HLSPlayerContext);
	if (context === undefined) {
		throw new Error("useHLSPlayer must be used within an HLSPlayerProvider");
	}
	return context;
};
