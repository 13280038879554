import { Skeleton } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BlobFile } from "../../models/Interface";
import { WorkspaceWebService } from "../../services/WorkspaceWebService";
import { fancyTimeFormat, fileIcon, formatBytes } from "../../utils/Helpers";
import { useAuth } from "../AuthContext";
import ButtonMenu from "../molecules/ButtonMenu";
import { MoreOptionMenuItem } from "../molecules/MoreOptionMenu";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 500,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	borderRadius: "8px",
	p: 4,
};
interface FileTileProps {
	file: BlobFile;
	isUploading: boolean;
	taskId: string;
	progress?: string;
	refTaskId?: string;
	isReferenced?: boolean;
	currentBase?: string;
}

const FileTile = (props: FileTileProps) => {
	const { currentWorkspace } = useAuth();
	const [approved, setApproved] = useState(props?.file?.approved);

	// open menu
	const [open, setOpen] = useState(false);

	// open modal for delete

	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);

	// handle delete function
	const handleDelete = () => {
		setOpen(false);
		setOpenModal(true);
	};

	const handleDownload = () => {
		if (props?.file?.path) {
			window.location.href = props.file.path + `&${new Date().getTime()}`;
		}
	};

	const handleFileStateChange = async () => {
		const taskId = props.taskId;
		const file = props.file;
		const body = { ...file, approved: !approved };
		setApproved(!approved);
		if (file && taskId) {
			await WorkspaceWebService.uploadFileInTask(taskId, [body])
				.then((res) => {})
				.catch((err: any) => setApproved(!approved))
				.finally(() => console.log("4"));
		}
	};

	// handle approve function

	const handleApprove = () => {
		handleFileStateChange();
	};

	const moreMenuOptions: MoreOptionMenuItem[] = [
		{ title: approved ? "Draft" : "Approve File", icon: approved ? "radio_button_unchecked" : "check_circle", action: () => handleApprove() },
		{ title: "Download", icon: "download", action: () => handleDownload() },
		{ title: "Delete", icon: "delete", action: () => handleDelete() },
	];

	return (
		<>
			<>
				<div className='g-padding-x-0--xs g-padding-y-0--xs g-bg-color--gray-light g-radius--10' style={{ border: "1px solid var(--gray)" }}>
					{props.isUploading ? (
						<Skeleton
							variant='rectangular'
							animation='wave'
							height={250}
							className='g-width-100-percent--xs g-height-180--xs g-radius--10 g-content-center-y--xs'
						/>
					) : (
						<Link
							to={`/${currentWorkspace?.id}/tasks/${props.taskId}/${props.file.fileType?.includes("text") ? "doc" : "player"}/${
								props.file.name
							}?approved=${JSON.stringify(props.file.approved ?? false)}&refTaskId=${props.refTaskId ?? ""}&base=${encodeURIComponent(
								props.currentBase ?? ""
							)}`}>
							<div
								className='g-width-100-percent--xs g-height-180--xs g-radius--10 g-content-center-y--xs'
								style={{
									backgroundImage: "url(" + props.file?.thumbnail + ")",
									backgroundSize: "cover",
									backgroundPosition: "center",
									position: "relative",
								}}>
								<div
									style={{
										position: "absolute",
										top: "10%",
										right: "3%",
										backgroundColor: "black",
										color: "white",
										width: "1.7rem",
										height: "1.7rem",
										borderRadius: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										fontSize: "0.55rem",
									}}>
									V{props?.file?.versionNumber ? props?.file?.versionNumber + 1 : 1}
								</div>
								<div
									className='g-width-100-percent--xs g-margin-t-100--xs g-display-flex--xs g-padding-x-10--xs'
									style={{
										justifyContent: "flex-end",
										alignContent: "flex-end",
									}}>
									{props.file.fileType?.includes("video") && props?.file?.duration && (
										<div className='g-radius--4 g-bg-color--dark g-padding-x-10--xs g-color--white g-margin-t-30--xs'>
											{fancyTimeFormat(Number(props?.file?.duration))}
										</div>
									)}
								</div>
							</div>
						</Link>
					)}
					<div className='g-width-100-percent--xs g-padding-x-10--xs g-padding-y-5--xs'>
						{props.isUploading ? (
							<div>{props.progress}</div>
						) : (
							<div className='g-width-100-percent--xs g-content-center-y--xs g-margin-t-0--xs g-font-weight-600--xs'>
								<i className='material-symbols-sharp g-margin-r-5--xs' style={{ fontSize: 14, fontWeight: 600 }}>
									{fileIcon(props.file.fileType)}
								</i>
								<div
									style={{
										flexGrow: 10,
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
									}}>
									<p
										style={{
											textOverflow: "ellipsis",
											overflow: "hidden",
											whiteSpace: "nowrap",
											margin: 0,
											fontSize: 13,
											fontWeight: 600,
										}}>
										{props.file?.originalName?.slice(0, 100)}
									</p>
								</div>

								<div style={{ position: "relative" }}>
									<ButtonMenu
										menuOptions={moreMenuOptions}
										variant='text'
										icon={"more_vert"}
										aria-controls={open ? "basic-menu" : undefined}
										aria-haspopup='true'
										aria-expanded={open ? "true" : undefined}
									/>
									<div>
										<Modal
											aria-labelledby='transition-modal-title'
											aria-describedby='transition-modal-description'
											open={openModal}
											onClose={handleCloseModal}
											closeAfterTransition
											slots={{ backdrop: Backdrop }}
											slotProps={{
												backdrop: {
													timeout: 500,
												},
											}}>
											<Fade in={openModal}>
												<Box sx={style}>
													<div className='ModalDiv'>
														<div className='DeletIconDiv'>
															<div className='divIcon'>
																<span className='material-symbols-sharp'>delete</span>
																Delete this File?
															</div>
															<div className='DeleteText'>
																<div>This file will be deleted.</div>
															</div>
														</div>
														<div className='ModalButton'>
															<MuiButton variant='contained' onClick={() => setOpenModal(false)}>
																Cancel
															</MuiButton>
															<MuiButton variant='contained' color='error'>
																Delete
															</MuiButton>
														</div>
													</div>
												</Box>
											</Fade>
										</Modal>
									</div>
								</div>
							</div>
						)}
						{props.isUploading ? (
							<Skeleton animation='wave' />
						) : (
							<div
								style={{
									textOverflow: "clip",
									overflowX: "auto",
									overflowY: "hidden",
									whiteSpace: "nowrap",
									margin: 0,
									fontSize: 11,
									fontWeight: 600,
									display: "flex",
									justifyContent: "space-between",
								}}>
								<div style={{ display: "flex" }}>
									{approved && (
										<span
											style={{
												padding: "0px 6px",
												background: "var(--success)",
												color: "#fff",
												borderRadius: "8px",
												marginRight: 5,
											}}>
											Approved
										</span>
									)}
									{props.isReferenced && (
										<span
											style={{
												padding: "0px 6px",
												background: "var(--active)",
												color: "#fff",
												borderRadius: "8px",
												marginRight: 5,
											}}>
											Ref
										</span>
									)}
									{props.file.fileType && (
										<span
											style={{
												padding: "0px 6px",
												background: "#444",
												color: "#fff",
												borderRadius: "8px",
												marginRight: 5,
											}}>
											{props.file.fileType}
										</span>
									)}
									{props.file.fileSize && (
										<span
											style={{
												padding: "0px 6px",
												background: "#444",
												color: "#fff",
												borderRadius: "8px",
												marginRight: 5,
											}}>
											{formatBytes(props.file.fileSize)}
										</span>
									)}
								</div>

								<div
									style={{
										padding: "0px",
										background: "transparent",
										color: "var(--dark)",
										marginRight: 10,
										display: "flex",
										alignItems: "center",
									}}>
									<i className='material-symbols-sharp g-margin-r-5--xs g-font-size-14--xs'>comment</i>0
								</div>
							</div>
						)}
					</div>
				</div>
			</>
		</>
	);
};

export default FileTile;
