import { Box, Link, TextareaAutosize, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useHLSPlayer } from "../../../components/HLSPlayerContext";
import { fancyTimeFormat } from "../../../utils/Helpers";
import Button from "../../atoms/Button";
import { useAuth } from "../../AuthContext";
import { useComments } from "../../CommentsContext";
import "./../../../styling/Comments.css";
import ChildComment from "./ChildComments";

const Comment = (props: any) => {
	const { user } = useAuth();
	const { comments, addComment, updateComment, deleteComment } = useComments();
	const [showReplyBox, setShowReplyBox] = useState(false);
	const [replyText, setReplyText] = useState("");
	const [currentText, setCurrentText] = useState(props?.props?.text);
	const { setIsPlaying, setCurrentTime } = useHLSPlayer();
	const [replies, setReplies] = useState<any[]>([]);
	const replyInputRef = useRef<HTMLTextAreaElement>(null);
	const editInputRef = useRef<HTMLTextAreaElement>(null);
	const [liked, setLiked] = useState(false);
	const [disliked, setDisliked] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const handleLike = () => {
		const newLikedStatus = !liked;
		setLiked(newLikedStatus);
		if (disliked) setDisliked(false);

		// updateComment(updatedComment);
	};

	const handleDislike = () => {
		const newDislikedStatus = !disliked;
		setDisliked(newDislikedStatus);
		if (liked) setLiked(false);

		// updateComment(updatedComment);
	};

	const handleReply = () => {
		setShowReplyBox(!showReplyBox);
		setIsEditing(false);
		replyInputRef.current?.focus();
	};

	const handleEnterReply = () => {
		if (replyText.trim() !== "") {
			const newComment = {
				commentsId: "some-id",
				commenterId: user.id,
				thumb: "",
				completed: false,
				completerId: "",
				annotation: "",
				frame: 0,
				timestampMs: 0,
				text: replyText,
				likeCount: 0,
				updatedOn: new Date(),
				createdOn: new Date(),
				isDeleted: false,
				isLiked: liked,
				isDisliked: disliked,
				userName: `${user.firstName} ${user.lastName}`,
				timestamp: "Just now",
				time: "0",
				replies: [],
				parentId: props.props.id,
			};

			if (props.parentComment) {
				addComment(newComment);
			}

			setReplyText("");
			setShowReplyBox(false);
		}
	};

	const handleChange = (event: any) => {
		setReplyText(event.target.value);
	};

	const handleChangeTime = () => {
		setIsPlaying(true);

		setTimeout(() => {
			setCurrentTime(props?.props?.timestampMs);
			setIsPlaying(false);
		}, 100);
	};

	const handleDelete = () => {
		const newComment = {
			commentsId: "some-id",
			commenterId: user.id,
			thumb: "",
			completed: false,
			completerId: "",
			annotation: "",
			frame: 0,
			timestampMs: 0,
			id: props?.props?.id,
			text: replyText,
			likeCount: 0,
			updatedOn: new Date(),
			createdOn: new Date(),
			isDeleted: true,
			deleted: true,
			isLiked: liked,
			isDisliked: disliked,
			userName: `${user.firstName} ${user.lastName}`,
			timestamp: "Just now",
			time: "0",
			parentId: props.props.id,
		};
		deleteComment(newComment);
	};

	const handleSaveEdit = () => {
		setShowReplyBox(false);
		const updatedComment = {
			commentsId: "some-id",
			commenterId: user.id,
			thumb: "",
			completed: false,
			completerId: "",
			annotation: "",
			frame: 0,
			timestampMs: 0,
			id: props?.props?.id,
			text: currentText,
			likeCount: 0,
			updatedOn: new Date(),
			createdOn: new Date(),
			isDeleted: false,
			isLiked: liked,
			isDisliked: disliked,
			userName: `${user.firstName} ${user.lastName}`,
			timestamp: "Just now",
			time: "0",
			parentId: "null",
		};
		updateComment(updatedComment);
		setIsEditing(false);
	};

	const formatTime = (timestamp: any) => {
		const date = new Date(timestamp);
		let hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours = hours % 12;
		hours = hours ? hours : 12;
		const strTime = hours + ":" + (minutes < 10 ? "0" + minutes : minutes) + " " + ampm;
		return strTime;
	};

	useEffect(() => {
		if (isEditing && editInputRef.current) {
			editInputRef.current.focus();
		}
	}, [isEditing]);

	useEffect(() => {
		const filteredReplies = comments.filter((comment: any) => comment.parentId === props.props.id);
		setReplies(filteredReplies);
	}, [comments, props.props.id]);

	useEffect(() => {
		setCurrentText(props?.props?.text);
	}, [props?.props?.text]);

	return (
		<Box sx={styles.commentContainer}>
			<Box sx={styles.header}>
				<div className='user'>
					<div className='commentsAvatar'>
						{user.firstName[0]}
						{user.lastName[0]}
						<span className='stat green'></span>
					</div>
				</div>
				<Box sx={styles.userInfo}>
					<Typography sx={styles.userName}>
						{user.firstName} {user.lastName}
						<div className='flexBoxDiv'>
							<span style={styles.timestamp}>{formatTime(props?.props?.updatedOn)}</span>
							<div className='animated-border-box' onClick={handleChangeTime} style={styles.maindiv}>
								<i onClick={handleChangeTime} className='material-symbols-sharp'>
									timer
								</i>
								<div onClick={handleChangeTime}>{fancyTimeFormat(props?.props?.timestampMs)}</div>
								<div className='animated-border-box-glow'></div>
							</div>
						</div>
					</Typography>
				</Box>
			</Box>
			<Typography sx={styles.commentText}>
				{isEditing ? (
					<>
						<TextareaAutosize
							ref={editInputRef}
							minRows={1}
							value={currentText}
							onChange={(e: any) => {
								setCurrentText(e.target.value);
							}}
							onKeyDown={(e) => {
								if (e.key === "Enter" && !e.shiftKey) {
									e.preventDefault();
									handleSaveEdit();
								}
							}}
							placeholder='Comment ...'
							className='s-form-v1__input g-width-100-percent--xs  g-padding-y-4--xs commenttextAreaEdit '
						/>
						<Button onClick={handleSaveEdit} icon='check' size='small' color='primary' variant='contained' />
					</>
				) : (
					<TextareaAutosize
						minRows={1}
						value={currentText}
						placeholder='Comment ...'
						className='s-form-v1__input g-width-100-percent--xs  g-padding-y-4--xs commentTextArea'
					/>
				)}
			</Typography>
			<Box sx={styles.actions}>
				<Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}>
					<Link href='#' sx={styles.replyLink} onClick={handleReply}>
						Reply
					</Link>

					<div
						onClick={handleLike}
						id='thump_up_btn'
						style={{ color: liked ? "#3b82f6" : "#888", cursor: "pointer" }}
						className={`material-symbols-sharp ${liked ? "icon-sharp-fill" : ""}`}>
						thumb_up
					</div>
					<div
						id='thump_up_btn'
						onClick={handleDislike}
						style={{ color: disliked ? "#3b82f6" : "#888", cursor: "pointer" }}
						className={`material-symbols-sharp ${disliked ? "icon-sharp-fill" : ""}`}>
						thumb_down
					</div>
				</Box>
				<Box className='flexBoxDiv'>
					{/* <span className='material-symbols-sharp'>add_reaction</span> */}

					<div
						onClick={() => {
							setIsEditing(!isEditing);
							setShowReplyBox(false);
						}}
						style={{ color: isEditing ? "#3b82f6" : "#888", cursor: "pointer" }}
						className='material-symbols-sharp'>
						edit
					</div>
					<div onClick={handleDelete} style={{ cursor: "pointer" }} className='material-symbols-sharp'>
						delete_forever
					</div>
				</Box>
			</Box>
			{showReplyBox && (
				<Box sx={{ paddingLeft: "1rem" }}>
					<TextareaAutosize
						minRows={1}
						className='commentTextAreaReply'
						ref={replyInputRef}
						value={replyText}
						placeholder='Your reply...'
						onChange={handleChange}
						onKeyDown={(e) => {
							if (e.key === "Enter" && !e.shiftKey) {
								e.preventDefault();
								handleEnterReply();
							}
						}}
					/>
					<Button onClick={handleEnterReply} size='small' text='Enter' color='primary' variant='contained' />
				</Box>
			)}
			{replies?.map((reply: any, index: number) => (
				<ChildComment props={reply} parrentComent={false} parentId={props?.props?.id} />
			))}
		</Box>
	);
};

const styles = {
	commentContainer: {
		border: "1px solid #ccc",
		borderRadius: "8px",
		padding: "8px",
		maxWidth: "600px",
		margin: "16px auto",
		backgroundColor: "white",
		color: "#333",
	},
	header: {
		display: "flex",
		alignItems: "center",
		marginBottom: "8px",
	},
	avatar: {
		width: "40px",
		height: "40px",
		marginRight: "8px",
	},
	userInfo: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		justifyContent: "space-between",
	},
	userName: {
		fontWeight: "bold",
		fontSize: "14px",
		color: "#333",
		display: "flex",
		alignItems: "center",
		width: "100%",
		justifyContent: "space-between",
	},
	timestamp: {
		fontWeight: "normal",
		fontSize: "12px",
		color: "#888",
		marginLeft: "8px",
	},
	commentText: {
		fontSize: "14px",
		marginBottom: "8px",
		display: "flex",
		fontFamily: "Arial, sans-serif",
	},
	timestampText: {
		color: "#3b82f6", // blue color for timestamp
	},
	actions: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	actionButton: {
		color: "#888",
		fontSize: "6px",
	},
	replyLink: {
		color: "#3b82f6", // blue color for reply link
		textDecoration: "none",
		fontSize: "14px",
		paddingTop: "2px",
		paddingLeft: "4px",
	},
	readStatus: {
		fontSize: "14px",
		color: "#888",
	},
	maindiv: {
		display: "flex",
		"jusity-content": "flex-end",
		"align-items": "center",
		backgroundColor: "#fffafa",
		color: "black",
		paddingLeft: "0.3rem",
		paddingRight: "0.3rem",
		paddingTop: "0.2rem",
		paddingBottom: "0.2rem",
		columnGap: "0.3rem",
		borderRadius: "0.5rem",
		fontSize: "0.7rem",
		width: "fit-content",
		height: "fit-content",
		cursor: "pointer",
	},
};

export default Comment;
