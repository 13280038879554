// BasePathContext.tsx
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Define the shape of the context
interface BasePathContextType {
	basePath: string;
	setBasePath: (path: string) => void;
	searchParams: URLSearchParams;
	setSearchParams: (params: URLSearchParams) => void;
}

// Create the context with an undefined default
const BasePathContext = createContext<BasePathContextType | undefined>(undefined);

// Provider component
export const BasePathProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const rawbasePath: string = searchParams.get("base") ?? "";
	const [basePath, setBasePath] = useState<string>(rawbasePath !== "" && rawbasePath.slice(-1) === "/" ? rawbasePath : `${rawbasePath}/`);

	// Effect to update basePath when searchParams change
	useEffect(() => {
		setBasePath(rawbasePath !== "" && rawbasePath.slice(-1) === "/" ? rawbasePath : `${rawbasePath}/`);
	}, [searchParams]);

	return <BasePathContext.Provider value={{ basePath, setBasePath, searchParams, setSearchParams }}>{children}</BasePathContext.Provider>;
};

// Custom hook to use the context
export const useBasePath = (): BasePathContextType => {
	const context = useContext(BasePathContext);
	if (!context) {
		throw new Error("useBasePath must be used within a BasePathProvider");
	}
	return context;
};
