import { Box, Link, TextareaAutosize, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Button from "../../atoms/Button";
import { useAuth } from "../../AuthContext";
import { useComments } from "../../CommentsContext";
import "./../../../styling/ChildComment.css";

const ChildComment = (props: any) => {
	const { user } = useAuth();
	const { addComment, updateComment, deleteComment } = useComments();
	const [showReplyBox, setShowReplyBox] = useState(false);
	const [replyText, setReplyText] = useState("");
	const replyInputRef = useRef<HTMLTextAreaElement>(null);
	const editInputRef = useRef<HTMLTextAreaElement>(null);
	const [isEditing, setIsEditing] = useState(false);
	const [currentText, setCurrentText] = useState(props?.props?.text);
	const [liked, setLiked] = useState(props?.props?.isLiked || false);
	const [disliked, setDisliked] = useState(props?.props?.isDisliked || false);

	const handleLike = () => {
		const newLikedStatus = !liked;
		setLiked(newLikedStatus);
		if (disliked) setDisliked(false);
		const updatedComment = {
			...props.props,
			isLiked: newLikedStatus,
			isDisliked: disliked,
		};
		// updateComment(updatedComment);
	};

	const handleDislike = () => {
		const newDislikedStatus = !disliked;
		setDisliked(newDislikedStatus);
		if (liked) setLiked(false);
		const updatedComment = {
			...props.props,
			isLiked: liked,
			isDisliked: newDislikedStatus,
		};
		// updateComment(updatedComment);
	};

	setTimeout(() => {
		replyInputRef.current?.focus();
	}, 0);

	const handleReply = () => {
		setShowReplyBox(!showReplyBox);
	};

	const handleEnterReply = () => {
		if (replyText.trim() !== "") {
			const newComment = {
				commentsId: "some-id",
				commenterId: user.id,
				thumb: "",
				completed: false,
				completerId: "",
				annotation: "",
				frame: 0,
				timestampMs: 8,
				text: replyText,
				likeCount: 0,
				updatedOn: new Date(),
				createdOn: new Date(),
				isDeleted: false,
				userName: `${user.firstName} ${user.lastName}`,
				timestamp: "Just now",
				time: "0",
				parentId: props?.parentId,
				replies: [],
			};

			addComment(newComment);

			setReplyText("");
			setShowReplyBox(false);
		}
	};

	const handleChange = (event: any) => {
		setReplyText(event.target.value);
	};
	const handleDelete = () => {
		const newComment = {
			commentsId: "some-id",
			commenterId: user.id,
			thumb: "",
			completed: false,
			completerId: "",
			annotation: "",
			frame: 0,
			timestampMs: 0,
			id: props?.props?.id,
			text: replyText,
			likeCount: 0,
			updatedOn: new Date(),
			createdOn: new Date(),
			isDeleted: true,
			deleted: true,
			userName: `${user.firstName} ${user.lastName}`,
			timestamp: "Just now",
			time: "0",
			parentId: props.props.id,
		};
		deleteComment(newComment);

		// setComments((prevComments: any) => prevComments.filter((comment: any) => comment.id !== props?.props?.id));
	};

	const handleSaveEdit = () => {
		setShowReplyBox(false);
		const updatedComment = {
			commentsId: "some-id",
			commenterId: user.id,
			thumb: "",
			completed: false,
			completerId: "",
			annotation: "",
			frame: 0,
			timestampMs: 0,
			id: props?.props?.id,
			text: currentText,
			likeCount: 0,
			updatedOn: new Date(),
			createdOn: new Date(),
			isDeleted: false,
			userName: `${user.firstName} ${user.lastName}`,
			timestamp: "Just now",
			time: "0",
			parentId: props?.parentId,
		};
		updateComment(updatedComment);
		setIsEditing(false);
	};

	const formatTime = (timestamp: any) => {
		const date = new Date(timestamp);
		let hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours = hours % 12;
		hours = hours ? hours : 12;
		const strTime = hours + ":" + (minutes < 10 ? "0" + minutes : minutes) + " " + ampm;
		return strTime;
	};

	useEffect(() => {
		if (isEditing && editInputRef.current) {
			editInputRef.current.focus();
		}
	}, [isEditing]);

	useEffect(() => {
		setCurrentText(props?.props?.text);
	}, [props?.props?.text]);

	return (
		<Box sx={styles.commentContainer}>
			<Box sx={styles.header}>
				<div>
					<div className='childavatar'>
						{user.firstName[0]}
						{user.lastName[0]}
					</div>
				</div>
				<Box sx={styles.userInfo}>
					<Typography sx={styles.userName}>
						{user.firstName} {user.lastName} <span style={styles.timestamp}>{formatTime(props?.props?.updatedOn)}</span>
						{/* <div style={styles.maindiv}>
              <i className="material-symbols-sharp">timer</i>
              <div>{"00:00"}</div>
            </div> */}
					</Typography>
				</Box>
			</Box>
			<Typography sx={styles.commentText}>
				{isEditing ? (
					<>
						<TextareaAutosize
							ref={editInputRef}
							minRows={1}
							value={currentText}
							onChange={(e: any) => {
								setCurrentText(e.target.value);
							}}
							placeholder='Comment ...'
							className='s-form-v1__input g-width-100-percent--xs  g-padding-y-4--xs textAreaEditClass'
							onKeyDown={(e) => {
								if (e.key === "Enter" && !e.shiftKey) {
									e.preventDefault();
									handleSaveEdit();
								}
							}}
						/>
						<Button onClick={handleSaveEdit} icon='check' size='small' color='primary' variant='contained' />
					</>
				) : (
					<TextareaAutosize
						minRows={1}
						value={currentText}
						placeholder='Comment ...'
						className='s-form-v1__input g-width-100-percent--xs  g-padding-y-4--xs textAreaComment'
					/>
				)}
			</Typography>
			<Box sx={styles.actions}>
				<Box className='flexBoxDiv'>
					<Link href='#' sx={styles.replyLink} onClick={handleReply}>
						Reply
					</Link>
					<div
						onClick={handleLike}
						id='thump_up_btn'
						style={{ color: liked ? "#3b82f6" : "#888", cursor: "pointer", fontSize: "14px" }}
						className={`material-symbols-sharp ${liked ? "icon-sharp-fill" : ""}`}>
						thumb_up
					</div>
					<div
						id='thump_up_btn'
						onClick={handleDislike}
						style={{ color: disliked ? "#3b82f6" : "#888", cursor: "pointer", fontSize: "14px" }}
						className={`material-symbols-sharp ${disliked ? "icon-sharp-fill" : ""}`}>
						thumb_down
					</div>
				</Box>
				<Box className='flexBoxDiv'>
					<div
						onClick={() => {
							setIsEditing(!isEditing);
							setShowReplyBox(false);
						}}
						style={{ color: isEditing ? "#3b82f6" : "#888", cursor: "pointer", fontSize: "14px" }}
						className='material-symbols-sharp'>
						edit
					</div>

					<div onClick={handleDelete} style={{ cursor: "pointer", fontSize: "14px" }} className='material-symbols-sharp'>
						delete_forever
					</div>
				</Box>
			</Box>
			{showReplyBox && (
				<Box sx={{ paddingLeft: "1rem" }}>
					<TextareaAutosize
						minRows={1}
						className='textAreaReply'
						value={replyText}
						placeholder='Your reply...'
						onChange={handleChange}
						ref={replyInputRef}
						onKeyDown={(e) => {
							if (e.key === "Enter" && !e.shiftKey) {
								e.preventDefault();
								handleEnterReply();
							}
						}}
					/>
					<Button onClick={handleEnterReply} size='small' text='Enter' color='primary' variant='contained' />
				</Box>
			)}
		</Box>
	);
};

const styles = {
	commentContainer: {
		border: "1px solid #ccc",
		borderRadius: "8px",
		padding: "8px",
		maxWidth: "600px",
		margin: "16px auto",
		backgroundColor: "white",
		color: "#333",
		marginLeft: "1rem",
	},
	header: {
		display: "flex",
		alignItems: "center",
		marginBottom: "8px",
	},
	avatar: {
		width: "40px",
		height: "40px",
		marginRight: "8px",
	},
	userInfo: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		justifyContent: "space-between",
	},
	userName: {
		fontWeight: "bold",
		fontSize: "11px",
		color: "#333",
		display: "flex",
		alignItems: "center",
		width: "100%",
		justifyContent: "space-between",
	},
	timestamp: {
		fontWeight: "normal",
		fontSize: "11px",
		color: "#888",
		marginLeft: "8px",
	},
	commentText: {
		fontSize: "11px",
		marginBottom: "8px",
		display: "flex",
	},
	timestampText: {
		color: "#3b82f6", // blue color for timestamp
	},
	actions: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	actionButton: {
		color: "#888",
		fontSize: "2px",
	},
	replyLink: {
		color: "#3b82f6", // blue color for reply link
		textDecoration: "none",
		fontSize: "11px",
		paddingTop: "2px",
		paddingLeft: "4px",
	},
	readStatus: {
		fontSize: "11px",
		color: "#888",
	},
	maindiv: {
		display: "flex",
		"jusity-content": "flex-end",
		"align-items": "center",
		backgroundColor: "#F2F3F3",
		paddingLeft: "0.3rem",
		paddingRight: "0.3rem",
		paddingTop: "0.2rem",
		paddingBottom: "0.2rem",
		columnGap: "0.3rem",
		borderRadius: "0.5rem",
		fontSize: "0.7rem",
		width: "fit-content",
		height: "fit-content",
	},
};

export default ChildComment;
