import { Link } from "react-router-dom";

interface FolderComponentProps {
	name: string;
	currentBase: string;
}

const Folder = (props: FolderComponentProps) => {
	return (
		<Link to={`?base=${encodeURIComponent(props.currentBase)}${encodeURIComponent(props.name)}`}>
			<div className='g-padding-x-0--xs g-padding-y-0--xs g-bg-color--gray-light g-radius--10' style={{ border: "0px solid transparent" }}>
				<div
					style={{ backgroundImage: "url(/img/thumbnails/folder.png)", backgroundSize: "cover", backgroundPosition: "center", position: "relative" }}
					className='g-width-100-percent--xs g-height-180--xs g-content-center-y--xs g-radius--10'></div>
				<div className='g-width-100-percent--xs g-padding-x-10--xs g-padding-y-5--xs'>
					<div className='g-width-100-percent--xs g-content-center-y--xs g-margin-t-0--xs g-font-weight-600--xs'>
						<i className='material-symbols-sharp g-margin-r-5--xs' style={{ fontSize: 14, fontWeight: 600 }}>
							folder
						</i>
						<div
							style={{
								flexGrow: 10,
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}>
							<p
								style={{
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap",
									margin: 0,
									fontSize: 13,
									fontWeight: 600,
								}}>
								{props.name}
							</p>
						</div>
					</div>
					<div>Folder</div>
				</div>
			</div>
		</Link>
	);
};

export default Folder;
