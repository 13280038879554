import { Idea } from "../models/Interface";
import Api from "./Api";

export namespace IdeaWebService {
	export const fetchAllIdeas = (workspaceId: string) => {
		return new Promise((resolve, reject) => {
			Api.get(`/workspace/${workspaceId}/idea`)
				.then((response) => {
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data as Array<Idea>;
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const fetchIdeaById = (ideaId: string) => {
		return new Promise((resolve, reject) => {
			Api.get(`idea/${ideaId}`)
				.then((response) => {
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data;
						if (data.length > 0) resolve(data[0] as Idea);
						else resolve(null);
					} else {
						reject(response);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const saveAndUpdateIdea = (workspaceId: string, body: any) => {
		return new Promise((resolve, reject) => {
			Api.post(`workspace/${workspaceId}/idea`, body)
				.then((response) => {
					let responseOK = response && [200, 201].includes(response.status);
					if (responseOK) {
						const data = response.data;
						resolve(data);
					} else {
						reject(response);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const deleteIdea = (ideaId: string) => {
		return new Promise((resolve, reject) => {
			Api.delete(`/idea/${ideaId}`)
				.then((response) => {
					console.log(response);
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data;
						resolve(data);
					} else {
						reject(response);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
}
