import { Navigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

function Protected({ children, ...rest }: any) {
	const { isLoggedIn } = useAuth();

	if (!isLoggedIn()) {
		return <Navigate to='/login' />;
	} else {
		return children;
	}
}

export default Protected;
