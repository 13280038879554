import { BlobServiceClient } from "@azure/storage-blob";
import { BlobFile } from "../models/Interface";
import { currentDate } from "../utils/DateHelpers";
import { AuthenticationWebService } from "./AuthenticationWebService";

export namespace BlobStorageService {
	const containerName = "upload";
	const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;
	const storageAccountURL = process.env.REACT_APP_STORAGE_ACCOUNT_URL;
	let storageSASToken = localStorage.getItem("sasToken") ?? "";
	let uploadStatus: { [k: string]: any } = {};
	// Define chunk size (e.g., 1MB)
	const chunkSizeInBytes = 4 * 1024 * 1024; // 4 MB

	const getSASToken = async () => {
		if (!isSASTokenValid(storageSASToken)) {
			storageSASToken = (await AuthenticationWebService.getSASToken()) as string;
			localStorage.setItem("sasToken", storageSASToken);
		}
		return storageSASToken;
	};

	const isSASTokenValid = (token: string) => {
		if (token.length == 0) return false;
		const split = token.split("&").find((x) => x.includes("se="));
		if (split && split.length > 0) {
			const endDate = decodeURIComponent(split.split("se=")[1]);
			return Date.parse(endDate) > Date.now();
		}
		return false;
	};

	const getBlobServiceClient = async () => {
		const sasToken = await getSASToken();
		return new BlobServiceClient(`${storageAccountURL}?${sasToken}`);
	};

	export const uploadFile = async (newFile: BlobFile) => {
		return new Promise(async (resolve, reject) => {
			let chunksUploaded = 0;
			const blobName = newFile.name;
			const id: string = newFile.name as string;
			const blobServiceClient = await getBlobServiceClient();
			const containerClient = blobServiceClient.getContainerClient(containerName);
			const blockBlobClient = containerClient.getBlockBlobClient(blobName);
			const fileSize = newFile.file?.size ?? 1;
			const currenTime = Date.now();
			const options = {
				onProgress: (ev: any) => {
					console.log(`Time Spent:${(Date.now() - currenTime) / 1000}s`);
				},
			};

			// Calculate total number of chunks
			const totalChunks = Math.ceil(fileSize / chunkSizeInBytes);
			// Array to store block IDs
			const blockIds = [];
			const chunks = [];

			// Upload file in chunks
			for (let i = 0; i < totalChunks; i++) {
				const start = i * chunkSizeInBytes;
				const end = Math.min(start + chunkSizeInBytes, fileSize);

				// Stage block
				const blockId = btoa(`block-${i.toString().padStart(6, "0")}`);

				chunks.push({ blockId, start, end });
				blockIds.push(blockId);
			}

			for await (const e of chunks) {
				await new Promise((resolve, reject) => {
					// console.log("Reading", e.blockId);
					const fileReader = new FileReader();
					fileReader.onload = async () => {
						const arrayBuffer = fileReader.result as ArrayBuffer;
						const chunkSize = e.end - e.start;
						if (arrayBuffer && uploadStatus[id] !== "abort") {
							// const totalSize = arrayBuffer?.byteLength;
							const chunkBuffer = arrayBuffer;
							await blockBlobClient.stageBlock(e.blockId, chunkBuffer, chunkSize, options);
							chunksUploaded++;
							const bytesUploaded = Math.min(chunkSizeInBytes * chunksUploaded, fileSize);
							newFile.status = ((bytesUploaded / fileSize) * 100).toFixed();
							if (bytesUploaded === fileSize) newFile.status = "100";
							if (uploadStatus[id] !== "abort") {
								uploadStatus[id] = newFile.status ?? "0";
							}
							resolve(e.blockId);
						}
					};
					// Read the file as ArrayBuffer
					fileReader.readAsArrayBuffer(newFile?.file?.slice(e.start, e.end) as Blob);
				});
			}
			// Commit blocks
			if (uploadStatus[id] !== "abort") {
				await blockBlobClient.commitBlockList(blockIds as string[]);
				uploadStatus[id] = "100";
			}

			resolve(blobName);
		});
	};

	export const getProgress = (id: string) => {
		return uploadStatus[id];
	};

	export const getProgressAll = () => {
		return uploadStatus;
	};

	export const clearProgress = (id: string) => {
		delete uploadStatus[id];
	};

	export const clearProgressAll = () => {
		uploadStatus = {};
	};

	export const abortUpload = (id: string) => {
		uploadStatus[id] = "abort";
	};

	export const getBlobUrl = (blob: any) => {
		return `${storageAccountURL}/${containerName}/${encodeURIComponent(blob.name)}?${storageSASToken}&versionId=${blob.versionId}`;
	};

	export const getBlobsListAtPath = async (path: string) => {
		const blobServiceClient = await getBlobServiceClient();
		const containerClient = blobServiceClient.getContainerClient(containerName);
		// console.log(containerClient.url);
		// some options for filtering list
		const listOptions = {
			includeMetadata: false,
			includeSnapshots: false,
			includeTags: false,
			includeVersions: false,
			includeDeletedWithVersions: false,
			prefix: path,
		};
		const iterator = await containerClient.listBlobsFlat(listOptions).byPage({ maxPageSize: 100 });
		let response = (await iterator.next()).value;
		return azureResponseToBlobFiles(response.segment.blobItems);
	};

	export const getBlobVersions = async (blobName: string) => {
		const blobServiceClient = await getBlobServiceClient();
		const containerClient = blobServiceClient.getContainerClient(containerName);
		// some options for filtering list
		const listOptions = {
			includeMetadata: false,
			includeSnapshots: false,
			includeTags: false,
			includeVersions: true,
			includeDeletedWithVersions: false,
			prefix: blobName,
		};
		const iterator = await containerClient.listBlobsFlat(listOptions).byPage({ maxPageSize: 100 });
		let response = (await iterator.next()).value;
		return azureResponseToBlobFiles(response.segment.blobItems);
	};

	const azureResponseToBlobFiles = (res: any): Array<BlobFile> => {
		const blobFiles = res.map((blob: any) => {
			const name = blob.name.split("/")[2];
			const id = name.split(".")[0];
			const filePath = getBlobUrl(blob);
			const blobFile = {
				id: id,
				name: name,
				path: filePath,
				versionId: blob.versionId,
				isCurrentVersion: blob.isCurrentVersion,
			};
			return blobFile;
		});
		return blobFiles;
	};

	const getFileExtension = (fname: string) => {
		return fname.slice((Math.max(0, fname.lastIndexOf(".")) || Infinity) + 1);
	};

	export const fileToBlobFile = async (
		file: File,
		userId: string,
		workspaceId: string,
		taskId: string,
		fileId: string,
		fileName: string | undefined | null = null,
		fileExtention: string | undefined | null = null
	) => {
		const orignalName = fileName ?? file.name;
		if (!fileExtention) {
			fileExtention = getFileExtension(orignalName);
		}
		let newFileName = `${workspaceId}/${taskId}/${fileId}`;
		if (fileExtention && fileExtention.length) {
			newFileName = `${newFileName}.${fileExtention}`;
		}
		let duration;
		if (file.type.includes("video")) {
			const video: any = await loadVideo(file);
			duration = video?.duration;
		}

		const newFile: BlobFile = {
			name: newFileName,
			originalName: orignalName,
			status: "0",
			fileSize: file.size?.toString(),
			fileType: file.type,
			duration: duration,
			isUploading: true,
			isCurrentVersion: true,
			approved: false,
			file: file,
			taskId: taskId,
			uploadedBy: userId,
			uploadDate: currentDate(),
		};
		return newFile;
	};

	const loadVideo = async (file: File) =>
		new Promise((resolve, reject) => {
			try {
				let video = document.createElement("video");
				video.preload = "metadata";

				video.onloadedmetadata = function () {
					resolve(this);
				};

				video.onerror = function () {
					reject("Invalid video. Please select a video file.");
				};

				video.src = window.URL.createObjectURL(file);
			} catch (e) {
				reject(e);
			}
		});

	export const setBlobContentDispositionHeader = async (blobName: string, fileName: string) => {
		const blobServiceClient = await getBlobServiceClient();
		const containerClient = blobServiceClient.getContainerClient(containerName);
		const blobClient = containerClient.getBlobClient(blobName);
		await blobClient.setHTTPHeaders({
			blobContentDisposition: `attachment; filename="${fileName}"`,
		});
	};
}
