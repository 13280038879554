import { useContext } from "react";
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from "reactflow";
import Button from "../../atoms/Button";
import { WorkflowContext } from "../../templates/dashboard/WorkflowEditor";

export default function ButtonEdge({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd, data }: EdgeProps) {
	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});

	const { handleActivityDrawer }: any = useContext(WorkflowContext);
	const onEdgeClick = (event: any, id: any) => {
		event.stopPropagation();
		handleActivityDrawer(id);
	};
	return (
		<>
			<BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
			<EdgeLabelRenderer>
				<div
					className='addbuttonedge'
					style={{
						position: "absolute",
						transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
						fontSize: 12,
						height: 50,
						width: 50,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						pointerEvents: "all",
					}}>
					<Button
						disabled={data.disabled ?? false}
						onClick={(event: any) => onEdgeClick(event, id)}
						className='g-text-align--center g-padding-x-0--xs g-padding-y-0--xs g-radius--50 addbuttonedge-button'
						variant='contained'
						icon={"add"}></Button>
				</div>
			</EdgeLabelRenderer>
		</>
	);
}
