import { Grid, Skeleton } from "@mui/material";

const TaskSkeleton = () => {
	return (
		<div className='g-height-100-percent--xs '>
			<div className='g-margin-t-10--xs g-hor-divider__solid--gray'>
				<Grid container>
					<Grid item xs={9}>
						<Skeleton variant='text' className='g-margin-l-10--xs' width={"500px"} sx={{ fontSize: "1.6rem" }} />
					</Grid>
					<Grid item xs={3}>
						<Skeleton variant='text' className='g-margin-l-10--xs' width={"250px"} sx={{ fontSize: "1.6rem" }} />
					</Grid>
				</Grid>
			</div>
			<div className='g-margin-t-20--xs g-width-100-percent--xs g-padding-x-40--xs'>
				<Skeleton variant='rectangular' width={"100%"} height={50} />
			</div>

			<div className='g-margin-t-40--xs g-height-100-percent--xs g-width-100-percent--xs g-padding-x-40--xs'>
				<Grid container>
					<Grid item xs={3}>
						<Skeleton variant='rectangular' width={250} height={200} />
					</Grid>
					<Grid item xs={3}>
						<Skeleton variant='rectangular' width={250} height={200} />
					</Grid>
					<Grid item xs={3}>
						<Skeleton variant='rectangular' width={250} height={200} />
					</Grid>
					<Grid item xs={3}>
						<Skeleton variant='rectangular' width={250} height={200} />
					</Grid>
				</Grid>
			</div>
		</div>
	);
};
export default TaskSkeleton;
