import ELK from "elkjs/lib/elk.bundled.js";
import { Edge, MarkerType, Node } from "reactflow";
import NodeTypes from "../constants/NodeTypes";
import { TaskTemplate } from "../models/Interface";
import { StorageService } from "./StorageService";

const nodeWidth = 600;
const nodeXInitial = (isSidebarOpen: boolean) => (window.innerWidth - (isSidebarOpen ? 240 : 60) - nodeWidth) / 2;
const elk = new ELK();

const elkOptions = {
	"elk.direction": "DOWN",
	"elk.algorithm": "mrtree",
	"elk.layered.spacing.nodeNodeBetweenLayers": "300",
	"elk.spacing.nodeNode": "80",
};

const newId = (): string => {
	return crypto.randomUUID().toString();
};

const newEdge = (source: string, target: string, type: string = "buttonEdge", data: any = {}): Edge => {
	return {
		id: newId(),
		source: source,
		target: target,
		type: type,
		style: { stroke: "#444", strokeWidth: "2px" },
		markerEnd: { type: MarkerType.ArrowClosed, width: 50, color: "#444" },
		data: data,
	};
};
const newNode = (nodeData: TaskTemplate, isSidebarOpen: boolean): Node => {
	return { id: newId(), type: nodeData.type, position: { x: nodeXInitial(isSidebarOpen), y: 40 }, data: nodeData };
};

const newActivityDrawerNode = (isSidebarOpen: boolean): Node => {
	return {
		id: newId(),
		type: NodeTypes.ActivityDrawerNode,
		position: { x: nodeXInitial(isSidebarOpen), y: 40 },
		data: {
			avatar: "apps",
			title: "Select Activity",
			description: "This is an workflow ideation activity that could use to define the idea of the video.",
			theme: "#e9ead4",
		},
	};
};

const deleteEdge = (edges: Array<any>, edgeId: string) => {
	for (let i = 0; i < edges.length; i++) {
		if (edges[i].id == edgeId) {
			edges.splice(i, 1);
			return edges;
		}
	}
	return edges;
};

export namespace WorkflowService {
	export const LayoutWorkflow = (workflow: any, isSidebarOpen = true) => {
		const nodes = workflow.nodes;
		const edges = workflow.edges;

		const isHorizontal = false;
		const graph = {
			id: "root",
			layoutOptions: elkOptions,
			children: nodes.map((node: any) => ({
				...node,
				// Adjust the target and source handle positions based on the layout
				// direction.
				targetPosition: isHorizontal ? "left" : "top",
				sourcePosition: isHorizontal ? "right" : "bottom",

				// Hardcode a width and height for elk to use when layouting.
				width: nodeWidth,
				height: node.type === NodeTypes.ActivityDrawerNode ? 380 : 300,
			})),
			edges: edges,
		};
		return elk
			.layout(graph)
			.then((layoutedGraph: any) => {
				workflow.nodes = layoutedGraph.children.map((node: any) => ({
					...node,
					// React Flow expects a position property on the node instead of `x`
					// and `y` fields.
					position: { x: nodeXInitial(isSidebarOpen), y: node.y },
				}));

				workflow.edges = layoutedGraph.edges;
				return workflow;
			})
			.catch(console.error);
	};

	export const AddActivityDrawerNode = (nodes: any, edges: any, edgeId: string | null = null, isSidebarOpen = true) => {
		return new Promise((resolve, reject) => {
			const currNodesLen = nodes.length;
			const currEdgesLen = edges.length;
			const node = newActivityDrawerNode(isSidebarOpen);
			let newNodes = nodes.concat(node);
			let newEdges = edges;
			if (currNodesLen > 0) {
				if (edgeId != null) {
					for (let i = 0; i < currEdgesLen; i++) {
						if (edges[i].id == edgeId) {
							let edge = edges[i];
							const target = edge.target;
							edge.target = node.id;
							edges[i] = edge;
							newEdges = edges.concat(newEdge(node.id, target));
							break;
						}
					}
				} else {
					newEdges = edges.concat(newEdge(nodes[currNodesLen - 1].id, node.id));
				}
			}
			return resolve({ newNodes, newEdges, addedNode: node });
		});
	};

	export const AddNodeFromActivityDrawer = (nodes: any, edges: any, activityDrawerId: string | undefined, nodeData: TaskTemplate, isSidebarOpen = true) => {
		return new Promise((resolve, reject) => {
			const currNodesLen = nodes.length;
			const currEdgesLen = edges.length;
			if (currNodesLen > 0 && activityDrawerId != undefined) {
				let newNodes = nodes;
				let newEdges = edges;
				const node = newNode(nodeData, isSidebarOpen);
				for (let i = 0; i < currNodesLen; i++) {
					const data = nodes[i].data;
					if (nodes[i].type == NodeTypes.ActivityDrawerNode) {
						const activityDrawerNode = nodes[i];
						newNodes[i] = node;
						for (let j = 0; j < currEdgesLen; j++) {
							if (edges[j].source === activityDrawerId) {
								newEdges[j].source = node.id;
							}
							if (edges[j].target === activityDrawerId) {
								newEdges[j].target = node.id;
							}
						}
						return resolve({ newNodes, newEdges, addedNode: node });
					}
				}
			}
			return reject();
		});
	};

	export const DeleteNode = (nodes: any, edges: any, nodeId: string) => {
		return new Promise((resolve, reject) => {
			let currNodesLen: number = nodes.length;
			let currEdgesLen: number = edges.length;
			try {
				if (currNodesLen > 0 && nodeId != undefined) {
					let newNodes = nodes;
					let newEdges = edges;
					let node;
					//Identifying the node to be deleted
					for (let i = 0; i < currNodesLen; i++) {
						node = nodes[i];
						if (node.id != nodeId) {
							// Remove the selected refrence of this node from the another nodes
							const references = nodes[i].data.references;
							const filtered = references.filter((ref: any) => !ref?.taskTitleRef?.includes(nodeId));
							nodes[i].data.references = filtered;
							newNodes = nodes;
						}

						if (node.id == nodeId) {
							nodes.splice(i, 1);
							newNodes = nodes;
							let edgeNodeAsSrc: any;
							let edgeNodeAsTgt: any;
							//Finding edges that has node as source and/or target
							for (let j = 0; j < currEdgesLen; j++) {
								if (edges[j].source == nodeId) {
									edgeNodeAsSrc = edges[j];
								}
								if (edges[j].target == nodeId) {
									edgeNodeAsTgt = edges[j];
								}
							}
							//if node is the first node in workflow
							if (edgeNodeAsSrc != undefined && edgeNodeAsTgt == null) {
								newEdges = deleteEdge(edges, edgeNodeAsSrc.id);
							}
							//if node is the last node in workflow
							else if (edgeNodeAsSrc == null && edgeNodeAsTgt != null) {
								newEdges = deleteEdge(edges, edgeNodeAsTgt.id);
							}
							//if node is any middle node in workflow
							else if (edgeNodeAsSrc != null && edgeNodeAsTgt != null) {
								newEdges = deleteEdge(edges, edgeNodeAsSrc.id);
								edgeNodeAsTgt.target = edgeNodeAsSrc.target;
								for (let j = 0; j < newEdges.length; j++) {
									if (newEdges[j].id === edgeNodeAsTgt.id) {
										newEdges[j] = edgeNodeAsTgt;
									}
								}
							} else {
								newEdges = edges;
							}
							i--;
							currNodesLen--;
						}
					}
					return resolve({ newNodes, newEdges, deletedNode: node });
				}
			} catch (e) {
				console.log(e);
				return reject();
			}
		});
	};

	export const UpdateNodeData = (nodes: any, edges: any, nodeId: string, data: any) => {
		return new Promise((resolve, reject) => {
			const currNodesLen = nodes.length;
			if (currNodesLen > 0 && nodeId !== undefined) {
				for (let i = 0; i < currNodesLen; i++) {
					if (nodes[i].id === nodeId) {
						console.log(nodes[i]);
						// nodes[i].data = data;
						const newNodes = nodes;
						const newEdges = edges;
						return resolve({ newNodes, newEdges });
					}
				}
			}
			reject();
		});
	};

	export const RestoreWorkFlow = async () => {
		const flow = JSON.parse(localStorage.getItem(StorageService.WORKFLOW_STORAGE_KEY) || "{}");
		return flow;
	};

	export const StoreWorkFlow = async (flow: any) => {
		StorageService.saveWorkflow(flow);
	};
}
