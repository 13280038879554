import Grid from "@mui/material/Grid";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { WorkspaceWebService } from "../../services/WorkspaceWebService";
import { email_validation, name_validation } from "../../utils/InputValidations";
import { useAuth } from "../AuthContext";
import { useLoading } from "../LoadingContext";
import { useNotification } from "../NotificationContext";
import Button from "../atoms/Button";
import InputV2 from "../atoms/InputV2";
const CreateWorkspaceForm = (props: any) => {
	const methods = useForm({ mode: "onBlur" });
	const [success, setSuccess] = useState(false);
	const [onInvitePage, setOnInvitePage] = useState(false);
	const navigate = useNavigate();
	const { showLoading, hideLoading } = useLoading();
	const { showSuccessMessage } = useNotification();
	const { isLoggedIn, createNewWorkspace } = useAuth();
	const onSubmit = methods.handleSubmit((data: any) => {
		const members: Array<string> = [];
		if (data["user1"] !== "") {
			members.push(data["user1"]);
		}
		if (data["user2"] !== "") {
			members.push(data["user2"]);
		}
		if (data["user3"] !== "") {
			members.push(data["user3"]);
		}
		if (data["user4"] !== "") {
			members.push(data["user4"]);
		}
		const payload = {
			name: data["workspace_name"],
			description: data["workspace_description"],
		};
		showLoading();
		WorkspaceWebService.createWorkspace(payload)
			.then((value: any) => {
				const workspace = createNewWorkspace(value);
				if (members.length > 0) {
					WorkspaceWebService.inviteUsersToWorkspace(workspace.id, members).then((value: any) => {
						navigate(`/${workspace.id}/tasks`);
					});
				} else navigate(`/${workspace.id}/tasks`);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => hideLoading());
	});

	return (
		<div>
			<h3 className='g-margin-b-40--xs '>
				<b>Create a workspace</b>
			</h3>
			<FormProvider {...methods}>
				<form onSubmit={(e) => e.preventDefault()} noValidate autoComplete='off'>
					{!onInvitePage && (
						<div>
							<Grid container columnSpacing={1}>
								{/* <Grid item xs={12}>
									<label htmlFor='orgIdInput'>Name your organization</label>
									<div id='orgIdInput' className='g-display-flex--xs g-width-100-percent--xs'>
										<div className='g-display-flex--xs orgid'>app.gobananas.live/</div>
										<div className='g-width-100-percent--xs'>
											<InputV2 className='g-hor-border-0--left g-radius--left-0' name='orgId' type='text' placeholder='amigos' validation={name_validation} />
										</div>
									</div>
								</Grid> */}
								<Grid item xs={12}>
									<InputV2 name='workspace_name' type='text' placeholder='Workspace Name' label='Workspace Name' validation={name_validation} />
								</Grid>
								<Grid item xs={12}>
									<InputV2 name='workspace_description' type='text' placeholder='Description' label='Description' validation={{}} />
								</Grid>
							</Grid>
							<Button
								disabled={!methods.formState.isValid}
								size='sm'
								className='g-margin-t-20--xs g-width-100-percent--xs'
								onClick={() => setOnInvitePage(true)}
								color='dark'
								variant={"contained"}
								text='Next'></Button>
						</div>
					)}
					{onInvitePage && (
						<div>
							<Grid container columnSpacing={1}>
								<Grid item xs={12}>
									<InputV2 name='user1' type='text' placeholder='Member Email 1' label='Invite members to your workspace' validation={email_validation} />
								</Grid>
								<Grid item xs={12}>
									<InputV2 name='user2' type='text' placeholder='Member Email 2' validation={email_validation} />
								</Grid>
								<Grid item xs={12}>
									<InputV2 name='user3' type='text' placeholder='Member Email 3' validation={email_validation} />
								</Grid>
								<Grid item xs={12}>
									<InputV2 name='user4' type='text' placeholder='Member Email 4' validation={email_validation} />
								</Grid>
							</Grid>
							<Button
								size='sm'
								className='g-margin-t-20--xs g-width-100-percent--xs'
								onClick={onSubmit}
								color='dark'
								variant={"contained"}
								text='Create Workspace'></Button>
							<div className='g-margin-t-20--xs'>
								<Button variant='text' text='Back' onClick={() => setOnInvitePage(false)} />
							</div>
						</div>
					)}
				</form>
			</FormProvider>
		</div>
	);
};
export default CreateWorkspaceForm;
