import { Navigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

function UnProtected({ children, ...rest }: any) {
	const { isLoggedIn } = useAuth();

	if (isLoggedIn()) {
		return <Navigate to='/' />;
	} else {
		return children;
	}
}

export default UnProtected;
