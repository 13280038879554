const NoData = () => {
	return (
		<div className='g-content-center-xy--xs g-height-600--xs g-width-100-percent--xs' style={{ flexDirection: "column" }}>
			<img src={"/svg/gobananas-nodata.svg"} width={"280"}></img>
			<p className='g-font-weight--400 g-letter-spacing--1'>No Data Available!</p>
		</div>
	);
};

export default NoData;
