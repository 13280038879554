import Api from "./Api";

export namespace WorkflowWebService {
	export const saveWorkflow = (workspaceId: string, body: any) => {
		return new Promise((resolve, reject) => {
			Api.post(`/workspace/${workspaceId}/workflow`, body)
				.then((response) => {
					let responseOK = response && response.status === 201;
					if (responseOK) {
						const data = response.data;
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const fetchWorkflowById = (workflowId: string) => {
		return new Promise((resolve, reject) => {
			Api.get(`/workflow/${workflowId}`)
				.then((response) => {
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data;
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const deleteWorkflow = (workflowId: string) => {
		return new Promise((resolve, reject) => {
			Api.delete(`/workflow/${workflowId}`)
				.then((response) => {
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data;
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
}
