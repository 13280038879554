// LoadingBar.tsx
import { LinearProgress } from "@mui/material";
import { useLoading } from "../LoadingContext";

function LoadingBar() {
	const { isLoading } = useLoading();

	if (!isLoading) {
		return <></>;
	}

	return (
		<div
			style={{
				position: "absolute",
				top: "0px",
				width: "100%",
				height: "5px",
				zIndex: 2001,
			}}>
			<LinearProgress color='secondary' />
		</div>
	);
}

export default LoadingBar;
