import { LinearProgress, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { TaskStatus } from "../../../constants/TaskStatus";
import { Task } from "../../../models/Interface";
import { WorkspaceWebService } from "../../../services/WorkspaceWebService";
import { useAuth } from "../../AuthContext";
import { useLoading } from "../../LoadingContext";
import { useNotification } from "../../NotificationContext";
import { CustomTabPanel, ariaControls } from "../../atoms/CustomTabs";
import StatusChipMenu from "../../atoms/StatusChipMenu";
import { EnhancedTable } from "../../atoms/Table";
import UserChip from "../../atoms/UserChip";
import NoData from "../../molecules/NoData";

const TasksListing = (props: any) => {
	const { currentWorkspace, user } = useAuth();
	const { showLoading, hideLoading, isLoading } = useLoading();
	const [localLoading, setLocalLoading] = useState<any>(true);
	const [rows, setRows] = useState<any>([]);
	const [rowsAssigned, setRowsAssigned] = useState<any>([]);
	const [rowsReview, setRowsReview] = useState<any>([]);
	const { showErrorMessage, showSuccessMessage } = useNotification();

	const [allRows, setAllRows] = useState<any>([]);

	function createData(row: Task) {
		return {
			data: {
				id: row.id,
				title: row.data?.title,
				workType: row.type,
				status: row.status ?? "TODO",
				workflow: row.workflow,
				idea: row.idea,
				reviewers: row.data?.reviewers,
				assignedTo: row.data?.assignedTo,
			},
		};
	}

	const handleTaskStateChange = async (state: any, id: string) => {
		const formattedState = state.toUpperCase().replace(/\s+/g, "");
		const finalState = formattedState === "NEEDSREVIEW" ? "NEEDS_REVIEW" : formattedState;
		const updatedRows = allRows.map((row: any) => {
			if (row.data.id === id) {
				return { ...row, data: { ...row.data, status: finalState } };
			}
			return row;
		});
		setAllRows(updatedRows);

		try {
			if (id) {
				await WorkspaceWebService.updateTaskStatus(id, finalState).then((res) => {
					showSuccessMessage("Task status updated successfully!");
				});
			}
		} catch (error) {
			console.error("Error updating task status:", error);
		}
	};

	const mapper = (rows: Array<Task>) => {
		return rows.map((el: any) => createData(el));
	};
	const header = [
		// {
		// 	id: "id",
		// 	label: "Id",
		// 	render: (item: any) => item.id,
		// },
		{
			id: "idea",
			label: "Idea",
			render: (item: any) => <Link to={`${item.id}`}>{item.idea?.name}</Link>,
		},
		{
			id: "title",
			label: "Work Type",
			render: (item: any) => (
				<div className='g-display-flex--xs' style={{ justifyContent: "space-between" }}>
					<Link to={`${item.id}`}>{item.title}</Link>
					<i className='material-symbols-sharp'>more_horiz</i>
				</div>
			),
		},
		{
			id: "workflowType",
			label: "Workflow",
			render: (item: any) => item.workflow?.name,
		},
		{
			id: "status",
			label: "Status",
			render: (item: any) => (
				<StatusChipMenu key={item.id} status={item.status} handleTaskStateChange={(newStatus: string) => handleTaskStateChange(newStatus, item.id)} />
			),
		},
		{
			id: "assignedTo",
			label: "Assigned To",
			render: (item: any) => <UserChip id={item.assignedTo} index={0} />,
		},
		{
			id: "reviewers",
			label: "Reviewers",
			render: (item: any) => {
				return item.reviewers.map((option: any, index: any) => <UserChip key={index} className='g-margin-r-5--xs' id={option} index={index} />);
			},
		},
	];

	const fetchAllTasks = async () => {
		if (currentWorkspace?.id) {
			showLoading();
			setLocalLoading(true);
			await WorkspaceWebService.fetchAllUserTasks(currentWorkspace?.id)
				.then(async (res: any) => {
					const allRows = mapper(res);
					setAllRows(allRows);
					const assignedRows = allRows.filter((r) => r.data.status !== TaskStatus[TaskStatus.COMPLETE] && r.data?.assignedTo === user.email);
					const upForReviewRows = allRows.filter(
						(r) => r.data.status === TaskStatus[TaskStatus.NEEDS_REVIEW] && r.data?.reviewers?.some((reviewer) => reviewer === user.email)
					);
					const allVisibleRows = allRows.filter(
						(r) =>
							r.data?.assignedTo === user.email ||
							(r.data.status === TaskStatus[TaskStatus.NEEDS_REVIEW] && r.data?.reviewers?.some((reviewer) => reviewer === user.email))
					);
					setRows(allVisibleRows);
					setRowsAssigned(assignedRows);
					setRowsReview(upForReviewRows);
				})
				.catch((err) => showErrorMessage(err.message))
				.finally(() => {
					hideLoading();
					setLocalLoading(false);
				});
		}
	};
	useEffect(() => {
		fetchAllTasks();
	}, [currentWorkspace]);
	const [value, setValue] = useState(0);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		const updateRows = () => {
			const assignedRows = allRows.filter((r: any) => r.data.status !== TaskStatus[TaskStatus.COMPLETE] && r.data?.assignedTo === user.email);
			const upForReviewRows = allRows.filter(
				(r: any) => r.data.status === TaskStatus[TaskStatus.NEEDS_REVIEW] && r.data?.reviewers?.some((reviewer: any) => reviewer === user.email)
			);
			const allVisibleRows = allRows.filter(
				(r: any) =>
					r.data?.assignedTo === user.email ||
					(r.data.status === TaskStatus[TaskStatus.NEEDS_REVIEW] && r.data?.reviewers?.some((reviewer: any) => reviewer === user.email))
			);
			setRows(allVisibleRows);
			setRowsAssigned(assignedRows);
			setRowsReview(upForReviewRows);
		};
		updateRows();
	}, [allRows]);

	return (
		<div className='g-padding-x-20--xs g-margin-t-30--xs'>
			<div className='g-display-flex--xs g-margin-b-20--xs' style={{ justifyContent: "space-between" }}>
				<h3>My Tasks</h3>
			</div>

			<div className='g-display-flex--xs' style={{ justifyContent: "space-between" }}>
				<div>
					<Tabs value={value} onChange={handleChange} variant='standard' scrollButtons='auto' aria-label='basic tabs example'>
						<Tab sx={{ px: 2, py: 0, textTransform: "none", fontSize: 15 }} label='Active Tasks' {...ariaControls(0)} />
						<Tab sx={{ px: 2, py: 0, textTransform: "none", fontSize: 15 }} label='Pending Review' {...ariaControls(1)} />
						<Tab sx={{ px: 2, py: 0, textTransform: "none", fontSize: 15 }} label='All Tasks' {...ariaControls(2)} />
					</Tabs>
				</div>
			</div>
			<CustomTabPanel value={value} index={0}>
				{localLoading && (
					<div className='g-content-center-xy--xs g-height-600--xs'>
						<LinearProgress className='g-margin-t-40--xs g-width-150--xs' color='secondary' />
					</div>
				)}
				{rowsAssigned && rowsAssigned.length > 0 && !localLoading && (
					<EnhancedTable rows={rowsAssigned} columns={header} paginationOptions={[100]}></EnhancedTable>
				)}
				{!isLoading && !localLoading && rowsAssigned.length == 0 && <NoData />}
			</CustomTabPanel>

			<CustomTabPanel value={value} index={1}>
				{rowsReview && rowsReview.length > 0 && !localLoading && <EnhancedTable rows={rowsReview} columns={header} paginationOptions={[100]}></EnhancedTable>}
				{!isLoading && !localLoading && rowsReview.length == 0 && <NoData />}
			</CustomTabPanel>
			<CustomTabPanel value={value} index={2}>
				{localLoading && (
					<div className='g-content-center-xy--xs g-height-600--xs'>
						<LinearProgress className='g-margin-t-40--xs g-width-150--xs' color='secondary' />
					</div>
				)}
				{rows && rows.length > 0 && !localLoading && <EnhancedTable rows={rows} columns={header} paginationOptions={[100]}></EnhancedTable>}
				{!isLoading && !localLoading && rows.length == 0 && <NoData />}
			</CustomTabPanel>
		</div>
	);
};

export default TasksListing;
