import APIPaths from "../constants/ApiPaths";
import { User } from "../models/Interface";
import Api from "./Api";

export namespace AuthenticationWebService {
	export const login = (body: any) => {
		return new Promise((resolve, reject) => {
			Api.post(APIPaths.LoginAPI, body)
				.then((response) => {
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data;
						const jwt = data["authToken"];
						const user = data["user"] as User;
						resolve({ jwt, user });
					} else {
						console.log(response);
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const signUp = (body: any) => {
		return new Promise((resolve, reject) => {
			Api.post(APIPaths.SignUpAPI, body)
				.then((response) => {
					let responseOK = response && response.status === 201;
					if (responseOK) {
						const data = response.data;
						resolve(data);
					} else {
						console.log(response);
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const getSASToken = () => {
		return new Promise((resolve, reject) => {
			Api.get(APIPaths.SASToken)
				.then((response) => {
					let responseOK = response && response.status === 201;
					if (responseOK) {
						const data = response.data;
						const sasToken = data["sasToken"];
						// const sasToken = "sp=racwdl&st=2024-02-10T05:02:39Z&se=2024-02-10T13:02:39Z&sv=2022-11-02&sr=c&sig=BmXtHazwI6C19JyIk7h50roiLUOPsQ0rlOmzB6HyrEk%3D";
						resolve(sasToken);
					} else {
						console.log(response);
						reject("");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
}
