// NotificationContext.tsx
import { AlertColor } from "@mui/material";
import React, { ReactNode, createContext, useContext } from "react";

interface NotificationContextType {
	message: string | null;
	severity: AlertColor;
	hasMessage: () => boolean;
	showMessage: (message: string, severity: AlertColor) => void;
	showErrorMessage: (message: string) => void;
	showSuccessMessage: (message: string) => void;
	clearMessage: () => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [message, setMessage] = React.useState<string | null>(null);
	const [severity, setSeverity] = React.useState<AlertColor>("info");

	const showMessage = (message: string, severity: AlertColor) => {
		setMessage(message);
		setSeverity(severity);
	};
	const showErrorMessage = (message: string) => {
		setMessage(message);
		setSeverity("error");
	};
	const showSuccessMessage = (message: string) => {
		setMessage(message);
		setSeverity("success");
	};
	const hasMessage = () => message != null || message != undefined;
	const clearMessage = () => {
		setMessage(null);
	};

	return (
		<NotificationContext.Provider value={{ message, severity, hasMessage, showMessage, showErrorMessage, showSuccessMessage, clearMessage }}>
			{children}
		</NotificationContext.Provider>
	);
};

export const useNotification = (): NotificationContextType => {
	const context = useContext(NotificationContext);
	if (context === undefined) {
		throw new Error("useNotification must be used within a NotificationProvider");
	}
	return context;
};
