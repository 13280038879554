import Grid from "@mui/material/Grid";
import Title from "../atoms/Title";

const NotFound = () => {
	Title(`GoBananas | Page Not Found`);
	return (
		<>
			<Grid
				container
				justifyContent='center'
				style={{
					height: "100vh",
					background: "url(/svg/gobananas-404.svg)",
					backgroundColor: "#f7f7f7",
					backgroundSize: "35%",
					backgroundPositionY: "80%",
					backgroundRepeat: "no-repeat",
					backgroundPositionX: "50%",
				}}>
				<Grid item xs={12} md={12} className='g-padding-y-40--xs g-padding-x-20--xs g-padding-x-80--md'>
					<Grid width={"100%"} display={"inline-flex"} justifyContent={"space-between"}>
						<div>
							<a href='https://gobanans.live'>
								<img src='/logo.png' height='30'></img>
							</a>
						</div>
						<div>
							<a href='/login'>Home</a>
						</div>
					</Grid>
					<Grid className='g-margin-t-80--xs'>
						<h1 className='g-margin-b-30--xs g-font-size-80--md g-font-size-40--xs g-text-align--center'>
							<b>Oops! Page not found</b>
						</h1>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default NotFound;
