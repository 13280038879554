import { LinearProgress } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WorkspaceWebService } from "../../services/WorkspaceWebService";
import { useAuth } from "../AuthContext";
import { useLoading } from "../LoadingContext";

const Main = ({ children, ...rest }: any) => {
	const { isLoggedIn, setupWorkspace } = useAuth();
	const { showLoading, hideLoading, isLoading } = useLoading();
	const navigate = useNavigate();

	const fetchWorkspaces = () => {
		showLoading();
		WorkspaceWebService.fetchAllWorkspaces()
			.then((workspaces: any) => {
				if (workspaces != null && workspaces.length > 0) {
					const workspace = setupWorkspace(workspaces);
					navigate(`/${workspace.id}/tasks`);
				} else {
					navigate("/onboarding");
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				hideLoading();
			});
	};

	useEffect(() => {
		if (isLoggedIn()) {
			fetchWorkspaces();
		} else {
			navigate("/login");
		}
	}, []);

	return (
		<>
			{isLoading && (
				<div className='g-content-center-xy--xs g-height-600--xs'>
					<LinearProgress className='g-margin-t-40--xs g-width-150--xs' color='secondary' />
				</div>
			)}
		</>
	);
};

export default Main;
