import { Chip } from "@mui/material";
import { StatusLabels } from "../../constants/TaskStatus";
import StateCircle from "./StateCircle";

const StatusChip = (props: any) => {
	const { status, ...rest } = props;
	const main = StatusLabels.getColor(status);
	const light = `${StatusLabels.getColor(status)}55`;
	return (
		<Chip {...rest} avatar={<StateCircle color={main} />} sx={{ backgroundColor: light }} label={StatusLabels.getLabel(status)} size='small' variant='filled' />
	);
};

export default StatusChip;
