import { ReactNode, createContext, useContext, useState } from "react";
import { User, Workspace } from "../models/Interface";
import { StorageService } from "../services/StorageService";
import LoadingBar from "./atoms/LoadingBar";
import { Notification } from "./atoms/Notification";
interface AuthContextType {
	token: string | null;
	user: User | any;
	currentWorkspace: Workspace | null;
	workspaces: Array<Workspace> | null;
	workflowId: string | null;
	login: (jwtToken: string, userDetails: User) => void;
	logout: () => void;
	isLoggedIn: () => boolean;
	setupWorkspace: (workspaces: Array<Workspace>) => Workspace;
	createNewWorkspace: (workspace: Workspace) => Workspace;
	changeWorkspace: (workspace: Workspace) => void;
	updateCurrentWorkspace: (workspace: Workspace) => void;
	setWorkflowId: (workflowId: string) => void;
	isAdmin: () => boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
	const [token, setToken] = useState<string | null>(localStorage.getItem(StorageService.TOKEN));
	const [user, setUser] = useState<User | null>(JSON.parse(localStorage.getItem(StorageService.USER) || "{}"));
	const [currentWorkspace, setCurrentWorkspace] = useState<Workspace | null>(JSON.parse(localStorage.getItem(StorageService.CURR_WORKSPACE) || "{}"));
	const [workspaces, setWorkspaces] = useState<Array<Workspace>>(JSON.parse(localStorage.getItem(StorageService.WORKSPACES) || "[]"));
	const [workflowId, setWorkflowId] = useState<string>("");

	const login = (jwtToken: string, userDetails: User) => {
		setToken(jwtToken);
		setUser(userDetails);
		StorageService.logIn(jwtToken, userDetails);
	};

	const logout = () => {
		setToken(null);
		setUser(null);
		StorageService.logOut();
	};

	const isLoggedIn = () => {
		return token !== null && user != null;
	};

	const isAdmin = (): boolean => {
		return (
			currentWorkspace?.members.some((member) => {
				return member.email === user?.email && member.role == "ADMIN";
			}) || false
		);
	};

	const createNewWorkspace = (workspace: Workspace) => {
		setCurrentWorkspace(workspace);
		if (!workspaces.some((p) => p.id == workspace.id)) {
			workspaces.push(workspace);
			setWorkspaces(workspaces);
			StorageService.setupWorkspace(workspace, workspaces);
		}
		return workspace;
	};

	const changeWorkspace = (workspace: Workspace) => {
		setCurrentWorkspace(() => workspace);
		StorageService.setupWorkspace(workspace, workspaces);
	};

	const updateCurrentWorkspace = (workspace: Workspace) => {
		if (workspace.id == currentWorkspace?.id) {
			setCurrentWorkspace(() => workspace);
			StorageService.setupWorkspace(workspace, workspaces);
		}
	};

	const setupWorkspace = (workspaces: Array<Workspace>) => {
		setWorkspaces(workspaces);
		if (currentWorkspace != null && workspaces.some((p) => p.id == currentWorkspace.id)) {
			StorageService.setupWorkspaces(workspaces);
			return currentWorkspace;
		} else {
			const workspace = workspaces[0];
			setCurrentWorkspace(workspace);
			StorageService.setupWorkspace(workspace, workspaces);
			return workspace;
		}
	};

	return (
		<AuthContext.Provider
			value={{
				token,
				user,
				currentWorkspace,
				workspaces,
				workflowId,
				login,
				logout,
				isLoggedIn,
				setupWorkspace,
				createNewWorkspace,
				changeWorkspace,
				updateCurrentWorkspace,
				setWorkflowId,
				isAdmin,
			}}>
			<LoadingBar />
			<Notification />
			{children}
		</AuthContext.Provider>
	);
}

export function useAuth() {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
}
