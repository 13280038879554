import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ReactNode, createContext, useContext, useState } from "react";
import Button from "./atoms/Button";

interface AlertConfirmationContextType {
	showConfirmationDialog: (title: string, description: string, onConfirmCallback: Function, onCloseCallback: Function | null) => void;
}

const AlertConfirmationContext = createContext<AlertConfirmationContextType | undefined>(undefined);

export function AlertConfirmationProvider({ children }: { children: ReactNode }) {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [title, setTitle] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [onConfirm, setOnConfirm] = useState(() => () => {});
	const [onClose, setOnClose] = useState(() => () => {});

	const showConfirmationDialog = (title: string, description: string, onConfirmCallback: Function, onCloseCallback: Function | null = null) => {
		setTitle(title);
		setDescription(description);
		setIsOpen(true);
		setOnConfirm(() => () => {
			setIsOpen(false);
			onConfirmCallback();
		});

		setOnClose(() => () => {
			setIsOpen(false);
			if (onCloseCallback) onCloseCallback();
		});
	};

	return (
		<AlertConfirmationContext.Provider
			value={{
				showConfirmationDialog,
			}}>
			{children}
			{isOpen && (
				<Dialog open={isOpen} onClose={onClose} fullWidth={true} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
					<DialogTitle id='alert-dialog-title'>
						<b>{title}</b>
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>{description}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose} text='No' radius='0'></Button>
						<Button onClick={onConfirm} color='dark' radius='0' text='Go Ahead' autoFocus></Button>
					</DialogActions>
				</Dialog>
			)}
		</AlertConfirmationContext.Provider>
	);
}

export function useAlertConfirmation() {
	const context = useContext(AlertConfirmationContext);
	if (context === undefined) {
		throw new Error("useLoading must be used within a useAlertConfirmation");
	}
	return context;
}
