import React, { forwardRef, useCallback, useEffect, useState } from "react";
import ReactFlow, {
	Background,
	BackgroundVariant,
	Controls,
	Edge,
	Node,
	PanOnScrollMode,
	ReactFlowProvider,
	useEdgesState,
	useNodesState,
	useReactFlow,
} from "reactflow";

import { Grid, Skeleton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "reactflow/dist/style.css";
import NodeTypes from "../../../constants/NodeTypes";
import { Reference, TaskTemplate, Workflow } from "../../../models/Interface";
import { StorageService } from "../../../services/StorageService";
import { WorkflowService } from "../../../services/WorkflowService";
import { WorkflowWebService } from "../../../services/WorkflowWebService";
import { localeDate } from "../../../utils/DateHelpers";
import { name_validation } from "../../../utils/InputValidations";
import { useAlertConfirmation } from "../../AlertConfirmationContext";
import { useAuth } from "../../AuthContext";
import { useLoading } from "../../LoadingContext";
import { useNotification } from "../../NotificationContext";
import Button from "../../atoms/Button";
import InputError from "../../atoms/InputError";
import InputV3 from "../../atoms/InputV3";
import UserChip from "../../atoms/UserChip";
import MoreOptionMenu, { MoreOptionMenuItem } from "../../molecules/MoreOptionMenu";
import ButtonEdge from "../../molecules/wfedges/ButtonEdge";
import ActivityDrawerNode from "../../molecules/wfnodes/ActivityDrawerNode";
import EditingNode from "../../molecules/wfnodes/EditingNode";
import RecordingsNode from "../../molecules/wfnodes/RecordingsNode";
import ReleaseYoutubeNode from "../../molecules/wfnodes/ReleaseYoutubeNode";
import ScriptNode from "../../molecules/wfnodes/ScriptNode";
import ThumbnailNode from "../../molecules/wfnodes/ThumbnailNode";
import { DashboardContext } from "../../pages/Dashboard";

const nodeTypes = {
	ActivityDrawer: ActivityDrawerNode,
	Content: ScriptNode,
	Script: ScriptNode,
	Recordings: RecordingsNode,
	Thumbnail: ThumbnailNode,
	Editing: EditingNode,
	Document: ScriptNode,
	Image: RecordingsNode,
	Video: ThumbnailNode,
	Audio: ThumbnailNode,
	ReleaseYoutube: ReleaseYoutubeNode,
};

const edgeTypes = {
	buttonEdge: ButtonEdge,
};

const initialEdges: Edge[] = [];

const initialNodes: Node[] = [];

interface WorkflowContextType {
	handleAddActivityFromDrawer: (activity: TaskTemplate) => void;
	handleActivityDrawer: (edgeId: string | null) => Promise<void>;
	handleDeleteActivity: (nodeId: string) => Promise<void> | void;
	handleNodeDataChange: (nodeId: string, data: any) => Promise<void> | void;
	activityDrawerId: string | undefined;
}

export const WorkflowContext = React.createContext<WorkflowContextType | null>(null);

const Flow = forwardRef((props: any, ref) => {
	const { setCenter, setViewport } = useReactFlow();
	const [rfInstance, setRfInstance] = useState(null);
	const zoom = 1.0;
	useEffect(() => {
		setCenter(props.x, props.y, { zoom, duration: 1000 });
	}, [props.trigger]);
	return <ReactFlow {...props} />;
});
const states = [{ label: "Draft" }, { label: "Up for Review" }, { label: "Approved" }, { label: "Required Changes" }, { label: "Rejected" }];
const WorkflowEditor = () => {
	let { workflowId } = useParams();
	const navigate = useNavigate();
	const { open, toggleOpen }: any = React.useContext(DashboardContext);
	const [isIntitialLoad, setIsIntitialLoad] = useState<boolean>(true);
	const [nodes, setNodes] = useNodesState<Node[]>(initialNodes);
	const [edges, setEdges] = useEdgesState<Edge[]>(initialEdges);
	const [trigger, setTrigger] = useState(0);
	const [posX, setPosX] = useState(0);
	const [posY, setPosY] = useState(0);
	const [hasActivityDrawer, setHasActivityDrawer] = useState<boolean>(false);
	const [isAnyTitleMissing, setIsAnyTitleMissing] = useState<boolean>(false);
	const [activityDrawerId, setActivityDrawerId] = useState<string>();
	const [rfInstance, setRfInstance] = useState<any>(null);
	const [workflow, setWorkflow] = useState<Workflow>({});
	const [lastChangeDatetime, setLastChangeDatetime] = useState<string>();
	const [workflowName, setWorkflowName] = useState<string>("");
	const [workflowState, setWorkflowState] = useState(states[2]);
	const { user, currentWorkspace, updateCurrentWorkspace, setWorkflowId } = useAuth();
	const { showErrorMessage, showSuccessMessage } = useNotification();
	const { showConfirmationDialog } = useAlertConfirmation();
	const { showLoading, hideLoading, isLoading } = useLoading();
	const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);
	let timeoutId: any;

	const NEW_WORKFLOW_ID = "new";
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};
	useEffect(() => {
		handleLoadingWorkflow(workflowId ?? NEW_WORKFLOW_ID);
	}, [workflowId]);

	useEffect(() => {
		if (Object.keys(workflow).length > 0) {
			handleLayoutWorkflow(workflow);
		}
	}, [open]);

	const handleLoadingWorkflow = (workflowId: string) => {
		if (workflowId != null && workflowId != NEW_WORKFLOW_ID) {
			showLoading();
			WorkflowWebService.fetchWorkflowById(workflowId)
				.then((data: any) => {
					if (data != null && data.length > 0) {
						const wf = data[0];
						if (Object.keys(wf).length > 0 && wf.nodes && wf.edges && wf.workspaceId === currentWorkspace?.id) {
							handleLayoutWorkflow(wf);
						} else {
							localStorage.removeItem(StorageService.WORKFLOW_STORAGE_KEY);
						}
					}
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					hideLoading();
				});
		} else {
			localStorage.removeItem(StorageService.WORKFLOW_STORAGE_KEY);
			setNodes([]);
			setEdges([]);
			setWorkflow({});
			setWorkflowName("");
		}
	};

	const handleActivityDrawer = useCallback(
		async (edgeId: string | null) => {
			if (!hasActivityDrawer && !isAnyTitleMissing) {
				await WorkflowService.AddActivityDrawerNode(nodes, edges, edgeId, open).then(
					async (value: any) => {
						if ("addedNode" in value) {
							setHasActivityDrawer(() => value.addedNode.type == NodeTypes.ActivityDrawerNode);
							setActivityDrawerId(() => (value.addedNode.type == NodeTypes.ActivityDrawerNode ? value.addedNode.id : undefined));
						}
						workflow.nodes = value.newNodes;
						workflow.edges = value.newEdges;
						await handleLayoutWorkflow(workflow);
						if ("addedNode" in value) {
							const layoutedNode = nodes.find((nd: any, index) => {
								if (nd.id == value?.addedNode.id) {
									const x = nd.position.x + 600 / 2;
									const y = 375 * (index + 1) - nd.position.y;
									setPosX(x);
									setPosY(y);
									setTrigger(() => trigger + 1);
									return true;
								}
							});
						}
					},
					() => {}
				);
			}
		},
		[nodes, edges]
	);

	const handleAddActivityFromDrawer = useCallback(
		async (activity: TaskTemplate) => {
			await WorkflowService.AddNodeFromActivityDrawer(nodes, edges, activityDrawerId, activity, open).then(
				async (value: any) => {
					if ("addedNode" in value) {
						setHasActivityDrawer(() => value.addedNode.type == NodeTypes.ActivityDrawerNode);
						setActivityDrawerId(() => (value.addedNode.type == NodeTypes.ActivityDrawerNode ? value.addedNode.id : undefined));
					}
					workflow.nodes = value.newNodes;
					workflow.edges = value.newEdges;
					await handleLayoutWorkflow(workflow);
					// handleSaveWorkflow();
					if ("addedNode" in value) {
						const layoutedNode = nodes.find((nd: any, index) => {
							if (nd.id == value?.addedNode.id) {
								const x = nd.position.x + 600 / 2;
								const y = 375 * (index + 1) - nd.position.y;
								setPosX(x);
								setPosY(y);
								setTrigger(() => trigger + 1);
								return true;
							}
						});
					}
				},
				() => {}
			);
		},
		[nodes, edges]
	);

	const handleDeleteActivity = async (nodeId: string) => {
		console.log(`In handleDeleteActivity`);
		await WorkflowService.DeleteNode(nodes, edges, nodeId).then(
			async (value: any) => {
				if ("deletedNode" in value && value["deletedNode"]?.type == NodeTypes.ActivityDrawerNode) {
					setHasActivityDrawer(false);
					setActivityDrawerId(undefined);
				}
				workflow.nodes = value.newNodes;
				workflow.edges = value.newEdges;
				await handleLayoutWorkflow(workflow);
				// handleSaveWorkflow();
			},
			() => {}
		);
	};

	const handleNodeDataChange = async (nodeId: string, data: any) => {
		console.log(`In handleNodeDataChange`);
		await WorkflowService.UpdateNodeData(nodes, edges, nodeId, data).then(async (value: any) => {
			workflow.nodes = value.newNodes;
			workflow.edges = value.newEdges;
			await handleLayoutWorkflow(workflow);
			// handleSaveWorkflow();
		});
	};

	const handleWorkflowNameChange = useCallback(
		(e: any) => {
			console.log(`In handleWorkflowNameChange`);
			const value = e.target?.value;
			if (value != null) {
				setWorkflowName(value);
				workflow.name = value;
				setWorkflow(workflow);
				// handleSaveWorkflow();
			}
		},
		[workflow]
	);

	const handleWorkflowStateChange = useCallback(
		(value: any) => {
			console.log(`In handleWorkflowNameChange`);
			if (value != null) {
				setWorkflowState(value);
				workflow.status = value.label;
				setWorkflow(workflow);
				// handleSaveWorkflow();
			}
		},
		[workflow]
	);

	const handleDeleteWorkflow = () => {
		if (workflowId) {
			showLoading();
			WorkflowWebService.deleteWorkflow(workflowId)
				.then((data) => {
					if (currentWorkspace) {
						const workflowIdNames = currentWorkspace.workflows;
						currentWorkspace?.workflows?.splice(
							workflowIdNames?.findIndex((a) => a.id == workflowId),
							1
						);
						updateCurrentWorkspace(currentWorkspace);
						localStorage.removeItem(StorageService.WORKFLOW_STORAGE_KEY);
						if (currentWorkspace.workflows.length > 0) {
							navigate(`/${currentWorkspace?.id}/workflow/${currentWorkspace.workflows[0].id}`);
						} else {
							navigate(`/${currentWorkspace?.id}/workflow/new`);
						}
					}
				})
				.catch((err) => showErrorMessage(err.message))
				.finally(() => {
					hideLoading();
				});
		}
	};

	const handleSaveWorkflow = async () => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			setTimeout(() => {
				if (workflow && hasActivityDrawer == false && currentWorkspace && nodes.length > 0 && !isIntitialLoad) {
					console.log(`In handleSaveWorkflow`, new Date());
					if (!workflow.name || workflow.name.length == 0) {
						showErrorMessage("Workflow Name can't be empty.\n Unable to save the workflow");
						return;
					}
					setIsSavingInProgress(true);
					const flow = rfInstance.toObject();
					const workspaceId = currentWorkspace.id;
					workflow.workspaceId = workspaceId;
					workflow.status = states[2].label; // Default to Approved
					if (workflowId != null && workflowId != NEW_WORKFLOW_ID) {
						workflow["id"] = workflowId;
					}
					const newWorkflow: Workflow = { ...workflow, ...flow };
					setWorkflow(newWorkflow);
					WorkflowWebService.saveWorkflow(workspaceId, newWorkflow)
						.then((data: any) => {
							if (data != null) {
								if (workflowId != data.id) {
									setWorkflowId(data.id);
								}
								workflow.id = data.id;
								setWorkflow(workflow);
								setLastChangeDatetime(localeDate());
								WorkflowService.StoreWorkFlow(workflow);
								const workflowIdNames = currentWorkspace.workflows;
								const workflows = workflowIdNames?.filter((wf) => wf.id == data.id);
								if (workflows.length == 0 || workflowIdNames.length == 0) {
									workflowIdNames?.push({
										name: data.name,
										id: data.id,
									});
									currentWorkspace.workflows = workflowIdNames;
									updateCurrentWorkspace(currentWorkspace);
								} else {
									currentWorkspace.workflows = workflowIdNames.map((wfidname) => {
										if (wfidname.id == data.id) {
											wfidname.name = data.name;
										}
										return wfidname;
									});
									updateCurrentWorkspace(currentWorkspace);
								}
								showSuccessMessage("Workflow saved successfully");
							}
						})
						.catch((err) => {
							showErrorMessage(err.message);
						})
						.finally(() => {
							setIsSavingInProgress(false);
						});
				}
			}, 100);
		}, 200);
	};

	const handleLayoutWorkflow = async (workflow: any) => {
		console.log("In Handle Layout workflow");
		setNodes([]);
		setEdges([]);
		await WorkflowService.LayoutWorkflow(workflow, open).then(async (layoutedWorkflow: any) => {
			const newNodes = layoutedWorkflow.nodes;
			setIsAnyTitleMissing(false);
			for (let i = 0; i < newNodes.length; i++) {
				const currNodeRefs: Reference[] = [];

				if (i > 0) {
					const prevNodeData = newNodes[i - 1]["data"];
					const prevNodeRefs: Array<Reference> = prevNodeData["availableRefs"];
					const prevNodeInputs: Array<string> = [prevNodeData["title"]];
					prevNodeInputs.map((input: string) => {
						const newReference: Reference = { taskTemplateIdRef: `${newNodes[i - 1].id}.${newNodes[i - 1].data?.title}` };
						currNodeRefs.push(newReference);
					});
					prevNodeRefs.map((ref: Reference) => currNodeRefs.push(ref));
				}

				newNodes[i]["data"]["availableRefs"] = currNodeRefs;
				if (newNodes[i]["data"]["title"].length === 0) setIsAnyTitleMissing(true);
			}

			setIsIntitialLoad(false);
			setNodes(newNodes);
			setEdges(layoutedWorkflow.edges);
			setWorkflowId(workflow.id);
			setWorkflowName(workflow.name);
			setWorkflowState({ label: workflow.status });
			setWorkflow(layoutedWorkflow);
			hideLoading();
			return { layoutedWorkflow };
		});
	};

	const moreMenuOptions: MoreOptionMenuItem[] = [
		{ title: "Reload Workflow", icon: "refresh", action: () => handleLoadingWorkflow(workflowId ?? NEW_WORKFLOW_ID) },
		{ title: "Add Activity", icon: "add", isDisable: hasActivityDrawer || isAnyTitleMissing, action: () => handleActivityDrawer(null) },
		{
			title: "Delete Workflow",
			icon: "delete",
			isDisable: workflowId === NEW_WORKFLOW_ID,
			action: () =>
				showConfirmationDialog(
					"Confirm Deletion",
					"Are you sure you would like to delete this workflow? This action can not be undone.",
					handleDeleteWorkflow,
					null
				),
		},
	];
	return (
		<>
			{isLoading ? (
				<div className='g-height-100-percent--xs '>
					<div className='g-margin-t-20--xs g-hor-divider__solid--gray'>
						<Skeleton variant='text' className='g-margin-l-10--xs' width={"500px"} sx={{ fontSize: "1.6rem" }} />
						<Skeleton variant='text' className='g-margin-l-10--xs' width={"200px"} sx={{ fontSize: "1.2rem" }} />
					</div>

					<div className='g-margin-t-40--xs g-content-center-x--xs g-height-100-percent--xs g-width-100-percent--xs'>
						<Skeleton variant='rectangular' width={600} height={280} />
					</div>
				</div>
			) : (
				<>
					<Grid
						container
						className='g-margin-t-0--xs g-padding-x-20--xs g-padding-y-10--xs g-hor-divider__solid--gray'
						style={{ borderLeft: "10px solid var(--active)" }}>
						<Grid container className='g-margin-t-0--xs'>
							<Grid item xs={8}>
								<div className='g-content-center-y--xs'>
									<i className='material-symbols-sharp g-margin-r-5--xs g-font-size-20--xs'>account_tree</i>
									<InputV3
										value={workflowName}
										onChange={(e: any) => handleWorkflowNameChange(e)}
										validation={name_validation}
										name='workflowName'
										placeholder='Enter Workflow Name'
									/>
								</div>
							</Grid>
							<Grid item xs={1}></Grid>
							<Grid item xs={3}>
								<div className='g-display-flex--xs' style={{ justifyContent: "flex-end" }}>
									<div className='g-display-flex--xs g-width-100-percent--xs g-margin-r-10--xs' style={{ alignItems: "center" }}>
										<div className='g-width-200--xs g-margin-l-5--xs'>
											{/* <AutoCompleteSm
												onChange={(e: any, v: any) => handleWorkflowStateChange(v)}
												options={states}
												value={workflowState.label}
												defaultValue={states[0]}
												placeholder='Select State'
											/> */}
										</div>
									</div>

									<div className='g-margin-r-10--xs'>
										<Button
											onClick={handleSaveWorkflow}
											disabled={hasActivityDrawer || isAnyTitleMissing}
											style={{ height: "33px" }}
											icon='save'
											color={"primary"}
											text='Save & Close'
											radius={3}
											variant='contained'></Button>
									</div>
									<div className='g-margin-r-5--xs'>
										<Button style={{ height: "33px" }} onClick={handleMenuClick} icon='more_horiz' color={"light"} radius={4} variant='contained'></Button>
									</div>
								</div>
								<MoreOptionMenu anchorElement={anchorEl} closeHandler={handleMenuClose} items={moreMenuOptions} />
							</Grid>
						</Grid>
						<Grid container style={{ alignItems: "center" }} className='g-margin-t-5--xs'>
							<Grid item xs={8}>
								<div className='g-display-flex--xs' style={{ alignItems: "flex-end" }}>
									<p className='g-margin-b-0--xs g-margin-r-10--xs'>Created By</p>
									<UserChip id={user.email} />
								</div>
							</Grid>

							<Grid item xs={4}>
								<div className='g-display-flex--xs g-width-100-percent--xs' style={{ alignItems: "center", justifyContent: "flex-end" }}>
									{workflowName?.length == 0 ? (
										<InputError message="Workflow name can't be empty"></InputError>
									) : isSavingInProgress ? (
										<p className='g-margin-b-0--xs g-margin-r-10--xs g-text-align-right--xs g-font-weight--800'>Saving Workflow...</p>
									) : (
										lastChangeDatetime && (
											<p className='g-margin-b-0--xs g-margin-r-10--xs g-text-align-right--xs'>
												Last updated at: <b>{lastChangeDatetime}</b>
											</p>
										)
									)}
								</div>
							</Grid>
						</Grid>
					</Grid>
					<WorkflowContext.Provider
						value={{
							handleAddActivityFromDrawer: handleAddActivityFromDrawer,
							handleActivityDrawer: handleActivityDrawer,
							handleDeleteActivity: handleDeleteActivity,
							handleNodeDataChange: handleNodeDataChange,
							activityDrawerId: activityDrawerId,
						}}>
						<div id='reactflowplan' style={{ width: "100%", height: "90vh" }}>
							<ReactFlowProvider>
								<Flow
									style={{ background: "#fafafa" }}
									nodes={nodes}
									edges={edges}
									nodeTypes={nodeTypes}
									edgeTypes={edgeTypes}
									zoomOnScroll={false}
									zoomOnDoubleClick={false}
									nodesDraggable={false}
									panOnScroll={true}
									panOnDrag={false}
									panOnScrollMode={PanOnScrollMode.Vertical}
									x={posX}
									y={posY}
									trigger={trigger}
									onInit={setRfInstance}>
									<Controls showInteractive={false} />
									<Background color='#777' variant={BackgroundVariant.Dots} gap={35} size={2} />
									<div style={{ position: "absolute", top: "90%", left: "calc(50% - 80px)", zIndex: 4 }}>
										<Button
											disabled={hasActivityDrawer || isAnyTitleMissing}
											onClick={() => handleActivityDrawer(null)}
											text='Add Activity'
											icon='add'
											radius='3'
											variant='contained'
										/>
									</div>
								</Flow>
							</ReactFlowProvider>
						</div>
					</WorkflowContext.Provider>
				</>
			)}
		</>
	);
};

export default WorkflowEditor;
