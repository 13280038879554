import { Grid, MenuItem, Select, styled } from "@mui/material";
import Hls from "hls.js";
import React, { memo, useEffect, useRef, useState } from "react";
import { WorkspaceWebService } from "../../services/WorkspaceWebService";
import { fancyTimeFormat } from "../../utils/Helpers";
import Button from "./Button";
import { useHLSPlayer } from "../../components/HLSPlayerContext";

interface QualityOption {
	label: string;
	level: number;
}

const ProgressBarContainer = styled("div")({
	width: "100%",
	height: 5,
	position: "relative",
	borderRadius: 0,
	backgroundColor: "#ddd",
	overflow: "hidden",
	cursor: "pointer",
	marginTop: "0px",
});
const ProgressBar = styled("div")<{ progress: number; buffered: number }>(({ progress, buffered }) => ({
	width: "100%",
	height: 5,
	position: "relative",
	borderRadius: 2,
	backgroundColor: "#ddd",
	overflow: "hidden",
	"&::before": {
		content: '""',
		position: "absolute",
		top: 0,
		left: 0,
		width: `${buffered}%`,
		height: "100%",
		backgroundColor: "rgba(0, 0, 0, 0.1)",
	},
	"&::after": {
		content: '""',
		position: "absolute",
		top: 0,
		left: 0,
		width: `${progress}%`,
		height: "100%",
		backgroundColor: "var(--primary)",
	},
}));

interface VolumeControlProps {
	value: number;
	onChange: (volume: number) => void;
}
const VolumeControl: React.FC<VolumeControlProps> = ({ value, onChange }) => {
	const handleVolumeChange = (event: any) => {
		const newVolume = parseFloat(event.target.value);
		onChange(newVolume);
	};

	return (
		<div className='g-display-flex--xs' style={{ alignItems: "center" }}>
			{value > 0 ? (
				<Button onClick={() => onChange(0)} variant='text' icon='volume_up' />
			) : (
				<Button onClick={() => onChange(1)} variant='text' icon='volume_off' />
			)}
			<input id='myinput' type='range' min={0} max={1} step={0.01} value={value} onChange={handleVolumeChange} />
		</div>
	);
};

interface HLSPlayerProps {
	originalSrc: string;
	hlsSrc?: string;
	getCurrentTime?: (time: any) => void;
	isHlsStreamAvailable: boolean | null;
}
const HLSPlayer: React.FC<HLSPlayerProps> = ({ originalSrc, hlsSrc, getCurrentTime, isHlsStreamAvailable }: HLSPlayerProps) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const hlsRef = useRef<Hls | null>(null);
	const { currentTime, isPlaying, setCurrentTime, setIsPlaying } = useHLSPlayer();
	const [qualityOptions, setQualityOptions] = useState<QualityOption[]>([]);
	const [selectedQuality, setSelectedQuality] = useState<number | "auto">("auto");
	const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
	const [duration, setDuration] = useState<number>(0);
	const [volume, setVolume] = useState(1);

	const [progress, setProgress] = useState(0);
	const [buffered, setBuffered] = useState(0);
	const [isLoaded, setLoaded] = useState(0);
	const updateProgress = () => {
		if (videoRef.current) {
			const progressPercent = (videoRef.current.currentTime / videoRef.current.duration) * 100;
			setProgress(progressPercent);
			setCurrentTime(videoRef.current.currentTime);
			setDuration(videoRef.current.duration);
			if (videoRef.current.currentTime && typeof getCurrentTime === "function") {
				getCurrentTime(videoRef.current.currentTime);
			}
			if (videoRef.current.buffered.length > 0) {
				const bufferedEnd = videoRef.current.buffered.end(0);
				const bufferedPercent = (bufferedEnd / videoRef.current.duration) * 100;
				setBuffered(bufferedPercent);
			}
		}
	};
	useEffect(() => {
		const intervalId = setInterval(updateProgress, 500);

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		if (videoRef.current && currentTime !== videoRef.current.currentTime) {
			videoRef.current.currentTime = currentTime;
			const progressPercent = (currentTime / videoRef.current.duration) * 100;
			setProgress(progressPercent);
			// setIsPlaying(false);
		}
	}, [isPlaying]);

	const handleProgressClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (videoRef.current) {
			const rect = event.currentTarget.getBoundingClientRect();
			const offsetX = event.clientX - rect.left;
			const progressBarWidth = rect.width;
			const seekPercent = (offsetX / progressBarWidth) * 100;
			const seekTime = (seekPercent * videoRef.current.duration) / 100;
			videoRef.current.currentTime = seekTime;
			setCurrentTime(videoRef.current.currentTime);
			if (videoRef.current.currentTime && typeof getCurrentTime === "function") {
				getCurrentTime(videoRef.current.currentTime);
			}
			setBuffered(videoRef.current.currentTime);
		}
	};

	const removeDups = (originalArray: QualityOption[]) => {
		const uniqueArray = originalArray.filter((obj, index, self) => index === self.findIndex((o) => o.label === obj.label));
		return uniqueArray;
	};

	useEffect(() => {
		hlsRef.current = new Hls();
		if (videoRef.current && hlsRef.current) {
			if (hlsSrc && isHlsStreamAvailable) {
				hlsRef.current.loadSource(hlsSrc);
				hlsRef.current.attachMedia(videoRef.current);
				hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
					const availableQualities: QualityOption[] = hlsRef.current!.levels.map((level, index) => ({
						label: `${level.height}p`,
						level: index,
					}));
					setQualityOptions(removeDups(availableQualities));
				});
				hlsRef.current.on(Hls.Events.ERROR, () => {
					if (videoRef.current && hlsRef.current) {
						videoRef.current.src = originalSrc;
					}
				});
			} else {
				videoRef.current.src = originalSrc;
			}
		}
		return () => {
			if (hlsRef.current) {
				hlsRef.current.destroy();
			}
		};
	}, [isHlsStreamAvailable]);

	useEffect(() => {
		if (hlsRef.current) {
			if (selectedQuality === "auto") {
				hlsRef.current.currentLevel = -1; // Set to -1 for auto quality
			} else {
				hlsRef.current.currentLevel = selectedQuality;
			}
		}
	}, [selectedQuality]);

	useEffect(() => {
		if (isPlaying) {
			videoRef.current!.play();
		} else {
			videoRef.current!.pause();
		}
	}, [isPlaying]);

	useEffect(() => {
		videoRef.current!.playbackRate = playbackSpeed;
	}, [playbackSpeed]);

	const handleQualityChange = (event: any) => {
		const selectedQuality = event.target.value === "auto" ? "auto" : parseInt(event.target.value as string);
		setSelectedQuality(selectedQuality);
	};

	const handlePlayPause = () => {
		setIsPlaying(!isPlaying);
	};

	const toggleFullscreen = () => {
		if (videoRef.current) {
			if (document.fullscreenElement) {
				document.exitFullscreen();
			} else {
				videoRef.current.requestFullscreen();
			}
		}
	};

	const handleVolumeChange = (newVolume: number) => {
		if (videoRef.current) {
			videoRef.current.volume = newVolume;
			setVolume(newVolume);
		}
	};

	return (
		<div style={{ width: "100%", margin: "auto" }} className='g-bg-color--dark-light'>
			<video ref={videoRef} style={{ maxWidth: "100%", height: "72vh", margin: "auto", display: "block" }} onClick={handlePlayPause}></video>
			<ProgressBarContainer onClick={handleProgressClick}>
				<ProgressBar progress={progress} buffered={buffered} />
			</ProgressBarContainer>
			<Grid container style={{ background: "var(--gray-light)" }}>
				<Grid item xs={4} className='g-display-flex--xs' style={{ alignItems: "center" }}>
					<Button variant='text' onClick={handlePlayPause} icon={isPlaying ? "pause" : "play_arrow"}></Button>
					<Select
						sx={{
							fontSize: "14px",
							height: "2.375rem",
							"& .MuiOutlinedInput-notchedOutline": {
								borderColor: "var(--dark)",
								borderWidth: ".0rem !important",
							},
							"& .MuiSelect-select": {
								padding: "0 0.5rem",
							},
						}}
						value={playbackSpeed}
						onChange={(event) => setPlaybackSpeed(event.target.value as number)}>
						<MenuItem value={0.5}>0.5x</MenuItem>
						<MenuItem value={1}>1x</MenuItem>
						<MenuItem value={1.5}>1.5x</MenuItem>
						<MenuItem value={2}>2x</MenuItem>
					</Select>

					<VolumeControl value={volume} onChange={handleVolumeChange} />
				</Grid>
				<Grid item xs={4} className='g-display-flex--xs' style={{ alignItems: "center", justifyContent: "center" }}>
					{fancyTimeFormat(currentTime)}/{fancyTimeFormat(duration)}
				</Grid>
				<Grid item xs={4} className='g-display-flex--xs' style={{ alignItems: "center", justifyContent: "flex-end" }}>
					<Select
						sx={{
							fontSize: "14px",
							height: "2.375rem",
							"& .MuiOutlinedInput-notchedOutline": {
								borderColor: "var(--dark)",
								borderWidth: ".0rem !important",
							},
							"& .MuiSelect-select": {
								padding: "0 0.5rem",
							},
						}}
						value={selectedQuality}
						onChange={handleQualityChange}>
						<MenuItem value='auto'>Auto</MenuItem>
						{qualityOptions.map((option, index) => (
							<MenuItem key={index} value={option.level?.toString()}>
								{option.label}
							</MenuItem>
						))}
					</Select>
					<Button variant='text' onClick={toggleFullscreen} icon={"fullscreen"}></Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default memo(HLSPlayer);
