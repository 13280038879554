import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useContext, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Handle, Position } from "reactflow";
import NodeTypes from "../../../constants/NodeTypes";
import { Reference } from "../../../models/Interface";
import { useAuth } from "../../AuthContext";
import Button from "../../atoms/Button";
import InputError from "../../atoms/InputError";
import InputV3 from "../../atoms/InputV3";
import MultipleSelectChip, { MultipleSelectChipOption } from "../../atoms/MultipleSelectChip";
import Popover from "../../atoms/Popover";
import { WorkflowContext } from "../../templates/dashboard/WorkflowEditor";

const BaseNode = (props: any) => {
	const methods = useForm({ mode: "onBlur" });
	const { user, currentWorkspace, updateCurrentWorkspace, setWorkflowId } = useAuth();
	const { handleDeleteActivity, handleNodeDataChange }: any = useContext(WorkflowContext);
	const { id, type, data, ...children } = props;
	const [expanded, setExpanded] = React.useState<string | false>("panel1");
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const ref: any = useRef(null);

	const [height, setHeight] = useState(ref.current?.clientHeight);
	const [title, setTitle] = useState<string>(data?.title);
	const [references, setReferences] = useState(data?.references ?? []);
	let members: MultipleSelectChipOption[] = [];
	if (currentWorkspace) {
		members = currentWorkspace?.members.map((member) => {
			return { label: `${member.firstName} ${member.lastName ?? ""}`, value: member.email, icon: member.avatar ?? "k" };
		});
	}
	const availableReferences = data?.availableRefs ?? [];

	const toStringArray = (arr: Array<Reference>): string[] => {
		const newArray: string[] = [];
		arr.map((ref: Reference) => {
			const taskTemplateRef = ref.taskTemplateIdRef.split(".");
			newArray.push(taskTemplateRef[0]);
		});
		return newArray;
	};

	const toOptionsArray = (arr: Array<Reference>): MultipleSelectChipOption[] => {
		const newArray: MultipleSelectChipOption[] = [];
		arr.map((ref: Reference) => {
			const taskTemplateRef = ref.taskTemplateIdRef.split(".");
			newArray.push({ label: `Approved files from ${taskTemplateRef[1]}`, key: taskTemplateRef[0], value: taskTemplateRef[0] });
		});
		return newArray;
	};

	const handleAssignedToChange = (value: any) => {
		data.assignedTo = value[0];
		handleNodeDataChange(id, data);
	};
	const handleReviewersChange = (value: any) => {
		data.reviewers = value;
		handleNodeDataChange(id, data);
	};
	const handleReferencesChange = (value: any) => {
		const newReferences: Array<Reference> = [];
		value.map((val: string) =>
			newReferences.push({
				taskTemplateIdRef: val,
			})
		);
		data.references = newReferences;
		setReferences(newReferences);
		handleNodeDataChange(id, data);
	};
	const handleDeleteNode = (id: string) => {
		handleDeleteActivity(id, type);
	};

	const handleInfoClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleTitleChange = (event: any) => {
		const value = event.target.value;
		setTitle(value);
	};

	const acceptTitleChange = () => {
		data.title = title;
		handleNodeDataChange(id, data);
	};

	const declineTitleChange = () => {
		setTitle(data.title);
	};

	return (
		<div className='g-hor-border-1__solid--light' id={id}>
			<Handle position={Position.Top} type='target' style={{ width: "0", height: 0, top: "0px", background: "transparent", borderColor: "transparent" }} />
			<Accordion
				expanded={expanded === "panel1"}
				ref={ref}
				className='g-width-600--xs g-bg-color--white g-box-shadow__dark-lightest-v2'
				sx={{
					"&.Mui-expanded": {
						margin: 0,
					},
					"&.MuiAccordion-root": {
						margin: 0,
						borderRight: 0,
					},
				}}>
				<AccordionSummary
					aria-controls='panel1d-content'
					id='panel1d-header'
					sx={{
						"&.MuiAccordionSummary-root": {
							minHeight: "40px",
							background: data.theme,
							".MuiAccordionSummary-content": {
								"&.Mui-expanded": {
									margin: "0.2rem 0",
								},
							},
						},
					}}>
					<div className='g-display-flex--xs' style={{ alignItems: "center" }}>
						<span
							onPointerOver={handleInfoClick}
							onPointerOut={handleInfoClick}
							style={{ marginLeft: "-10px" }}
							className='material-symbols-sharp g-color--darklight g-margin-r-5--xs g-font-size-24--xs'>
							{data.avatar}
						</span>
						{type != NodeTypes.ActivityDrawerNode ? (
							<div className='g-display-flex--xs' style={{ alignItems: "center" }}>
								<InputV3 name={data.title} value={title} onChange={handleTitleChange} placeholder={"Enter Title"} />
								{data.title !== title && <Button onClick={acceptTitleChange} icon='check' variant='text' />}
								{data.title !== title && <Button onClick={declineTitleChange} icon='close' variant='text' />}
								{title.length == 0 && (
									<div style={{ width: "100%" }}>
										<InputError message='Title can not be empty.' />
									</div>
								)}
							</div>
						) : (
							<p className='g-margin-b-0--xs g-font-size-16--xs g-color--darklight'>{data.title}</p>
						)}

						<span
							onClick={() => handleDeleteNode(id)}
							style={{ right: 10, position: "absolute" }}
							className='material-symbols-sharp g-color--darklight g-margin-l-5--xs g-font-size-24--xs'>
							close
						</span>
						<Popover anchorEl={anchorEl}>{data.description}</Popover>
					</div>
				</AccordionSummary>
				<AccordionDetails sx={{ paddingTop: "20px", minHeight: "285px", overflowY: "auto" }}>
					{type != NodeTypes.ActivityDrawerNode && (
						<FormProvider {...methods}>
							<MultipleSelectChip
								value={data.assignedTo}
								onSelect={handleAssignedToChange}
								name='assignedTo'
								options={members}
								label='Assigned To'
								placeholder='Select User'
							/>
							<MultipleSelectChip
								value={data.reviewers}
								onSelect={handleReviewersChange}
								name='reviewers'
								options={members}
								label='Reviewers'
								placeholder='Select Users'
								allowMultiple={true}
							/>
							<MultipleSelectChip
								value={toStringArray(references)}
								onSelect={handleReferencesChange}
								name='references'
								options={toOptionsArray(availableReferences)}
								label='References'
								placeholder='Select References'
								allowMultiple={true}
							/>
						</FormProvider>
					)}
					<div {...children}></div>
				</AccordionDetails>
			</Accordion>

			<Handle
				position={Position.Bottom}
				type='source'
				style={{ width: "0", height: 0, bottom: "0px", background: "transparent", borderColor: "transparent" }}
			/>
		</div>
	);
};

export default BaseNode;
