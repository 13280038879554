class APIPaths {
	private static APIVersion: string = "v1";
	public static BaseURL: string = `${process.env.REACT_APP_API_URL}/api/${this.APIVersion}`;
	public static LoginAPI: string = "/authenticate";
	public static SignUpAPI: string = "/user";
	public static SASToken: string = "/sasToken";
	public static OrganizationAPI: string = "/organization";
	public static WorkspaceAPI: string = "/workspace";
}

export default APIPaths;
