import React, { useState, useEffect } from "react";
import { useHLSPlayer } from "../../../components/HLSPlayerContext";
import { fancyTimeFormat } from "../../../utils/Helpers";
import { Box, Grid, Tab, Tabs, TextareaAutosize } from "@mui/material";
import UserAvatar from "../../atoms/UserAvatar";
import Button from "../../atoms/Button";
import { useAuth } from "../../AuthContext";
import { useComments } from "../../CommentsContext";

const Timer = (props: any) => {
	const { user } = useAuth();
	const { currentTime, setIsPlaying } = useHLSPlayer();
	const [currentTimeNow, setCurrentTimeNow] = useState<number>(0);
	useEffect(() => {
		setCurrentTimeNow(currentTime);
	}, [currentTime]);

	// text area of comment
	const { addComment } = useComments();

	const [commentText, setCommentText] = useState("");

	const handleChange = (event: any) => {
		setCommentText(event.target.value);
	};

	// styling
	const mainDiv = {
		display: "flex",
		"jusity-content": "center",
		"align-items": "center",
		backgroundColor: "#fffafa",
		paddingLeft: "0.5rem",
		paddingRight: "0.5rem",
		paddingTop: "0.2rem",
		paddingBottom: "0.2rem",
		columnGap: "0.3rem",
		borderRadius: "0.5rem",
		fontSize: "0.8rem",
	};

	const handleComment = () => {
		const newComment = {
			commentsId: "some-id",
			commenterId: user.id,
			parentId: "null",
			thumb: "",
			completed: false,
			completerId: "",
			annotation: "",
			frame: 0,
			timestampMs: currentTimeNow,
			text: commentText,
			likeCount: 0,
			updatedOn: new Date(),
			createdOn: new Date(),
			isDeleted: false,
			userName: `${user.firstName} ${user.lastName}`,
			timestamp: "Just now",
			time: fancyTimeFormat(currentTimeNow),
			replies: [],
		};
		if (commentText.length > 0) {
			addComment(newComment);
		}
		setCommentText("");
	};

	return (
		<Grid item xs={12} className='g-display-flex--xs' style={{ justifyContent: "center" }}>
			<div
				className=' g-radius--10 g-margin-t-20--xs g-padding-y-15--xs g-padding-x-20--xs g-padding'
				style={{ width: "100%", backgroundColor: "white", border: "1px solid #ccc" }}>
				<div className='g-content-row-left--xs '>
					<div className='g-content-center-xy--xs'>
						<div className='user'>
							<div className='commentsAvatar'>
								{user.firstName[0]}
								{user.lastName[0]}
								<span className='stat green'></span>
							</div>
						</div>

						{props.isVideo && (
							<div style={mainDiv}>
								<i className='material-symbols-sharp'>timer</i>
								<div>{fancyTimeFormat(currentTimeNow)}</div>
							</div>
						)}
					</div>
					<TextareaAutosize
						placeholder='Leave your comments here...'
						minRows={1}
						value={commentText}
						onChange={handleChange}
						style={{ border: 0, paddingTop: "0.5rem", marginTop: "0.5rem", marginBottom: "0.5rem" }}
						className='s-form-v1__input g-width-100-percent--xs '
						onFocus={() => setIsPlaying(false)}
						onKeyDown={(e) => {
							if (e.key === "Enter" && !e.shiftKey) {
								e.preventDefault();
								handleComment();
							}
						}}
					/>
				</div>

				<div className='g-display-flex--xs' style={{ justifyContent: "flex-end" }}>
					<Button disable={commentText.length > 0} onClick={handleComment} size='xs' text='Send' color={"dark"} radius={4} variant='contained'></Button>
				</div>
			</div>
		</Grid>
	);
};

export default Timer;
