import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BlobFile, FileNode } from "../../models/Interface";
import buildFileTree from "../../utils/FileTree";
import { formatBytes } from "../../utils/Helpers";
import Button from "../atoms/Button";
import FileTile from "../atoms/FileTile";
import Folder from "../atoms/Folder";
import StatusChip from "../atoms/StatusChip";
import { EnhancedTable } from "../atoms/Table";
import UserChip from "../atoms/UserChip";
import { useBasePath } from "../BasePathContext";
import NoData from "./NoData";
import UploadManager, { FileManagerProps } from "./UploadManager";

const header = [
	{
		id: "name",
		label: "Name",
		render: (item: any) => (
			<div
				className='g-display-flex--xs'
				style={{ maxWidth: "300px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", justifyContent: "space-between" }}>
				<Link to={`${item.fileType?.includes("text") ? "doc" : "player"}/${item.id}`}>{item.name}</Link>
			</div>
		),
	},
	{
		id: "status",
		label: "Status",

		render: (item: any) => {
			return (
				<>
					{item.isReferenced && <StatusChip status={item.isReferenced ? "REFERENCED" : "DRAFT"} />}

					<StatusChip status={item.approved ? "APPROVED" : "DRAFT"} />
				</>
			);
		},
	},
	{
		id: "fileSize",
		label: "Size",
		render: (item: any) => formatBytes(item.fileSize),
	},
	{
		id: "fileType",
		label: "Type",
		render: (item: any) => item.fileType,
	},
	{
		id: "uploadDate",
		label: "Upload At",
		render: (item: any) => new Date(item.uploadDate)?.toLocaleString(),
	},
	{
		id: "uploadedBy",
		label: "Upload By",
		render: (item: any) => <UserChip id={item.uploadedBy} />,
	},
];
function createData(row: BlobFile) {
	return {
		data: {
			id: row.name,
			name: row.originalName,
			fileType: row.fileType,
			approved: row.approved,
			fileSize: row.fileSize,
			taskId: row.taskId,
			uploadDate: row.uploadDate,
			uploadedBy: row.uploadedBy,
		},
	};
}
const mapper = (rows: Array<BlobFile>) => {
	return rows.map((el: any) => createData(el));
};

const FileManager = (props: FileManagerProps) => {
	const files: BlobFile[] = props.files;
	const [fileTree, setFileTree] = useState<Array<FileNode>>([]);
	const [currentFileTree, setCurrentFileTree] = useState<Array<FileNode>>([]);
	const [listView, setListView] = useState<boolean>(false);
	const [fileRows, setFileRows] = useState<any>([]);
	const { basePath } = useBasePath();

	useEffect(() => {
		const tree = buildFileTree(files);
		setFileTree(tree);
		if (basePath != null && basePath != "" && basePath != "/") {
			const folders = basePath.split("/");
			let current: Array<FileNode> = tree;
			for (let part of folders) {
				if (current != null) {
					const currentNode: FileNode | undefined = current.find((node) => node.name === part);
					if (currentNode != undefined) {
						current = currentNode.children ?? [];
					}
				}
			}
			setCurrentFileTree(current);
		} else {
			setCurrentFileTree(tree);
		}
	}, [files, basePath]);

	const toggleListView = () => {
		setListView(() => !listView);
	};

	// Creates the listing view file array
	useEffect(() => {
		// Set files rows for listing
		const currentFiles = currentFileTree
			.filter((item) => {
				return item.children == null || item.children?.length === 0;
			})
			.map((item) => item.file);
		const r = mapper(currentFiles);
		setFileRows(r);
	}, [currentFileTree]);

	return (
		<>
			<div className='g-display-flex--xs g-padding-x-5--xs g-margin-b-5--xs' style={{ justifyContent: "space-between" }}>
				<div className='g-display-flex--xs'>
					<Button
						className={"g-margin-r-5--xs g-padding-x-10--xs"}
						onClick={toggleListView}
						text={!listView ? "List View" : "Grid View"}
						icon={!listView ? "view_list" : "gallery_thumbnail"}
						color='white'
						variant='contained'
					/>
					<Button className={" g-padding-x-10--xs"} icon={"sort"} text='Sort' color='white' variant='contained' />
				</div>
				<UploadManager files={files} taskId={props.taskId} />
			</div>

			<Grid container>
				{!listView &&
					currentFileTree.map((node: FileNode, index) => (
						<Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: "5px" }} key={index}>
							{node.children != undefined && node.children.length > 0 ? (
								<Folder currentBase={basePath === "/" ? "" : basePath} name={node.name}></Folder>
							) : (
								<FileTile
									file={node.file}
									isUploading={node.file.isUploading ?? false}
									taskId={node.file.taskId ?? ""}
									isReferenced={node.file.isReferenced}
									refTaskId={node.file.isReferenced ? props.taskId : ""}
									currentBase={basePath === "/" ? "" : basePath}
								/>
							)}
						</Grid>
					))}
				{listView && fileRows && fileRows.length > 0 && <EnhancedTable rows={fileRows} columns={header} paginationOptions={[100]}></EnhancedTable>}
				{files && files.length == 0 && <NoData />}
			</Grid>
		</>
	);
};

export default FileManager;
