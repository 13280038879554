import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { Link } from "react-router-dom";

export interface ListItemProps {
	isOpen?: Boolean;
	text: string;
	icon: string;
	targetLink?: string;
}

export interface CollapsibleListItemProps {
	isOpen?: Boolean;
	text: string;
	icon: string;
	targetLink?: string;
	subitems: ListItemProps[];
}

const MainListItem = (props: ListItemProps) => {

	// remove padding for logout and email button 
	const customSpacingforLogOut = props.icon === "logout" || props.icon === "account_circle";

	const LItem = (props: ListItemProps) => {
		return (
			<ListItem key={props.text} className='g-color--dark g-font-size-14--xs' disablePadding sx={{ display: "block" ,".MuiListItemButton-root":{
				paddingTop:customSpacingforLogOut?"0":"",
				paddingBottom:customSpacingforLogOut?"0":"",
			},}}>
				<ListItemButton
					sx={{
						minHeight: 40,
						justifyContent: "center",
						alignItems: "center",
						px: 2,
					}}>
					<ListItemIcon
						className='g-color--dark g-font-size-18--xs'
						sx={{
							minWidth: 0,
							mr: props.isOpen ? 1 : 0,
							justifyContent: "center",
							fontSize: "18px !important",
						}}>
						<span className={"material-symbols-sharp g-font-size-22--xs"}>{props.icon}</span>
					</ListItemIcon>
					<ListItemText
						primary={props.text}
						sx={{
							opacity: props.isOpen ? 1 : 0,
							"& .MuiTypography-root": {
								fontSize: "16px",
								fontWeight: 500,
							},
						}}
					/>
				</ListItemButton>
			</ListItem>
		);
	};

	return (
		<>
			{props.targetLink != null ? (
				<Link to={props.targetLink}>
					<LItem text={props?.text} icon={props.icon} isOpen={props.isOpen} />
				</Link>
			) : (
				<LItem text={props?.text} icon={props.icon} isOpen={props.isOpen} />
			)}
		</>
	);
};

const SubListItem = (props: ListItemProps) => {
	return (
		<ListItem key={props.text} className='g-color--dark g-font-size-11--xs' disablePadding sx={{ display: "block" }}>
			<ListItemButton
				sx={{
					minHeight: 32,
					justifyContent: "center",
					alignItems: "center",
					px: props.isOpen ? 4.5 : 0,
					py: 0.5,
				}}>
				<ListItemIcon
					className='g-color--dark g-font-size-12--xs'
					sx={{
						minWidth: 0,
						mr: props.isOpen ? 1 : 0,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<span className={"material-symbols-sharp g-font-size-18--xs"}>{props.icon}</span>
				</ListItemIcon>
				<ListItemText
					primary={props.text}
					sx={{
						display: props.isOpen ? "block" : "none",
						"& .MuiTypography-root": {
							fontSize: "14px",
							fontWeight: 500,
						},
					}}
				/>
			</ListItemButton>
		</ListItem>
	);
};

const CollapsibleListItem = (props: CollapsibleListItemProps) => {
	const [openList, setOpenList] = React.useState(true);

	const handleClick = () => {
		if (props.isOpen) setOpenList(!openList);
	};
	return (
		<>
			<ListItem key={"n"} className='g-color--dark g-font-size-14--xs' disablePadding sx={{ display: "block" }}>
				<ListItemButton
					onClick={handleClick}
					sx={{
						minHeight: 40,
						justifyContent: "center",
						alignItems: "center",
						px: 2,
					}}>
					<ListItemIcon
						className='g-color--dark g-font-size-14--xs'
						sx={{
							minWidth: 0,
							mr: props.isOpen ? 1 : "0",
							justifyContent: "center",
							fontSize: "18px !important",
						}}>
						<span className={"material-symbols-sharp g-font-size-22--xs"}>{props.icon}</span>
					</ListItemIcon>
					<ListItemText
						primary='Workflows'
						sx={{
							opacity: props.isOpen ? 1 : 0,
							"& .MuiTypography-root": {
								fontSize: "16px",
								fontWeight: 500,
							},
						}}
					/>
					{props.isOpen && <span className={"material-symbols-sharp"}>{openList ? "expand_less" : "expand_more"}</span>}
				</ListItemButton>
			</ListItem>

			<Collapse in={openList} timeout='auto' unmountOnExit>
				<List sx={{ background: "#ddd", maxHeight: "270px", overflowY: "auto", paddingTop: 0, paddingBottom: 0 }}>
					{props.subitems.map((it) => (
						<Link key={it.targetLink ?? ""} to={it.targetLink ?? ""}>
							<SubListItem key={it.targetLink ?? ""} icon={it.icon} text={it.text} isOpen={props.isOpen}></SubListItem>
						</Link>
					))}
					<Link to={"workflow/new"}>
						<SubListItem key={"workflow/new"} icon={"add"} text={"Add Workflow"} isOpen={props.isOpen}></SubListItem>
					</Link>
				</List>
			</Collapse>
		</>
	);
};

export { CollapsibleListItem, MainListItem, SubListItem };
