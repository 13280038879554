import Grid from "@mui/material/Grid";
import { Navigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import Button from "../atoms/Button";
import Title from "../atoms/Title";
import CreateWorkspaceForm from "../molecules/CreateWorkspaceForm";

const Onboarding = () => {
	const { isLoggedIn, user, logout } = useAuth();
	Title(`GoBananas | Create Workspace`);
	if (isLoggedIn()) {
		return (
			<>
				<Grid
					container
					justifyContent='center'
					style={{
						height: "100vh",
						background: "url(/svg/gobananas.svg)",
						backgroundRepeat: "no-repeat",
						backgroundColor: "#f7f7f7",
						backgroundSize: "20%",
						backgroundPositionY: "90%",
					}}>
					<Grid item xs={12} md={5} className='g-padding-y-40--xs g-padding-x-20--xs g-padding-x-80--md g-bg-color--white'>
						<Grid width={"100%"} display={"inline-flex"} justifyContent={"space-between"}>
							<div>
								<a href='https://www.gobananas.live'>
									<img src='/logo.png' height='30'></img>
								</a>
							</div>
							<div>
								<Button variant='text' className='g-font-weight--600' onClick={() => logout()} text='Logout' />
							</div>
						</Grid>
						<Grid className='g-margin-t-60--xs'>
							<div>
								<p className='g-font-weight--700 g-display-flex--xs' style={{ alignItems: "center" }}>
									{user.email}
									<span className='g-margin-l-15--xs'>
										<Button variant='text' onClick={() => logout()} iconRight='unfold_more' text='Switch Account' />
									</span>
								</p>
							</div>
						</Grid>
						<Grid className='g-margin-t-20--xs'>
							<CreateWorkspaceForm />
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	} else {
		return <Navigate to={"/"} />;
	}
};

export default Onboarding;
