import { Chip, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { IdeaWebService } from "../../../services/IdeaWebService";
import { useAuth } from "../../AuthContext";
import { useLoading } from "../../LoadingContext";
import Button from "../../atoms/Button";
import InputV1 from "../../atoms/InputV1";
import { EnhancedTable } from "../../atoms/Table";
import UserChip from "../../atoms/UserChip";
import NoData from "../../molecules/NoData";

const IdeasListing = () => {
	const methods = useForm({ mode: "onBlur" });
	const { currentWorkspace } = useAuth();
	const navigate = useNavigate();
	const { showLoading, hideLoading, isLoading } = useLoading();

	const [rows, setRows] = useState([]);
	const [filteredRows, setFilteredRows] = useState([]);
	function createData(row: any) {
		return { data: { id: row.id, name: row.name, status: row.status ?? "Draft", ideaWorkflows: row.ideaWorkflows, tags: row.tags, createdBy: row.createdBy } };
	}
	const mapper = (rows: any) => {
		return rows.map((row: any) => createData(row));
	};

	const header = [
		// {
		// 	id: "id",
		// 	label: "Id",
		// 	render: (item: any) => item.id,
		// },
		{
			id: "name",
			label: "Title",
			render: (item: any) => (
				<div className='g-display-flex--xs' style={{ justifyContent: "space-between" }}>
					<Link to={`${item.id}`}>{item.name}</Link>
					<i className='material-symbols-sharp'>more_horiz</i>
				</div>
			),
		},
		{
			id: "ideaWorkflows",
			label: "Workflows",
			render: (item: any) => (
				<>
					{item.ideaWorkflows.map((element: any) => {
						return (
							<>
								{element && (
									<Chip
										className='g-margin-r-5--xs'
										size='small'
										key={element?.id}
										label={element?.workflowName ?? element?.id}
										color={"default"}
										variant='outlined'
									/>
								)}
							</>
						);
					})}
				</>
			),
		},
		{
			id: "tags",
			label: "Tags",
			render: (item: any) => (
				<>
					{item.tags?.map((element: any) => {
						return <Chip className='g-margin-r-5--xs' size='small' key={element} label={element} color={"default"} variant='outlined' />;
					})}
				</>
			),
		},
		{
			id: "createdBy",
			label: "Created By",
			render: (item: any) => <UserChip className='g-margin-r-5--xs' id={item?.createdBy} />,
		},
	];

	const handleClickOpen = () => {
		navigate("new");
	};

	const handleFilterChange = (event: any) => {
		const value = event.target.value;
		if (value && value.length > 0) {
			const row_filtered = rows.filter((row: any) => row.data.name.toLowerCase().includes(value.toLowerCase()));
			setFilteredRows(row_filtered);
		} else setFilteredRows(rows);
	};
	const fetchAllIdeas = async () => {
		if (currentWorkspace?.id) {
			showLoading();
			await IdeaWebService.fetchAllIdeas(currentWorkspace?.id)
				.then(async (res) => {
					const r = mapper(res);
					setRows(r);
					setFilteredRows(r);
				})
				.catch((err) => console.log(err))
				.finally(() => hideLoading());
		}
	};

	useEffect(() => {
		fetchAllIdeas();
	}, []);

	return (
		<>
			<div className='g-padding-x-20--xs g-margin-t-30--xs'>
				<div className='g-display-flex--xs g-margin-b-20--xs' style={{ justifyContent: "space-between" }}>
					<h3>Ideas</h3>
					<div className='g-display-flex--xs'>
						<div className='g-content-row-left--xs g-margin-r-10--xs'>
							<div className='g-width-200--xs'>
								<FormProvider {...methods}>
									<InputV1 onChange={handleFilterChange} name='filterIdeas' placeholder='Filter Ideas'></InputV1>
								</FormProvider>
							</div>
						</div>
						<div>
							<Button
								style={{ height: "2.375rem" }}
								onClick={() => handleClickOpen()}
								text='Create New Idea'
								icon='add'
								radius={4}
								variant='contained'
								color='primary'></Button>
						</div>
					</div>
				</div>
				{isLoading && (
					<div className='g-content-center-xy--xs g-height-600--xs'>
						<LinearProgress className='g-margin-t-40--xs g-width-150--xs' color='secondary' />
					</div>
				)}

				{filteredRows.length > 0 && <EnhancedTable rows={filteredRows} columns={header} paginationOptions={[100]}></EnhancedTable>}
				{filteredRows.length === 0 && !isLoading && <NoData />}
			</div>
		</>
	);
};

export default IdeasListing;
