import { Box, Drawer, styled } from "@mui/material";
import { useState } from "react";
import Button from "../atoms/Button";

const RightSidebarLayout = ({ drawer, drawerWidth, isDrawerOpen, children, ...rest }: any) => {
	const DRAWER_WIDTH = drawerWidth ?? 360;

	const [open, setOpen] = useState(isDrawerOpen);
	const handleDrawerToggle = () => {
		setOpen(!open);
	};

	const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
		open?: boolean;
	}>(({ theme, open }) => ({
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginRight: -DRAWER_WIDTH,
		...(open && {
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginRight: 0,
		}),
		/**
		 * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
		 * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
		 * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
		 * proper interaction with the underlying content.
		 */
		position: "relative",
	}));

	return (
		<>
			<Box sx={{ display: "flex" }}>
				<Main open={open} sx={{ flexGrow: 1 }} {...rest}>
					{children}
				</Main>
				<Drawer
					sx={{
						width: DRAWER_WIDTH,
						flexShrink: 0,
						"& .MuiDrawer-paper": {
							width: DRAWER_WIDTH,
							mt: 0,
							pt: 9,
							zIndex: 0,
						},
						div: { zIndex: 0, borderLeftColor: "var(--gray)" },
					}}
					variant='persistent'
					anchor='right'
					open={open}>
					{drawer}
				</Drawer>
			</Box>
			<div style={{ position: "fixed", top: "90%", left: !open ? "calc(100% - 40px)" : `calc(100% - ${DRAWER_WIDTH}px)`, zIndex: 10 }}>
				<Button
					style={{ width: "40px", height: "32px", borderRadius: !open ? "16px 0 0 16px" : "0 16px 16px 0" }}
					onClick={() => handleDrawerToggle()}
					variant='contained'
					icon={!open ? "chevron_left" : "chevron_right"}
				/>
			</div>
		</>
	);
};

export default RightSidebarLayout;
