import { Skeleton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import RP from "../../constants/RoutePaths";
import { AvatarIntials } from "../../utils/Helpers";
import { useAuth } from "../AuthContext";
import { useLoading } from "../LoadingContext";
import Button from "../atoms/Button";
import { CollapsibleListItem, ListItemProps, MainListItem } from "../atoms/ListItem";
import { DashboardContext } from "../pages/Dashboard";
import { ChangeWorkspaceMenu } from "./ChangeWorkspaceMenu";
import { SettingsMenu } from "./SettingsMenu";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: "ease-in-out",
		duration: 300,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: "ease-out",
		duration: 200,
	}),
	overflowX: "hidden",
	width: "60px",
});

const DrawerFooter = styled("div")(({ theme }) => ({
	position: "absolute",
	bottom: 0,
	left: 0,
	padding: 0,
	width: "100%",
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const MainSidebar = (props: any) => {
	const { open, toggleOpen }: any = React.useContext(DashboardContext);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [anchorSettingsEl, setAnchorSettingsEl] = React.useState<null | HTMLElement>(null);
	const [workflowList, setWorkflowList] = React.useState<Array<ListItemProps>>([]);
	const { currentWorkspace, workflowId, user, logout } = useAuth();
	const { isLoading } = useLoading();
	const navigate = useNavigate();

	const handleMenuClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleSettingsMenuClick = (event: any) => {
		setAnchorSettingsEl(event.currentTarget);
	};

	const handleSettingsMenuClose = () => {
		setAnchorSettingsEl(null);
	};

	const handleDrawerToggle = () => {
		toggleOpen();
	};

	React.useEffect(() => {
		prepareCList();
	}, [currentWorkspace, isLoading, workflowId]);

	const prepareCList = () => {
		const workflows = currentWorkspace?.workflows;
		let clist: Array<ListItemProps> = [];
		if (workflows) {
			clist = workflows?.map((workflow) => {
				return { text: workflow.name, icon: "flowsheet", targetLink: `workflow/${workflow.id}` } as ListItemProps;
			});
		}
		setWorkflowList(clist);
	};
	const logoutAndRedirect = () => {
		logout();
		navigate("/login");
	};

	return (
		<Drawer variant='permanent' anchor='left' open={open} sx={{ div: { zIndex: 1000, borderRightColor: "#ededed" } }}>
			<div className='g-padding-x-10--xs g-margin-t-30--xs g-margin-b-15--xs'>
				{open ? (
					<img className='s-header-v2__logo-img s-header-v2__logo-img--default' src='/logo.png' alt='logo' height='36' />
				) : (
					<img className='s-header-v2__logo-img s-header-v2__logo-img--default' src='/logo-sm.png' alt='logo' height='36' />
				)}
			</div>

			<div
				className='g-padding-x-15--xs g-margin-t-10--xs g-margin-b-10--xs'
				style={{ display: "flex", flexDirection: open ? "row" : "column", alignItems: "center" }}>
				{!isLoading ? (
					<Avatar sx={{ bgcolor: "green", width: 30, height: 30, fontSize: 14, mr: open ? 1 : 0 }}>{AvatarIntials(currentWorkspace?.name || "AB")}</Avatar>
				) : (
					<Skeleton style={{ borderRadius: "50%" }} variant='rectangular' width={30} height={30} />
				)}
				{!isLoading ? (
					open && (
						<Button
							onClick={handleMenuClick}
							style={{ minWidth: "150px", justifyContent: "left", fontWeight: 700, fontSize: 16, paddingLeft: 0, paddingRight: 0 }}
							text={currentWorkspace?.name}
							variant='text'
							size='xs'
							iconRight='unfold_more'></Button>
					)
				) : (
					<Skeleton variant='text' className='g-margin-l-10--xs' width={"140px"} sx={{ fontSize: "1rem" }} />
				)}
				<Button
					style={{ marginLeft: open ? "5px" : 0, marginTop: open ? 0 : "10px" }}
					variant='text'
					icon={open ? "chevron_left" : "chevron_right"}
					onClick={handleDrawerToggle}></Button>
				<ChangeWorkspaceMenu anchorElement={anchorEl} closeHandler={handleMenuClose}></ChangeWorkspaceMenu>
			</div>

			{!isLoading ? (
				<List>
					{/* <MainListItem isOpen={open} text='Dashboard' icon='home' targetLink={RP.Dashboard.Root}></MainListItem> */}
					<MainListItem isOpen={open} text='My Tasks' icon='task' targetLink={RP.DASHBOARD.TASKS}></MainListItem>
					<MainListItem isOpen={open} text='Ideas' icon='emoji_objects' targetLink={RP.DASHBOARD.IDEAS}></MainListItem>

					<CollapsibleListItem subitems={workflowList} isOpen={open} text='Workflows' icon='account_tree'></CollapsibleListItem>
				</List>
			) : (
				<>
					<Skeleton variant='text' className='g-margin-l-10--xs g-margin-t-30--xs' width={"180px"} sx={{ fontSize: "1.5rem" }} />
					<Skeleton variant='text' className='g-margin-l-10--xs g-margin-t-20--xs' width={"180px"} sx={{ fontSize: "1.5rem" }} />
					<Skeleton variant='text' className='g-margin-l-10--xs g-margin-t-20--xs' width={"180px"} sx={{ fontSize: "1.5rem" }} />
					<Skeleton variant='text' className='g-margin-l-10--xs g-margin-t-20--xs' width={"180px"} sx={{ fontSize: "1.5rem" }} />
				</>
			)}
			{!isLoading ? (
				<List>
					<MainListItem isOpen={open} text='Assets' icon='inventory_2' targetLink={RP.DASHBOARD.ROOT} />
					{/* <MainListItem isOpen={open} text='Updates' icon='pace' targetLink={RP.Dashboard.Workflow} /> */}
				</List>
			) : (
				<>
					<Skeleton variant='text' className='g-margin-l-10--xs g-margin-t-30--xs' width={"180px"} sx={{ fontSize: "1.5rem" }} />
					<Skeleton variant='text' className='g-margin-l-10--xs g-margin-t-20--xs' width={"180px"} sx={{ fontSize: "1.5rem" }} />
					<Skeleton variant='text' className='g-margin-l-10--xs g-margin-t-20--xs' width={"180px"} sx={{ fontSize: "1.5rem" }} />
					<Skeleton variant='text' className='g-margin-l-10--xs g-margin-t-20--xs' width={"180px"} sx={{ fontSize: "1.5rem" }} />
				</>
			)}
			<DrawerFooter className='g-margin-b-20--xs'>
				{!isLoading ? (
					<div>
						<div onClick={(e) => handleSettingsMenuClick(e)}>
							<List>
								<MainListItem isOpen={open} text={open ? `${user?.firstName} ${user?.lastName}` : ""} icon='account_circle' />
							</List>
						</div>
						<div onClick={() => logoutAndRedirect()}>
							<List>
								<MainListItem isOpen={open} text={"LogOut"} icon='logout' />
							</List>
						</div>

						<SettingsMenu anchorElement={anchorSettingsEl} closeHandler={handleSettingsMenuClose} />
					</div>
				) : (
					<>
						{/* <Skeleton variant='text' className='g-margin-l-10--xs g-margin-t-30--xs' width={"180px"} sx={{ fontSize: "1.5rem" }} /> */}
						<Skeleton variant='text' className='g-margin-l-10--xs g-margin-t-20--xs' width={"180px"} sx={{ fontSize: "1.5rem" }} />
					</>
				)}
			</DrawerFooter>
		</Drawer>
	);
};

export default MainSidebar;
