import Api from "./Api";

export namespace CommentsService {
	export const getAllComments = (taskId: string, fileName: string, fileVersionId: string) => {
		return new Promise((resolve, reject) => {
			Api.get(`/task/${taskId}/file/${fileName}/fileVersion/${fileVersionId}/comment`)
				.then((response) => {
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data;
                      
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	export const postComment = (taskId: string, fileName: string, fileVersionId: string, body: any) => {
		return new Promise((resolve, reject) => {
			Api.post(`/task/${taskId}/file/${fileName}/fileVersion/${fileVersionId}/comment`, body)
				.then((response) => {
					let responseOK = response && response.status === 200;
					if (responseOK) {
						const data = response.data;
						resolve(data);
					} else {
						reject();
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
}
