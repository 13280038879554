import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useNotification } from "../NotificationContext";

export const Notification = () => {
	const { message, severity, hasMessage, clearMessage } = useNotification();

	const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		clearMessage();
	};

	const handleExited = () => {
		clearMessage();
	};

	return (
		<Snackbar
			open={hasMessage()}
			autoHideDuration={5000}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			onClose={handleClose}
			sx={{ top: { xs: 80 } }}
			TransitionProps={{ onExited: handleExited }}>
			<Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
				{message}
			</Alert>
		</Snackbar>
	);
};
