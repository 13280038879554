const schema = {
	_id: "s344d434q4w4e2ed123",
	schema: "http://json-schema.org/draft-04/schema#",
	title: "Work Flow Definition Schema",
	description: "The workflow.",
	properties: {
		activities: {
			properties: {
				builtIn: {
					enum: ["Content"],
					description: "All built in activities",
				},
				external: {
					enum: ["ReleaseYoutube", "ReleaseInstagram", "ReleaseLinkedin"],
					description: "All external activities",
				},
			},
			definitions: {
				Content: {
					title: "",
					description: "All in one manager that helps you to create workflow step related to Docs, Image, Audio or Video",
					theme: "#ffcc08",
					avatar: "menu_book",
					type: "Content",
					assignedTo: [],
					references: [],
					reviewers: [],
					properties: {
						enum: ["Content"],
					},
					inputs: [],
				},
				Script: {
					title: "Script",
					description: "",
					theme: "#F1948A",
					avatar: "menu_book",
					type: "Script",
					assignedTo: [],
					references: [],
					reviewers: [],
					properties: {
						enum: ["Scripts"],
					},
					inputs: [],
				},
				Recordings: {
					title: "Recordings",
					description: "Recording activity allows to upload video recordings.",
					theme: "#76D7C4",
					avatar: "videocam",
					type: "Recordings",
					assignedTo: [],
					references: [],
					reviewers: [],
					properties: {
						enum: ["Recordings"],
					},
					inputs: [],
				},
				Thumbnail: {
					title: "Thumbnail",
					description: "",
					theme: "#F0B27A",
					avatar: "heap_snapshot_thumbnail",
					type: "Thumbnail",
					assignedTo: [],
					references: [],
					reviewers: [],
					properties: {
						enum: ["Thumbnail"],
					},
					inputs: [],
				},
				Editing: {
					title: "Editing",
					description: "",
					theme: "#7DCEA0",
					avatar: "video_library",
					type: "Editing",
					assignedTo: [],
					references: [],
					reviewers: [],
					properties: {
						enum: ["Edits"],
					},
					inputs: [],
				},
				Document: {
					title: "Document",
					description: "",
					theme: "#85C1E9",
					avatar: "description",
					type: "Document",
					assignedTo: [],
					references: [],
					reviewers: [],
					properties: {
						enum: ["Document"],
					},
					inputs: [],
				},
				Image: {
					title: "Image",
					description: "",
					theme: "#C39BD3",
					avatar: "image",
					type: "Image",
					assignedTo: [],
					references: [],
					reviewers: [],
					properties: {
						enum: ["Scripts"],
					},
					inputs: [],
				},
				Video: {
					title: "Video",
					description: "",
					theme: "#85929E",
					avatar: "movie",
					type: "Video",
					assignedTo: [],
					references: [],
					reviewers: [],
					properties: {
						enum: ["Video"],
					},
					inputs: [],
				},
				Audio: {
					title: "Audio",
					description: "",
					theme: "#73C6B6",
					avatar: "mic",
					type: "Audio",
					assignedTo: [],
					references: [],
					reviewers: [],
					properties: {
						enum: ["Audio"],
					},
					inputs: [],
				},
				ReleaseYoutube: {
					title: "Release To Youtube",
					description: "Coming Soon",
					theme: "#E74C3C",
					avatar: "youtube",
					type: "ReleaseYoutube",
					assignedTo: [],
					references: [],
					reviewers: [],
					properties: {
						enum: ["Title", "Description"],
					},
					inputs: [],
				},
				ReleaseInstagram: {
					title: "Release To Instagram",
					description: "Coming Soon",
					theme: "#e200a7",
					avatar: "instagram",
					type: "ReleaseInstagram",
					assignedTo: [],
					references: [],
					reviewers: [],
					properties: {
						enum: ["Title", "Description"],
					},
					inputs: [],
				},
				ReleaseLinkedin: {
					title: "Release To LinkedIn",
					description: "Coming Soon",
					theme: "#0b66c2",
					avatar: "linkedin",
					type: "ReleaseLinkedin",
					assignedTo: [],
					references: [],
					reviewers: [],
					properties: {
						enum: ["Title", "Description"],
					},
					inputs: [],
				},
			},
		},
	},
};

export default schema;
