const Button = (props: any) => {
	let { icon, iconRight, text, color, size, variant, radius, style, className, ...rest } = props;
	text = text != null ? text : icon == null ? "Button" : null;
	color = color != null ? color : "dark";
	size = size != null ? size : "xs";
	variant = variant == "contained" ? "bg" : props.variant == "outlined" ? "brd" : props.variant == "text" ? "text" : "brd";
	radius = radius != null ? radius : "10";
	return (
		<button
			{...rest}
			className={`s-btn g-display-flex--xs g-radius--${radius} s-btn--${size} s-btn--${color}-${variant} ${className}`}
			style={{ justifyContent: "center", alignItems: "center", ...style }}>
			{icon != null && <span className={"material-symbols-sharp"}>{icon}</span>}
			{icon != null && text != null && <span className='g-margin-r-5--xs' />}
			<span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{text}</span>
			{iconRight != null && <span className={"g-margin-l-5--xs material-symbols-sharp"}>{iconRight}</span>}
		</button>
	);
};

export default Button;
